/**React imports */
import React, { useEffect, Fragment } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';



const CardService = (props) => {


    return (
        <Fragment>
            <div 

                style={{
                    justifyContent: 'center',
                    backgroundSize:'cover',
                    backgroundPosition:'center',
                    backgroundImage: `linear-gradient(
                    325deg,
                    rgba(0, 36, 51, 1) 0%,
                    rgba(98, 218, 255, 0.164) 100%
                    ),url(${(props.url)})`
                }} className="cardNote">

                 
                <p style={{fontSize:'24px', fontWeight:'700',color:'#fff'}}>{props.titleCard}</p>
                <div style={{height:'20px'}}></div>
                <Link style={{paddingTop:'10px', width:'150px'}} className='btn botonChidoBlue' to={props.view}>Consultar</Link>
            </div>
        </Fragment>
    )

}


export default CardService;