import React, { Fragment, useState, useEffect } from 'react'


import Post from '../components/adminComponents/Post'


/** Config. Firebase */

import app from "../firebase";
const db = app.firestore();


const PostList = () => {




    const [post, setPost] = useState([]);
    




    


    


    const getPosts = async () => {



        await db.collection('posts')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    console.log(doc.data())
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setPost(docs)
            });


    }
    




    useEffect(() => {
        getPosts()
    }, [])

    return (
        <Fragment>

        
            
            {
                post.map((post) => (
                    <Post  url={post.url} name={post.name} eventDate={post.dateEvent} description={post.description}></Post>
                ))
            }
            

          
        </Fragment>
    )
}


export default PostList;