/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'

/**Transitions  */
import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';
import Swal from 'sweetalert2'

/** Config. Firebase */

import app from '../../firebase';
const db = app.firestore();

const PrayRequestList = () => {







    const [dia, setDia] = useState('');

    const [category, setCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);





    const [praysHealt, setPraysHealt] = useState([]);
    const [greetings, setGreetings] = useState([]);
    const [general, setGeneral] = useState([]);
    const [covidHealt, setCovidHealt] = useState([]);












    const [culto, setCulto] = useState('')




    const makeCut = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success m-2',
                cancelButton: 'btn btn-danger m-2'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Realment deseas hacer el corte?',
            text: "Todos los registros seran eliminados",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hacer corte',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {


                //deleting for viernes
                for (let h in praysHealt) {
                    db.collection('prayrequest').doc("dias").collection('viernes').doc('categorias').collection('healt')
                        .doc(praysHealt[h].id).delete()
                }
                for (let g in greetings) {
                    db.collection('prayrequest').doc("dias").collection('viernes').doc('categorias').collection('greetings')
                        .doc(greetings[g].id).delete()
                }
                for (let gen in general) {
                    db.collection('prayrequest').doc("dias").collection('viernes').doc('categorias').collection('general')
                        .doc(general[gen].id).delete()
                }
                for (let cv in covidHealt) {
                    db.collection('prayrequest').doc("dias").collection('viernes').doc('categorias').collection('covidhealt')
                        .doc(covidHealt[cv].id).delete()
                }

                //deleting for sabadomatutino
                for (let h in praysHealt) {
                    db.collection('prayrequest').doc("dias").collection('sabadomatutino').doc('categorias').collection('healt')
                        .doc(praysHealt[h].id).delete()
                }
                for (let g in greetings) {
                    db.collection('prayrequest').doc("dias").collection('sabadomatutino').doc('categorias').collection('greetings')
                        .doc(greetings[g].id).delete()
                }
                for (let gen in general) {
                    db.collection('prayrequest').doc("dias").collection('sabadomatutino').doc('categorias').collection('general')
                        .doc(general[gen].id).delete()
                }
                for (let cv in covidHealt) {
                    db.collection('prayrequest').doc("dias").collection('sabadomatutino').doc('categorias').collection('covidhealt')
                        .doc(covidHealt[cv].id).delete()
                }

                //deleting for sabadovespertino
                for (let h in praysHealt) {
                    db.collection('prayrequest').doc("dias").collection('sabadovespertino').doc('categorias').collection('healt')
                        .doc(praysHealt[h].id).delete()
                }
                for (let g in greetings) {
                    db.collection('prayrequest').doc("dias").collection('sabadovespertino').doc('categorias').collection('greetings')
                        .doc(greetings[g].id).delete()
                }
                for (let gen in general) {
                    db.collection('prayrequest').doc("dias").collection('sabadovespertino').doc('categorias').collection('general')
                        .doc(general[gen].id).delete()
                }
                for (let cv in covidHealt) {
                    db.collection('prayrequest').doc("dias").collection('sabadovespertino').doc('categorias').collection('covidhealt')
                        .doc(covidHealt[cv].id).delete()
                }

                Swal.fire(
                    'Corte realizado correctamente',
                    'Se ha hecho el corte',
                    'success'
                )
            }
        })

    }

    const getDataFromDay = async () => {


        let diaculto = localStorage.getItem('@dia')

        if (diaculto == 'viernes') {
            await setCulto('Culto de Recibimiento')
        } else if (diaculto == 'sabadomatutino') {
            await setCulto('Sábado matutino')
        } else if (diaculto == 'sabadovespertino') {
            await setCulto('Sábado vespertino')
        }





        setIsLoading(true);
        let dia = await localStorage.getItem('@dia')
        await setDia(dia);



        console.log(dia)
        switch (dia) {
            case 'viernes':



                //for greetings
                db.collection('prayrequest').doc("dias").collection('viernes')
                    .doc('categorias').collection('greetings').orderBy('date','asc')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setGreetings(docs)
                    });

                //for healt
                db.collection('prayrequest').doc("dias").collection('viernes')
                    .doc('categorias').collection('healt')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setPraysHealt(docs)
                    });

                //for general
                db.collection('prayrequest').doc("dias").collection('viernes')
                    .doc('categorias').collection('general')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setGeneral(docs)
                    });

                //for covidHealt
                db.collection('prayrequest').doc("dias").collection('viernes')
                    .doc('categorias').collection('covidhealt')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setCovidHealt(docs)
                    });






                setIsLoading(false);
                break;
            case 'sabadomatutino':
                //for greetings
                db.collection('prayrequest').doc("dias").collection('sabadomatutino')
                    .doc('categorias').collection('greetings')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setGreetings(docs)
                    });

                //for healt
                db.collection('prayrequest').doc("dias").collection('sabadomatutino')
                    .doc('categorias').collection('healt')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setPraysHealt(docs)
                    });

                //for general
                db.collection('prayrequest').doc("dias").collection('sabadomatutino')
                    .doc('categorias').collection('general')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setGeneral(docs)
                    });

                //for covidHealt
                db.collection('prayrequest').doc("dias").collection('sabadomatutino')
                    .doc('categorias').collection('covidhealt')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setCovidHealt(docs)
                    });






                setIsLoading(false);
                break;
            case 'sabadovespertino':
                //for greetings
                db.collection('prayrequest').doc("dias").collection('sabadovespertino')
                    .doc('categorias').collection('greetings')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setGreetings(docs)
                    });

                //for healt
                db.collection('prayrequest').doc("dias").collection('sabadovespertino')
                    .doc('categorias').collection('healt')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setPraysHealt(docs)
                    });

                //for general
                db.collection('prayrequest').doc("dias").collection('sabadovespertino')
                    .doc('categorias').collection('general')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setGeneral(docs)
                    });

                //for covidHealt
                db.collection('prayrequest').doc("dias").collection('sabadovespertino')
                    .doc('categorias').collection('covidhealt')
                    .onSnapshot((querySnapshot) => {

                        const docs = [];
                        querySnapshot.forEach(doc => {
                            docs.push({ ...doc.data(), id: doc.id })
                        });
                        setCovidHealt(docs)
                    });






                setIsLoading(false);
                break;

            default:
                break;
        }

    }




    const getGreetinsTable = () => {

        return (

            <div className="table shadow-table table-responsive table-bordered mb-4" style={{ width: '80%' }}>


                <div className='row justify-content-center'>
                    <span className='table-title'>Peticiones Por Agradecimientos</span>
                </div>


                <table className="table" id="ordersTable">
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th scope="col">Quién la pide</th>
                            <th scope="col">Tipo de usuario</th>
                            <th scope="col">Denominación</th>
                            <th scope="col">Localidad</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Q&A</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            greetings.map((greeting) => (
                                <tr>
                                    <td style={{ textAlign: "center" }}>{greeting.usuario.nombreCompleto}</td>
                                    <td style={{ textAlign: "center" }}>{greeting.externo}</td>
                                    <td style={{ textAlign: "center" }}>{greeting.usuario.denominacion}</td>
                                    <td style={{ textAlign: "center" }}>{greeting.usuario.localidad}</td>
                                    <td style={{ textAlign: "center" }}>{greeting.asunto.contenido}</td>
                                    <td style={{ textAlign: "left" }}>
                                        <tr><strong>Pregunta: </strong> {greeting.usuario.pregunta}</tr>
                                        <tr><strong>Respuesta: </strong> {greeting.usuario.respuesta}</tr>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>

        )

    }


    const getHealtTable = () => {

        return (

            <div className="table shadow-table table-responsive table-bordered mb-4" style={{ width: '80%' }}>




                <div className='row justify-content-center'>
                    <span className='table-title'>Peticiones de Salud</span>
                </div>


                <table className="table shadowTable" id="ordersTable">
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th scope="col">Quién la pide</th>
                            <th scope="col">Tipo de usuario</th>
                            <th scope="col">Denominación</th>
                            <th scope="col">Localidad</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Q&A</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            praysHealt.map((healt) => (
                                <tr>
                                    <td style={{ textAlign: "center" }}>{healt.usuario.nombreCompleto}</td>
                                    <td style={{ textAlign: "center" }}>{healt.externo}</td>
                                    <td style={{ textAlign: "center" }}>{healt.usuario.denominacion}</td>
                                    <td style={{ textAlign: "center" }}>{healt.usuario.localidad}</td>
                                    <td style={{ textAlign: "center" }}>{healt.asunto.contenido}</td>
                                    <td style={{ textAlign: "left" }}>
                                        <tr><strong>Pregunta: </strong> {healt.usuario.pregunta}</tr>
                                        <tr><strong>Respuesta: </strong> {healt.usuario.respuesta}</tr>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>

        )

    }


    const getGeneralTable = () => {

        return (

            <div className="table shadow-table table-responsive table-bordered mb-4" style={{ width: '80%' }}>


                <div className='row justify-content-center'>
                    <span className='table-title'>Peticiones Generales</span>
                </div>

                <table className="table shadowTable" id="ordersTable">
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th scope="col">Quién la pide</th>
                            <th scope="col">Tipo de usuario</th>
                            <th scope="col">Denominación</th>
                            <th scope="col">Localidad</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Q&A</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            general.map((pray) => (
                                <tr>
                                    <td style={{ textAlign: "center" }}>{pray.usuario.nombreCompleto}</td>
                                    <td style={{ textAlign: "center" }}>{pray.externo}</td>
                                    <td style={{ textAlign: "center" }}>{pray.usuario.denominacion}</td>
                                    <td style={{ textAlign: "center" }}>{pray.usuario.localidad}</td>
                                    <td style={{ textAlign: "center" }}>{pray.asunto.contenido}</td>
                                    <td style={{ textAlign: "left" }}>
                                        <tr><strong>Pregunta: </strong> {pray.usuario.pregunta}</tr>
                                        <tr><strong>Respuesta: </strong> {pray.usuario.respuesta}</tr>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>

        )

    }

    const getCovidHealtTable = () => {

        return (

            <div className="table shadow-table table-responsive table-bordered mb-4" style={{ width: '80%' }}>

                <div className='row justify-content-center'>
                    <span className='table-title'>Peticiones de Salud Por COVID-19</span>
                </div>


                <table className="table shadowTable" id="ordersTable">
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <th scope="col">Quién la pide</th>
                            <th scope="col">Tipo de usuario</th>
                            <th scope="col">Denominación</th>
                            <th scope="col">Localidad</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Q&A</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            covidHealt.map((covidHealt) => (
                                <tr>
                                    <td style={{ textAlign: "center" }}>{covidHealt.usuario.nombreCompleto}</td>
                                    <td style={{ textAlign: "center" }}>{covidHealt.externo}</td>
                                    <td style={{ textAlign: "center" }}>{covidHealt.usuario.denominacion}</td>
                                    <td style={{ textAlign: "center" }}>{covidHealt.usuario.localidad}</td>
                                    <td style={{ textAlign: "center" }}>{covidHealt.asunto.contenido}</td>
                                    <td style={{ textAlign: "left" }}>
                                        <tr><strong>Pregunta: </strong> {covidHealt.usuario.pregunta}</tr>
                                        <tr><strong>Respuesta: </strong> {covidHealt.usuario.respuesta}</tr>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </div>


        )

    }

 



    useEffect(() => {
        getDataFromDay()
        getGreetinsTable()
        getHealtTable()
        getGeneralTable()
        getCovidHealtTable()
    }, [])

    /** Verifica si hay usuario*/
    const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
        return <Redirect to='/login' />
    }


    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                        <div className="justify-content-center">
                            <div style={{height:'30px'}}></div>
                            <div className='toCenter'>
                                <h3 style={{fontWeight:'700',color:'#fff'}}>Listado de  Oraciones: {culto}</h3>
                                <button className='btn btn-danger m-2' onClick={() => makeCut()}>Hacer corte</button>
                            </div>
                        </div>

                        <div style={{ height: '35px' }}></div>

                        {
                            getGreetinsTable()
                        }

                        {
                            getHealtTable()
                        }
                        {
                            getGeneralTable()
                        }

                        {
                            getCovidHealtTable()
                        }
                    </div>








                </Fragment>
            </Paper>
        </Fade>
    );

}

export default PrayRequestList;