import React from 'react';


import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthProvider } from './components/Auth';

import DashboardAdmin from './components/adminComponents/DashboardAdmin'
import PrayRequestFilter from './components/adminComponents/PrayRequestFilter'
import PrayRequestList from './components/adminComponents/PrayRequestList'
import GreetingsList from './components/adminComponents/GreetingsList';
import Eventos from './components/adminComponents/EventList';


/**Filters */


import FilterOne from './components/FilterOne'
import HelpFilter from './components/HelpFilter'
import FormContact from './components/FormContact'
import HolocaustForm from './components/componentsForFilters/HolocaustForm';


/**Not Found*/
import NotFound from './containers/NotFound'


/**Filters Modules*/

import PrayRequest from './components/componentsForFilters/PrayRequest'
import Comments from './components/componentsForFilters/Comments'
import Greetings from './components/componentsForFilters/Greetings'
import WelcomeNotification from './components/componentsForFilters/WelcomeNotification'
import SoundRequest from './components/componentsForFilters/SoundRequest'
import GeneralInfo from './components/componentsForFilters/GeneralInfo'
import Questions from './components/componentsForFilters/Questions'
import ExternalFilter from './components/componentsForFilters/ExternalFilter'

/**Notifications */

import AlertyConfirmation from './containers/AlertyConfirmation'
import QuestionList from './components/adminComponents/QuestionList';
import CommenstList from './components/adminComponents/CommenstList';
import WelcomeList from './components/adminComponents/WelcomeList';
import SoundList from './components/adminComponents/SoundList';
import InfoRequestList from './components/adminComponents/InfoRequestList';
import Landpage from './components/Landpage';
import Login from './components/adminComponents/Login';
import Main from './components/adminComponents/Main';
import NewWorship from './components/adminComponents/NewWorship';
import AsignacionCultoMatutino from './components/adminComponents/AsignacionCultoMatutino';
import WorshipConfiguration from './components/worshipTemplates/WorshipConfiguration';
import WorshipMorning from './components/worshipTemplates/WorshipMorning';
import AsignacionCultoRecibimiento from './components/adminComponents/AsignacionCultoRecibimiento';
import HistorialCultosNuevos from './components/adminComponents/HistorialCultosNuevos';
import AsignacionCultoCabanasRecibimiento from './components/adminComponents/AsignacionCultoCabanasRecibimiento';
import HistorialCultosAnteriores from './components/adminComponents/HistorialCultosAnteriores';
import AsignacionCultoVespertino from './components/adminComponents/AsignacionCultoVespertino';
import HolocaustList from './components/adminComponents/HolocaustList';


const Routes = () => {

    return (
        <AuthProvider>
            <Router>


                <Route exact path="/" component={Landpage} />

                <Route exact path="/login" component={Login} />
                <Route exact path="/admin/dashboard" component={DashboardAdmin} />
                <Route exact path="/admin/prayrequestfilter" component={PrayRequestFilter} />
                <Route exact path="/admin/prayrequestlist" component={PrayRequestList} />
                <Route exact path="/admin/greetingslist" component={GreetingsList} />
                <Route exact path="/admin/questionlist" component={QuestionList} />
                <Route exact path="/admin/commentslist" component={CommenstList} />
                <Route exact path="/admin/welcomelist" component={WelcomeList} />
                <Route exact path="/admin/soundlist" component={SoundList} />
                <Route exact path="/admin/inforequestlist" component={InfoRequestList} />
                <Route exact path="/admin/eventos" component={Eventos} />
                <Route exact path="/admin/main" component={Main} />
                <Route exact path="/admin/new-worship" component={NewWorship} />
                <Route exact path="/admin/culto/visualizar" component={WorshipConfiguration} />




                {
                    /**Links para asignacion cultos */
                }
                <Route exact path='/admin/asignacion/culto/matutino' component={AsignacionCultoMatutino} />
                <Route exact path='/admin/asignacion/culto/recibimiento' component={AsignacionCultoRecibimiento} />
                <Route exact path='/admin/asignacion/culto/vespertino' component={AsignacionCultoVespertino} />
                <Route exact path='/admin/asignacion/culto/cabanas/configurar' component={AsignacionCultoCabanasRecibimiento} />


                {
                    /**Links para configuracion de cultos */
                }

                <Route exact path='/admin/culto/configuracion' component={WorshipConfiguration} />
                <Route exact path='/admin/cultos/anteriores' component={HistorialCultosAnteriores} />


                {
                    /**Links para configuracion de cultos */
                }

                <Route exact path='/admin/cultos/proximos' component={HistorialCultosNuevos} />



                <Route exact path='/filter' component={FilterOne} />
                <Route exact path='/helpFilter' component={HelpFilter} />
                <Route exact path='/formContact' component={FormContact} />
                <Route exact path='/contacto' component={FormContact} />
                <Route exact path='/holocausto' component={HolocaustForm} />
                <Route exact path='/admin/holocaust/all' component={HolocaustList} />



                <Route exact path='/prayRequest' component={PrayRequest} />
                <Route exact path='/comments' component={Comments} />
                <Route exact path='/greetings' component={Greetings} />
                <Route exact path='/welcomeNofitication' component={WelcomeNotification} />
                <Route exact path='/soundRequest' component={SoundRequest} />
                <Route exact path='/generalInformation' component={GeneralInfo} />
                <Route exact path='/questions' component={Questions} />
                <Route exact path='/externalFilter' component={ExternalFilter} />


                <Route exact path='/accepted' component={AlertyConfirmation} />

            </Router>
        </AuthProvider>
    );

}

export default Routes;