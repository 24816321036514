/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'


/**Transitions  */

import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';

import { Link } from 'react-router-dom';





/** Config. Firebase */

import app from '../../firebase';
const db = app.firestore();




const PrayRequestFilter = () => {



    const handleViernes = async () => {
        await localStorage.setItem('@dia', 'viernes');

    }
    const handleSabadoMatutino = async () => {
        await localStorage.setItem('@dia', 'sabadomatutino');

    }
    const handleSabadoVespertino = async () => {
        await localStorage.setItem('@dia', 'sabadovespertino');

    }


    const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
        return <Redirect to='/login' />
    }






    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                        <div className="justify-content-center">
                            <div style={{ height: '30px' }}></div>

                            <div className='toCenter'>
                                <h1 style={{ fontWeight: '700', color: '#fff' }}>Listado de  Oraciones</h1>
                                <h4 className='mt-4' style={{ color: '#fff' }}>Filtrar por</h4>
                            </div>
                        </div>


                        <div className='gruopButons' className='filterDays'>

                            <Link className='botonDecision' onClick={handleViernes} style={{ width: '300px' }} to='/admin/prayrequestlist'>Recibimiento del viernes</Link>
                            <Link className='botonDecision' onClick={handleSabadoMatutino} style={{ width: '300px' }} to='/admin/prayrequestlist'>Sábado matutino</Link>
                            <Link className='botonDecision' onClick={handleSabadoVespertino} style={{ width: '300px' }} to='/admin/prayrequestlist'>Sábado vespertino</Link>
                        </div>

                    </div>








                </Fragment>
            </Paper>
        </Fade>
    );

}

export default PrayRequestFilter;