import React, { Fragment } from 'react';

/**Transitions  */

import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';

import { Link } from 'react-router-dom';

function WelcomeNotification() {

    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='containerCenter'>
                        <div className="justify-content-center">

                            <div className='toCenter'>
                                <h4>¿En qué podemos ayudarte?</h4>
                                <h6 className='m-2'>Seleccione una opcion</h6>
                                <div className="input-group">
                                    <select className="m-4 custom-select" id="inputGroupSelect04">
                                        <option value="Petición de Oración" selected>Petición de Oración</option>
                                        <option value="Hacer un comentario de La Escuela Sabática o Predicación">Hacer un comentario de La Escuela Sabática o Predicación</option>
                                        <option value="Enviar Saludos">Enviar Saludos</option>
                                        <option value="Informar de una Bienvenida">Informar de una Bienvenida</option>
                                        <option value="Solicitar un Canto, Himno o Salmo">Solicitar un Canto, Himno o Salmo</option>
                                        <option value="Solictar Informes">Solictar Informes</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div className='gruopButons'>
                            <Link className='botonLlamar' to='/helpFilter'>Continuar</Link>
                        </div>
        
                    </div>








                </Fragment>
            </Paper>
        </Fade>
    );

}

export default WelcomeNotification;