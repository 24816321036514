/**React imports */
import React, { useEffect, Fragment } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';



const ButtonCardService = (props) => {


    return (
        <Fragment>
            <div>
                <Link to={props.view}>
                    <div

                        style={{
                            justifyContent: 'center',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `linear-gradient(
                        325deg,
                        rgba(0, 36, 51, 1) 0%,
                        rgba(98, 218, 255, 0.164) 100%
                        ),url(${(props.url)})`
                        }}  className="buttonCardNote">


                        <p style={{ fontSize: '24px', color: '#fff' }}>{props.titleCard}</p>

                    </div>
                </Link>
            </div>


        </Fragment>
    )

}


export default ButtonCardService;