/**React imports */
import React, { useEffect, Fragment } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../Navigation.css';

/**Icons  */
import CheckIcon from '../assets/icons/check.png'


/**Transitions */
import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';

/**Animations */




const _redirectToDashboard = () => {

    setTimeout(() => {
        localStorage.clear()
        window.location.href ='/'
    }, 3000);
}


const AlertyConfirmation = (props) => {


    useEffect(() => {
        window.scrollTo(0, 0);
        _redirectToDashboard()
    })



    return (

        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className="contentMain containerCenter">
                        <div style={{ height: "60px" }}></div>

                        <div style={{ textAlign: "center" }}>
                            <h3 style={{fontWeight:'700',color:'#fff',fontSize:"45px"}} className="selectLensText">Información recibida correctamente</h3>
                            <img src={CheckIcon} width="300px"></img>
                            <p style={{ color: "#fff", fontSize: "20px" }}>Pronto te atenderemos</p>
                        </div>

                    </div>

                </Fragment>
            </Paper>



        </Fade>


    );



}


export default AlertyConfirmation;