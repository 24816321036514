import React, { useState, Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'

import EventForm from './EventForm'


import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';

import Swal from 'sweetalert2';


/** Config. Firebase */

import app from '../../firebase';
const db = app.firestore();


const EventList = () => {


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });






    const [links, setLinks] = useState([])
    const [currentId, setCurrentId] = useState('')






    const addOrEditLink = async (linkObject) => {
        try {
            if (currentId === '') {
                await db.collection('posts').doc().set(linkObject)
                Toast.fire({
                    icon: 'success',
                    type: 'info',
                    background: "#fff",
                    title: "Evento creado correctamente"
                });
            } else {
                await db.collection('posts').doc(currentId).update(linkObject);
                Toast.fire({
                    icon: 'success',
                    type: 'info',
                    background: "#fff",
                    title: "Evento actualizado correctamente"
                });
                setCurrentId('');

            }
        } catch (error) {
            console.log(error)
        }
    }


    const getLinks = async () => {
        db.collection('posts')
            .orderBy('name', 'desc')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setLinks(docs)
            });

    }

    const onDelete = async (id) => {
        if (window.confirm('¿Eliminar evento?')) {
            await db.collection('posts').doc(id).delete();



            Toast.fire({
                icon: 'success',
                type: 'success',
                background: "#fff",
                title: "Evento eliminado correctamente"
            })
        }
    }

    useEffect(() => {

        getLinks()

    }, []);

    const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
        return <Redirect to='/login' />
    }


    return (
        <Fade in={true}>

            <Paper elevation={0}>
                <div className='contentMain containerCenter'>

                    <h3 style={{ color: '#fff',fontWeight:'700' }}>Gestor de eventos</h3>

                    <div className="col-md-4 p-2">
                        <EventForm {...{ addOrEditLink, currentId, links }} />
                    </div>
                    <div>
                        {links.map((event) => (
                            <div className="evento mb-1" key={event.id}>
                                <div className="card-body">
                                    <h6>{event.dateEvent}</h6>
                                    <div className='d-flex justify-content-between'>
                                        <h4>{event.name}</h4>
                                        <div>
                                            <button onClick={() => onDelete(event.id)} className='btn btn-links material-icons text-danger'>close</button>
                                            <button className='btn btn-links material-icons' onClick={() => setCurrentId(event.id)} >edit</button>
                                        </div>
                                    </div>
                                    <p>{event.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </Paper>

        </Fade>


    )
}

export default EventList;