/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Fade } from '@material-ui/core';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

import moment from 'moment'

//import emailjs

import emailjs from 'emailjs-com';



/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faWindowClose, faCheckSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const WorshipReception = () => {

    const [worship, setWorship] = useState('');
    const [currentId, setCurrentId] = useState('');





    //Creando los states

    const [alabanzaApertura, setAlabanzaApertura] = useState('');
    const [alabanza, setAlabanza] = useState('');
    const [alabanzaPredicacion, setAlabanzaPredicacion] = useState('');
    const [alabanzaAlusiva, setAlabanzaAlusiva] = useState('');
    const [bienvenidas, setBienvenidas] = useState('');
    const [alabanzaDespedida, setAlabanzaDespedida] = useState('');


    // configurando handlers

    const handleAlabanzaApertura = (e) => {
        worship["alabanzaApertura"] = e.target.value;
    }

    const handleAlabanza = (e) => {
        setAlabanza(e.target.value);
    }

    const handleAlabanzaPredicacion = (e) => {
        setAlabanzaPredicacion(e.target.value);
    }

    const handleAlabanzaAlusiva = (e) => {
        setAlabanzaAlusiva(e.target.value);
    }

    const handleBienvenidas = (e) => {
        setBienvenidas(e.target.value);
    }

    const handleAlabanzaDespedida = (e) => {
        setAlabanzaDespedida(e.target.value);
    }

    //Obtiene la data de un localstorage

    const getWorshipData = async () => {
        let worshipAux = await localStorage.getItem('@preWorship')
        worshipAux = JSON.parse(worshipAux);

        let currentId = await localStorage.getItem('@currentId')
        currentId = JSON.parse(currentId);
        setCurrentId(currentId)


        setWorship(worshipAux)
    }



    const translateDateToSpanish = () => {

        moment.lang('es', {
            months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
            monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
            weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
            weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
            weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
        }
        );

        var localLocale = moment(worship.worshipDate);
        moment.locale('es');
        return localLocale.format('Do MMMM YYYY ');

    }


    const handleFinishWorshipConfiguration = async (e) => {

        e.preventDefault()

        await db.collection("cultos").doc(currentId).update(worship);



        Swal.fire({
            title: "<i>Listo</i>",
            html: "Serás redirigido al histórico",
            confirmButtonColor: "#006279",
            confirmButtonText: "Aceptar",
            icon: "success"
        }).then(() => {
            localStorage.clear()
            window.location.href = "/admin/cultos/proximos";
        })

    }

    const finalizeWorship = async (e) => {

        e.preventDefault()

        await db.collection('cultosAnteriores').add(worship).then(() => {

            db.collection("cultos").doc(currentId).delete().then(() => {
                Swal.fire({
                    title: "<i>¡Culto terminado!</i>",
                    html: "<span className='m-4'>Serás redirigido al histórico</span>",
                    confirmButtonColor: "#006279",
                    confirmButtonText: "Aceptar",
                    icon: "success"
                }).then(() => {
                    localStorage.clear()
                    window.location.href = "/admin/cultos/proximos";
                })
            })
        })

    }



    function addField(table) {


        var tableRef = document.getElementById("table1");
        var newRow = tableRef.insertRow(-1);

        var newCell = newRow.insertCell(0);
        var newElem = document.createElement('input');
        newElem.setAttribute("name", "links");
        newElem.setAttribute("type", "text");
        newCell.appendChild(newElem);

        newCell = newRow.insertCell(1);
        newElem = document.createElement('input');
        newElem.setAttribute("name", "keywords");
        newElem.setAttribute("type", "text");
        newCell.appendChild(newElem);

        newCell = newRow.insertCell(2);
        newElem = document.createElement('input');
        newElem.setAttribute("name", "violationtype");
        newElem.setAttribute("type", "text");
        newCell.appendChild(newElem);

        newCell = newRow.insertCell(3);
        newElem = document.createElement('input');
        newElem.setAttribute("type", "button");
        newElem.setAttribute("value", "Delete Row");
        newElem.setAttribute("onclick", 'SomeDeleteRowFunction(this)')
        newCell.appendChild(newElem);



        window.SomeDeleteRowFunction = function SomeDeleteRowFunction(o) {
            var p = o.parentNode.parentNode;
            p.parentNode.removeChild(p);
        }
    }



    const [worshipHolocaust, setWorshipHolocaust] = useState([])

    const [numberOfHolocaust, setNumberOfHolocaust] = useState([]);
    const getHolocaustFrom = (currentId) => {

        //     let cantosAux = worship["cantos"];

        db.collection('holocaustos').orderBy('datePresent', 'desc').limit(7)
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                    console.log(doc.id)
                });
                setWorshipHolocaust(docs)

            });

    }


    const getNumberOfHolocaust = () => {

        //     let cantosAux = worship["cantos"];

        db.collection('holocaustos').orderBy('datePresent', 'desc')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                    console.log(doc.id)
                });
                setNumberOfHolocaust(docs)

            });

    }


    const getNameGroupOrFamily = (currentHolocaust) => {
        switch (currentHolocaust.participationTypes) {
            case "Individual":
                return currentHolocaust.registerName + "" + currentHolocaust.registerFirstName + " " + currentHolocaust.registerLastName
                break;

            case "Grupal":
                return currentHolocaust.groupName + " / " + currentHolocaust.holocaustTypes
                break;

            case "Familia":
                return currentHolocaust.groupName + " / " + currentHolocaust.holocaustTypes
                break;
            default:
                break;
        }
    }


    const rejectHolocaust = (holocaust) => {

        let razonRechazo = prompt("Ingrese motivo de eliminación de holocausto");

        let solucionRechazo = prompt("Ingrese solución");



        if (window.confirm("¿Rechazar?")) {


            db.collection("holocaustos").doc(holocaust.id).delete().then(() => {


                emailjs.send("churubusco_privatemail", "template_rechazo", {
                    nombreRegistrante: holocaust.registerName + " " + holocaust.registerFirstName + " " + holocaust.registerLastName,
                    emailRegistrante: holocaust.registerEmail,
                    motivoRechazo: razonRechazo,
                    solucionRechazo: solucionRechazo
                }, "user_BU2y1xMxhazWdSDHcARB6").then(() => {

                    Swal.fire({
                        title: "<i className='m-4'>Holocausto rechazado</i>",
                        html: "<span className='m-4'>Se ha informado al registrante que se ha rechazado el holocausto por: " + razonRechazo + "</span>",
                        confirmButtonColor: "#006279",
                        confirmButtonText: "Aceptar",
                        icon: "info"
                    })
                })

            })
        }

    }


    const confirmHolocaust = (holocaust) => {


        if (window.confirm("¿Confirmar?")) {

            let datePresentConfirmed = prompt("El holocausto será presentado el: " + holocaust.datePresent + " confirme fecha");

            let hourConfirmed = prompt("Ingrese hora de presentación");



            db.collection("holocaustos").doc(holocaust.id).update({
                confirmado: true,
                datePresent: datePresentConfirmed,
                hourConfirmed: hourConfirmed
            }).then(() => {


                emailjs.send("churubusco_privatemail", "template_aceptacion", {
                    nombreRegistrante: holocaust.registerName + " " + holocaust.registerFirstName + " " + holocaust.registerLastName,
                    emailRegistrante: holocaust.registerEmail,
                    worshipDatePresent: datePresentConfirmed,
                    worshipStartTime: hourConfirmed
                }, "user_BU2y1xMxhazWdSDHcARB6").then(() => {

                    Swal.fire({
                        title: "<i className='m-4'>Holocausto confirmado</i>",
                        html: "<span className='m-4'>Se ha informado al registrante que se ha aceptado el holocausto</span>",
                        confirmButtonColor: "#006279",
                        confirmButtonText: "Aceptar",
                        icon: "success"
                    })
                })

            })
        }else{
            alert("El holocausto no fue confirmado")
        }

    }


    const createTemplateFromWorshipType = () => {

        switch (worship.worshipType) {
            case "Culto Matutino":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzasPoteriorLey"] != undefined ? worship["alabanzasPoteriorLey"] : ""} onChange={e => worship["alabanzasPoteriorLey"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Distribución de Clases
                                </td>
                                <td><input defaultValue={worship["oracionClases"]} onChange={e => worship["oracionClases"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Escuela Sabática
                                </td>
                                <td><input defaultValue={worship["escuelaSabatica"]} onChange={e => worship["escuelaSabatica"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema
                                </td>
                                <td><input defaultValue={worship["alabanzaAlusiva"]} onChange={e => worship["alabanzaAlusiva"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Presentación de la Lección Infantil (Medianos)
                                </td>
                                <td><input defaultValue={worship["presentacionInfantilMedianos"]} onChange={e => worship["presentacionInfantilMedianos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Presentación de la Lección Infantil (Chicos)
                                </td>
                                <td><input defaultValue={worship["presentacionInfantilChicos"]} onChange={e => worship["presentacionInfantilChicos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteriorEscuelaInfantil"]} onChange={e => worship["alabanzaPosteriorEscuelaInfantil"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Acto Especial (Presentación de Niños, Entrega de Biblias, Bienvenidas, etc)
                                </td>
                                <td><input defaultValue={worship["actoEspecial"]} onChange={e => worship["actoEspecial"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Vespertino":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzasPoteriorLey"] != undefined ? worship["alabanzasPoteriorLey"] : ""} onChange={e => worship["alabanzasPoteriorLey"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Distribución de Clases
                                </td>
                                <td><input defaultValue={worship["oracionClases"]} onChange={e => worship["oracionClases"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Escuela Sabática
                                </td>
                                <td><input defaultValue={worship["escuelaSabatica"]} onChange={e => worship["escuelaSabatica"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema
                                </td>
                                <td><input defaultValue={worship["alabanzaAlusiva"]} onChange={e => worship["alabanzaAlusiva"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Presentación de la Lección Infantil (Medianos)
                                </td>
                                <td><input defaultValue={worship["presentacionInfantilMedianos"]} onChange={e => worship["presentacionInfantilMedianos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Presentación de la Lección Infantil (Chicos)
                                </td>
                                <td><input defaultValue={worship["presentacionInfantilChicos"]} onChange={e => worship["presentacionInfantilChicos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteriorEscuelaInfantil"]} onChange={e => worship["alabanzaPosteriorEscuelaInfantil"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Acto Especial (Presentación de Niños, Entrega de Biblias, Bienvenidas, etc)
                                </td>
                                <td><input defaultValue={worship["actoEspecial"]} onChange={e => worship["actoEspecial"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto de Recibimiento":
                return (

                    <table id="table1" className="table bordered">
                        <tbody>

                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Para la Predicación
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Para la Predicación
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["predicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaAlusiva"]} onChange={e => worship["alabanzaAlusiva"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas a Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )

                break;



            case "Culto Cabañas Día 1 (Recibimiento)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzasPoteriorLey"] != undefined ? worship["alabanzasPoteriorLey"] : ""} onChange={e => worship["alabanzasPoteriorLey"] = e.target.value} ></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Acto Especial (Presentación de Niños, Entrega de Biblias, Bienvenidas, etc)
                                </td>
                                <td><input defaultValue={worship["actoEspecial"]} onChange={e => worship["actoEspecial"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas Día 1 (Matutino)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Acto Especial (Presentación de Niños, Entrega de Biblias, Bienvenidas, etc)
                                </td>
                                <td><input defaultValue={worship["actoEspecial"]} onChange={e => worship["actoEspecial"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )

            case "Culto Cabañas (Fin Día 1 + Recibimiento Día 2)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas y Entrega de Presentes a Obreros Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;
            case "Culto Cabañas (Fin Día 2 + Recibimiento Día 3)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas y Entrega de Presentes a Obreros Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas (Fin Día 3 + Recibimiento Día 4)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas y Entrega de Presentes a Obreros Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas (Fin Día 4 + Recibimiento Día 5)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas y Entrega de Presentes a Obreros Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas (Fin Día 5 + Recibimiento Día 6)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas y Entrega de Presentes a Obreros Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas (Fin Día 6 + Recibimiento Día 7)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas y Entrega de Presentes a Obreros Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas (Fin Día 7 + Recibimiento Día 8)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Recolección de Palmas y Fruta
                                </td>
                                <td><input defaultValue={worship["recoleccionPalmasFruta"]} onChange={e => worship["recoleccionPalmasFruta"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Reconocimientos y Entrega de Presentes a Obreros Locales
                                </td>
                                <td><input defaultValue={worship["reconocimientos"]} onChange={e => worship["reconocimientos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas Día 8 (Matutino)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Acto Especial (Presentación de Niños, Entrega de Biblias, Bienvenidas, etc)
                                </td>
                                <td><input defaultValue={worship["actoEspecial"]} onChange={e => worship["actoEspecial"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
                break;

            case "Culto Cabañas Día 8 (Vespertino)":
                return (
                    <table id="table1" className="table bordered">
                        <tbody>
                            <tr>
                                <td><input defaultValue={worship["startTime"]}></input></td>
                                <td>
                                    Superintendente
                                </td>
                                <td><input defaultValue={worship["superIntendente"]} onChange={e => worship["superIntendente"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Apertura
                                </td>
                                <td><input defaultValue={worship["alabanzaApertura"]} onChange={e => worship["alabanzaApertura"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración
                                </td>
                                <td><input defaultValue={worship["oracionInicial"]} onChange={e => worship["oracionInicial"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanza"]} onChange={e => worship["alabanza"] = e.target.value} ></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura Bíblica Previa a la Ley
                                </td>
                                <td><input defaultValue={worship["lecturaPreviaLey"]} onChange={e => worship["lecturaPreviaLey"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Lectura De La Ley
                                </td>
                                <td><input defaultValue={worship["lecturaLey"]} onChange={e => worship["lecturaLey"] = e.target.value}></input></td>
                            </tr>


                            <tr id="alabanzasPoteriorLey">
                                <td><input></input></td>
                                <td>
                                    Palabras Alusivas
                                </td>
                                <td><input defaultValue={worship["palabrasAlusivas"] != undefined ? worship["palabrasAlusivas"] : ""} onChange={e => worship["palabrasAlusivas"] = e.target.value} ></input></td>
                            </tr>


                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Por los Holocaustos
                                </td>
                                <td><input defaultValue={worship["oracionHolocaustos"]} onChange={e => worship["oracionHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td></td>
                                <td>
                                    <div class="alert alert-secondary" role="alert">
                                        Espacio Para Holocaustos y Números Especiales
                                    </div>
                                </td>
                                <td></td>
                            </tr>

                            {
                                worshipHolocaust.length == 0 ? "Registros cargados: " + worshipHolocaust.length :
                                    worshipHolocaust.map((holocaust) => (
                                        <tr className="alert alert-success">

                                            <td>

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <span class="badge badge-dark ml-2">No confirmado</span>
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                                {
                                                    holocaust.confirmado == false ? <>
                                                        <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                                                        <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />
                                                    </>
                                                        :
                                                        <>
                                                            <span class="badge badge-primary ml-2">Confirmado</span>
                                                        </>

                                                }

                                            </td>
                                            <td>
                                                <span>{getNameGroupOrFamily(holocaust)} / {holocaust.titleHolocaust}</span>
                                            </td>
                                            <td>
                                                <a href={holocaust.urlFile} target="_blank" className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                                                </a>
                                            </td>
                                            <td>
                                                <button className='btn'>
                                                    <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                                                </button>
                                            </td>
                                        </tr>

                                    ))
                            }

                            <tr>
                                <td></td>
                                <Link type="button" to={"/admin/holocaust/all"} class="btn m-3 btn-primary">
                                    Ver <span class="badge badge-light">{numberOfHolocaust.length} registros más</span>
                                </Link>
                                <td></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza
                                </td>
                                <td><input defaultValue={worship["alabanzaPosteiorHolocaustos"]} onChange={e => worship["alabanzaPosteiorHolocaustos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Para la Predicación
                                </td>
                                <td><input defaultValue={worship["oracionPredicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Predicación
                                </td>
                                <td><input defaultValue={worship["predicacion"]} onChange={e => worship["oracionPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)
                                </td>
                                <td><input defaultValue={worship["alabanzaPredicacion"]} onChange={e => worship["alabanzaPredicacion"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bienvenidas y Entrega de Presentes a Obreros Visitantes
                                </td>
                                <td><input defaultValue={worship["bienvenidas"]} onChange={e => worship["bienvenidas"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Avisos
                                </td>
                                <td><input defaultValue={worship["avisos"]} onChange={e => worship["avisos"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Alabanza de Despedida
                                </td>
                                <td><input defaultValue={worship["alabanzaDespedida"]} onChange={e => worship["alabanzaDespedida"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Oración Final
                                </td>
                                <td><input defaultValue={worship["oracionFinal"]} onChange={e => worship["oracionFinal"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["bendicionDeDios"]} onChange={e => worship["bendicionDeDios"] = e.target.value}></input></td>
                            </tr>

                            <tr>
                                <td><input></input></td>
                                <td>
                                    Bendición de Dios
                                </td>
                                <td><input defaultValue={worship["cena"]} onChange={e => worship["cena"] = e.target.value}></input></td>
                            </tr>
                        </tbody>
                    </table>
                )
            default:
                return (
                    <div>
                        <span style={{ color: "#fff" }}>¡Espéralo muy pronto!</span>
                        <a href="admin/main" type="button" class="btn btn-primary">
                            <span class="badge badge-light">Volver al main</span>
                        </a>
                    </div>

                )
                break;
        }

    }

    useEffect(() => {
        getWorshipData()
        createTemplateFromWorshipType()
        translateDateToSpanish()
        getHolocaustFrom()
        getNumberOfHolocaust()
    }, [])

    return (

        <Fade in="true">
            <Fragment>
                <div className='contenedor seccionCentrada'>
                    <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                    <div className="justify-content-center">
                        <center>
                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>
                                    Programación de Culto del {worship.worshipType} {translateDateToSpanish()}</span>
                            </button>
                        </center>

                        <button type="button" class="btn btn-primary">
                            Hora de inicio: <span class="badge badge-light">{worship["startTime"]}</span>
                        </button>

                        <div style={{ backgroundColor: "#fff", textAlign: "right" }}>
                            <button className="btn btn-primary m-4" onClick={handleFinishWorshipConfiguration}>Guardar</button>
                            <button className="btn btn-secondary m-4" onClick={finalizeWorship}>Terminar</button>

                            {
                                createTemplateFromWorshipType()
                            }


                            <button className="btn btn-primary m-4" onClick={handleFinishWorshipConfiguration}>Guardar</button>

                        </div>
                    </div>
                </div>
            </Fragment>
        </Fade>

    )
}

export default WorshipReception;