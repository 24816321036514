/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**Router */

/**Css styles */
import '../../Navigation.css';

import LOGOIDI from "../../assets/icons/escudoblanco.png";

/**Transitions */
import Paper from '@material-ui/core/Paper';
import { Button, Fade } from '@material-ui/core';

import CardService from './CardService';
import Swal from 'sweetalert2'



/** Config. Firebase */

import app from '../../firebase';
const db = app.firestore();




const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});


const searcher = () => {

    var input, filter, table, tr, td, cell, i, j;
    input = document.getElementById("inputBuscarPedido");
    filter = input.value.toUpperCase();
    table = document.getElementById("ordersTable");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
        // Hide the row initially.
        tr[i].style.display = "none";

        td = tr[i].getElementsByTagName("td");
        for (var j = 0; j < td.length; j++) {
            cell = tr[i].getElementsByTagName("td")[j];
            if (cell) {
                if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                    break;
                }
            }
        }
    }
}


const verifyAuth = () => {
    var user = app.auth().currentUser;

    if (user) {
        console.log('User successfully authenticated')
    } else {
        window.location.replace("/login");

    }

}

const cerrarSesion = () => {
    app.auth().signOut().then(() => {
        window.location.replace("/login");
    })
}




const DashboardAdmin = () => {

    const [link, setLink] = useState('');




    const handleVideoLinkChange = (e) => {
        const { value } = e.target;
        setLink(value)
    }

    const addOrEditLink = async () => {



        var today = new Date();

        const linkObject = {
            link: `https://www.youtube.com/embed/${link}`,
            date: today
        }
        try {



            if (link !== "") {
                document.querySelector("#iLink").className = 'form-control correctNumber';
                await db.collection('video').doc().set(linkObject)
                Toast.fire({
                    type: 'info',
                    background: "#CDFFEC",
                    title: "Se ha compartido el video"
                });

                document.querySelector("#iLink").value = ''
                setLink('')
            } else {
                Toast.fire({
                    type: 'info',
                    background: "#FFB0B0",
                    title: "Clave inválida, porporciona una clave de vídeo válida"
                });
                document.querySelector("#iLink").className = 'form-control invalidNumber';
            }




        } catch (error) {
            console.log(error)
        }
    }


   

    useEffect(() => {
        verifyAuth();
        window.scrollTo(0, 0);
        document.title = 'Panel de Control'
    })

    const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
        return <Redirect to='/login' />
    }






    return (
        <Fade in={true}>
            <Paper elevation={0}>


                <Fragment>
                    <section className='contentMain containerCenter'>



                        <img src={LOGOIDI} className="logoIDI" alt="Logo ID(I)" />


                        <button className="mb-4 custom-btn btn-5">
                            <span style={{ fontWeight: '700', color: '#fff' }}>Panel de Control</span>
                        </button>



                        <div className='transmition' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '20px' }}>


                            <div style={{ width: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>






                                <div style={{ backgroundColor: '#fff' }} className="searchSection">


                                    <h5>Enlace de Transmisión en Vivo</h5>


                                    <div style={{ height: "20px" }}></div>

                                    <div className='linkSection'>
                                        <div class="input-group link" >
                                            <input onChange={handleVideoLinkChange} id='iLink' type="text" className="form-control m-2 inputLink" placeholder="URL" aria-label="URL" ></input>
                                            <Button className='btn btnAtenderPedido m-2' onClick={addOrEditLink}> Compartir</Button>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>

                        <div className='activitySection'>

                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ fontWeight: '700', color: '#fff' }}>Actividades</span>
                            </button>
                            <div className="services">
                                <CardService url='https://blog.connext.es/hubfs/crm-blog.png' titleCard={'CRM'} view={'/admin/main'} />
                                <CardService url='https://images.unsplash.com/photo-1567781343935-5e408319f723?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' titleCard={'Peticiones de oración'} view={'/admin/prayrequestfilter'} />
                                <CardService url='https://images.unsplash.com/photo-1498192467103-290f567eb3a3?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=750&q=80' titleCard={'Preguntas'} view={'/admin/questionlist'} />
                                <CardService url='https://images.unsplash.com/photo-1551847609-5aaf21a58ebc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' titleCard={'Comentarios'} view={'/admin/commentslist'} />
                                <CardService url='https://images.unsplash.com/photo-1488509082528-cefbba5ad692?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' titleCard={'Solicitudes de Informes'} view={'/admin/inforequestlist'} />
                                <CardService url='https://images.unsplash.com/photo-1535712593684-0efd191312bb?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=749&q=80' titleCard={'Solicitudes de cantos'} view={'/admin/soundlist'} />
                                <CardService url='https://images.unsplash.com/photo-1579600161224-cac5a2971069?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=751&q=80' titleCard={'Bienvenidas'} view={'/admin/welcomelist'} />
                                <CardService url='https://images.unsplash.com/photo-1595013008294-506e8f306d3c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80' titleCard={'Saludos'} view={'/admin/greetingslist'} />
                                <CardService titleCard={'Eventos'} url={'https://images.unsplash.com/photo-1482952980430-be6f9ff18749?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'} view={'/admin/eventos'} />

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '40px' }}>
                            <button type='submit' onClick={() => cerrarSesion()} id="btnContinuar" className="btn btn-danger">Cerrar sesión</button>
                        </div>
                    </section>
                </Fragment>
            </Paper>
        </Fade>
    )
}


export default DashboardAdmin;