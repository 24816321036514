import React, { Fragment, useEffect, useState } from 'react';


import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';
import Swal from 'sweetalert2'
import LOGOIDI from "../assets/icons/escudoblanco.png";
import { Link } from 'react-router-dom';

function HelpFilter() {


    const [internalService, setInternalService] = useState([
        "Petición de Oración",
        "Hacer un comentario de La Escuela Sabática o Predicación",
        "Hacer una pregunta referente a la Escuela Sabática o Predicación",
        "Enviar Saludos",
        "Informar de una Bienvenida",
        "Solicitar un Canto, Himno o Salmo",
        "Solictar Informes"
    ])


    const [externalService, setExternalService] = useState([
        "Petición de Oración",
        "Hacer un comentario de La Escuela Sabática o Predicación",
        "Hacer una pregunta referente a la Escuela Sabática o Predicación",
        "Enviar Saludos",
        "Solictar Informes"
    ])


    const externalServices = externalService.map(externalServices => externalServices);



    const [view, setView] = useState('/helpFilter');



    const [userType, setUserType] = useState('')

    const [ticket, setTicket] = useState('')
    const [name, setName] = useState('')

    const [loading, setLoading] = useState('')




    const getUserType = async () => {

        setLoading(true)
        let ticketAux = await localStorage.getItem('@ticket')



        ticketAux = JSON.parse(ticketAux);
        await setTicket(ticketAux)

        await setUserType(ticketAux.externo)
        await setName(ticketAux.usuario.nombreCompleto)
        setLoading(false)

    }


    useEffect(() => {
        getUserType()
        verifyGreeting()
        getServices()
    }, [])

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });


    let handleRedirect = async (e) => {
        if (internalService[e.target.value] != "") {
            console.log(internalService[e.target.value])

            switch (internalService[e.target.value]) {
                case 'Petición de Oración':

                    const ticketPray = {
                        asunto: {
                            titulo: internalService[e.target.value],
                            categoria: '',
                            contenido: ''
                        },
                        usuario: {
                            nombreCompleto: ticket.usuario.nombreCompleto,
                            localidad: '',
                            denominacion: ticket.usuario.denominacion,
                            correoElectronico: '',
                            pregunta: '',
                            respuesta: '',
                            mensaje: ''
                        },
                        externo: ticket.externo
                    }
                    await localStorage.setItem('@ticket', JSON.stringify(ticketPray));

                    setView('/prayRequest');
                    break;
                case 'Hacer un comentario de La Escuela Sabática o Predicación':
                    const ticketComment = {
                        asunto: {
                            titulo: internalService[e.target.value],
                            categoria: '',
                            contenido: ''
                        },
                        usuario: {
                            nombreCompleto: ticket.usuario.nombreCompleto,
                            localidad: '',
                            denominacion: ticket.usuario.denominacion,
                            correoElectronico: '',
                            pregunta: '',
                            respuesta: '',
                            mensaje: ''
                        },
                        externo: ticket.externo
                    }
                    await localStorage.setItem('@ticket', JSON.stringify(ticketComment));
                    setView('/formContact');
                    break;
                case 'Hacer una pregunta referente a la Escuela Sabática o Predicación':
                    const ticketQuestion = {
                        asunto: {
                            titulo: internalService[e.target.value],
                            categoria: '',
                            contenido: ''
                        },
                        usuario: {
                            nombreCompleto: ticket.usuario.nombreCompleto,
                            localidad: '',
                            denominacion: ticket.usuario.denominacion,
                            correoElectronico: '',
                            pregunta: '',
                            respuesta: '',
                            mensaje: ''
                        },
                        externo: ticket.externo
                    }
                    await localStorage.setItem('@ticket', JSON.stringify(ticketQuestion));
                    setView('/formContact');
                    break;
                case 'Enviar Saludos':
                    const ticketGreetings = {
                        asunto: {
                            titulo: internalService[e.target.value],
                            categoria: '',
                            contenido: ''
                        },
                        usuario: {
                            nombreCompleto: ticket.usuario.nombreCompleto,
                            localidad: '',
                            denominacion: ticket.usuario.denominacion,
                            correoElectronico: '',
                            pregunta: '',
                            respuesta: '',
                            mensaje: ''
                        },
                        externo: ticket.externo
                    }
                    await localStorage.setItem('@ticket', JSON.stringify(ticketGreetings));
                    setView('/formContact');
                    break;
                case 'Informar de una Bienvenida':
                    const ticketWelcome = {
                        asunto: {
                            titulo: internalService[e.target.value],
                            categoria: '',
                            contenido: ''
                        },
                        usuario: {
                            nombreCompleto: ticket.usuario.nombreCompleto,
                            localidad: '',
                            denominacion: ticket.usuario.denominacion,
                            correoElectronico: '',
                            pregunta: '',
                            respuesta: '',
                            mensaje: ''
                        },
                        externo: ticket.externo
                    }
                    await localStorage.setItem('@ticket', JSON.stringify(ticketWelcome));
                    setView('/formContact');
                    break;
                case 'Solicitar un Canto, Himno o Salmo':
                    const ticketSoundRequest = {
                        asunto: {
                            titulo: internalService[e.target.value],
                            categoria: '',
                            contenido: ''
                        },
                        usuario: {
                            nombreCompleto: ticket.usuario.nombreCompleto,
                            localidad: '',
                            denominacion: ticket.usuario.denominacion,
                            correoElectronico: '',
                            pregunta: '',
                            respuesta: '',
                            mensaje: ''
                        },
                        externo: ticket.externo
                    }
                    await localStorage.setItem('@ticket', JSON.stringify(ticketSoundRequest));
                    setView('/formContact');
                    break;
                case 'Solictar Informes':
                    const generalInfoRequest = {
                        asunto: {
                            titulo: internalService[e.target.value],
                            categoria: '',
                            contenido: ''
                        },
                        usuario: {
                            nombreCompleto: ticket.usuario.nombreCompleto,
                            localidad: '',
                            denominacion: ticket.usuario.denominacion,
                            correoElectronico: '',
                            pregunta: '',
                            respuesta: '',
                            mensaje: ''
                        },
                        externo: ticket.externo
                    }
                    await localStorage.setItem('@ticket', JSON.stringify(generalInfoRequest));
                    setView('/formContact');
                    break;
                default:
                    setView('/helpFilter')
                    let alerta = document.createElement("div");
                    alerta.className = "AlertError";
                    let texto = document.createTextNode("Selecciona una opción");
        
                    alerta.appendChild(texto);
        
                    document.querySelector("#areaAlerta").appendChild(alerta);
        
                    setTimeout(() => {
                        alerta.remove();
                    }, 2000);
                    break;
            }
        } else {

            setView('/helpFilter')
            let alerta = document.createElement("div");
            alerta.className = "AlertError";
            let texto = document.createTextNode("Selecciona una opción");

            alerta.appendChild(texto);

            document.querySelector("#areaAlerta").appendChild(alerta);

            setTimeout(() => {
                alerta.remove();
            }, 2000);
        }


    }

    let handleRedirectExternal = async (e) => {
        switch (externalService[e.target.value]) {
            case 'Petición de Oración':

                const ticketPray = {
                    asunto: {
                        titulo: externalService[e.target.value],
                        categoria: '',
                        contenido: ''
                    },
                    usuario: {
                        nombreCompleto: ticket.usuario.nombreCompleto,
                        localidad: '',
                        denominacion: ticket.usuario.denominacion,
                        correoElectronico: '',
                        pregunta: '',
                        respuesta: '',
                        mensaje: ''
                    },
                    externo: ticket.externo
                }
                await localStorage.setItem('@ticket', JSON.stringify(ticketPray));

                setView('/prayRequest');
                break;
            case 'Hacer un comentario de La Escuela Sabática o Predicación':
                const ticketComment = {
                    asunto: {
                        titulo: externalService[e.target.value],
                        categoria: '',
                        contenido: ''
                    },
                    usuario: {
                        nombreCompleto: ticket.usuario.nombreCompleto,
                        localidad: '',
                        denominacion: ticket.usuario.denominacion,
                        correoElectronico: '',
                        pregunta: '',
                        respuesta: '',
                        mensaje: ''
                    },
                    externo: ticket.externo
                }
                await localStorage.setItem('@ticket', JSON.stringify(ticketComment));
                setView('/formContact');
                break;
            case 'Hacer una pregunta referente a la Escuela Sabática o Predicación':
                const ticketQuestion = {
                    asunto: {
                        titulo: externalService[e.target.value],
                        categoria: '',
                        contenido: ''
                    },
                    usuario: {
                        nombreCompleto: ticket.usuario.nombreCompleto,
                        localidad: '',
                        denominacion: ticket.usuario.denominacion,
                        correoElectronico: '',
                        pregunta: '',
                        respuesta: '',
                        mensaje: ''
                    },
                    externo: ticket.externo
                }
                await localStorage.setItem('@ticket', JSON.stringify(ticketQuestion));
                setView('/formContact');
                break;
            case 'Enviar Saludos':
                const ticketGreetings = {
                    asunto: {
                        titulo: externalService[e.target.value],
                        categoria: '',
                        contenido: ''
                    },
                    usuario: {
                        nombreCompleto: ticket.usuario.nombreCompleto,
                        localidad: '',
                        denominacion: ticket.usuario.denominacion,
                        correoElectronico: '',
                        pregunta: '',
                        respuesta: '',
                        mensaje: ''
                    },
                    externo: ticket.externo
                }
                await localStorage.setItem('@ticket', JSON.stringify(ticketGreetings));
                setView('/formContact');
                break;

            case 'Solicitar un Canto, Himno o Salmo':
                const ticketSoundRequest = {
                    asunto: {
                        titulo: externalService[e.target.value],
                        categoria: '',
                        contenido: ''
                    },
                    usuario: {
                        nombreCompleto: ticket.usuario.nombreCompleto,
                        localidad: '',
                        denominacion: ticket.usuario.denominacion,
                        correoElectronico: '',
                        pregunta: '',
                        respuesta: '',
                        mensaje: ''
                    },
                    externo: ticket.externo
                }
                await localStorage.setItem('@ticket', JSON.stringify(ticketSoundRequest));
                setView('/formContact');
                break;
            case 'Solictar Informes':
                const generalInfoRequest = {
                    asunto: {
                        titulo: externalService[e.target.value],
                        categoria: '',
                        contenido: ''
                    },
                    usuario: {
                        nombreCompleto: ticket.usuario.nombreCompleto,
                        localidad: '',
                        denominacion: ticket.usuario.denominacion,
                        correoElectronico: '',
                        pregunta: '',
                        respuesta: '',
                        mensaje: ''
                    },
                    externo: ticket.externo
                }
                await localStorage.setItem('@ticket', JSON.stringify(generalInfoRequest));
                setView('/formContact');
                break;
            default:
                break;
        }

    }


    const getServices = () => {
        if (userType == 'Interno') {
            return (
                <div id='selectServices' className="input-group" style={{ maxWidth: '350px' }}>
                    <select required onChange={handleRedirect} defaultValue='' className="m-4 custom-select" id="service">
                        <option value="">Ninguna seleccionada</option>
                        {
                            internalService.map((service, key) => <option key={key} value={key}>{service}</option>)
                        }
                    </select>
                </div>
            )
        } else {
            return (
                <div id='selectServices' className="input-group" style={{ maxWidth: '350px' }}>
                    <select onChange={handleRedirectExternal} defaultValue='' className="m-4 custom-select" id="service">
                        <option value="">Ninguna seleccionada</option>
                        {
                            externalServices.map((service, key) => <option key={service} value={key}>{service}</option>)
                        }
                    </select>
                </div>
            )
        }
    }
    const verifyGreeting = () => {


        if (!loading) {

            if (ticket.externo == 'Externo') {
                return (
                    <section className="borderRed">
                        <div className="input m-2 inputData">
                            <h6 className='mb-2 titleApp toCenter' style={{color:'#fff',fontWeight:700}}>¡Bienvenid@ {name}!</h6>
                            <p className='toCenter blank-text'>Es un gusto y grande bendición el poder atenderte</p>
                            <p className='toCenter blank-text'>¿Cómo podemos hacerlo?</p>
                        </div>
                    </section>
                )
            } else {
                return (
                    <section className="borderRed">
                        <div className="input m-2 inputData">
                            <h4 className='blank-text'>¿En qué podemos atenderte?</h4>
                            <p className='blank-text toCenter pt-2'>Selecciona una opción</p>
                        </div>
                    </section>
                )
            }

        } else {
            return (
                <div>
                    Cargando...
                </div>
            )
        }



    }

    const validateSelect = () => {
        console.log('false')
        if(view =='/helpFilter'){
            let alerta = document.createElement("div");
            alerta.className = "AlertError";
            let texto = document.createTextNode("Selecciona una opción");

            alerta.appendChild(texto);

            document.querySelector("#areaAlerta").appendChild(alerta);

            setTimeout(() => {
                alerta.remove();
            }, 2000);
        }
    }


    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                    <img src={LOGOIDI} className="logoIDI" alt="Logo ID(I)" />
                        <div className="justify-content-center">

                            <div className='toCenter'>
                                {
                                    verifyGreeting()
                                }

                            </div>
                        </div>

                        {
                            getServices()
                        }


                        <div id="areaAlerta"></div>
                        <div className='gruopButons'>
                            <Link className='botonLlamar' onClick={validateSelect} to={view}>Continuar</Link>
                        </div>


                    </div>
                </Fragment>
            </Paper>
        </Fade>
    );

}

export default HelpFilter;