/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Fade } from '@material-ui/core';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

import moment from 'moment'

//import sendWhatsApp

import { Modal, Button } from 'react-bootstrap';

/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const WorshipMorning = () => {

    const [worship, setWorship] = useState('');

    //Creando los states

    const [alabanzaApertura, setAlabanzaApertura] = useState('');
    const [alabanza, setAlabanza] = useState('');
    const [alabanzaPredicacion, setAlabanzaPredicacion] = useState('');
    const [alabanzaAlusiva, setAlabanzaAlusiva] = useState('');
    const [bienvenidas, setBienvenidas] = useState('');
    const [alabanzaDespedida, setAlabanzaDespedida] = useState('');


    // configurando handlers

    const handleAlabanzaApertura = (e) => {
        setAlabanzaApertura(e.target.value);
    }

    const handleAlabanza = (e) => {
        setAlabanza(e.target.value);
    }

    const handleAlabanzaPredicacion = (e) => {
        setAlabanzaPredicacion(e.target.value);
    }

    const handleAlabanzaAlusiva = (e) => {
        setAlabanzaAlusiva(e.target.value);
    }

    const handleBienvenidas = (e) => {
        setBienvenidas(e.target.value);
    }

    const handleAlabanzaDespedida = (e) => {
        setAlabanzaDespedida(e.target.value);
    }

    //Obtiene la data de un localstorage

    const getWorshipData = async () => {
        let worshipAux = await localStorage.getItem('@preWorship')
        worshipAux = JSON.parse(worshipAux);
        setWorship(worshipAux)
    }


    const translateDateToSpanish = () => {

        moment.lang('es', {
            months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
            monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
            weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
            weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
            weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
        }
        );

        var localLocale = moment(worship.worshipDate);
        moment.locale('es');
        return localLocale.format('Do MMMM YYYY ');

    }


    const handleFinishWorshipConfiguration = async (e) => {

        e.preventDefault()


        worship["alabanzaApertura"] = alabanzaApertura;
        worship["alabanza"] = alabanza;
        worship["alabanzaPredicacion"] = alabanzaPredicacion;
        worship["alabanzaAlusiva"] = alabanzaAlusiva;
        worship["bienvenidas"] = bienvenidas;
        worship["alabanzaDespedida"] = alabanzaDespedida;

        worship["cantos"] = [alabanzaApertura, alabanza, alabanzaPredicacion, alabanzaAlusiva, alabanzaDespedida]


        db.collection('cultos').doc("tipos").collection("recibimiento").add(worship)


        Swal.fire({
            title: "<i>El culto se ha programado correctamente</i>",
            html: "Serás redirigido a la sección principal",
            confirmButtonColor: "#006279",
            confirmButtonText: "Aceptar",
            icon: "success"
        }).then(() => {
            localStorage.clear()
            window.location.href = "/admin/main";
        })

    }




    useEffect(() => {
        getWorshipData()
        translateDateToSpanish()
    }, [])

    return (

        <Fade in="true">
            <Fragment>
                <div className='contenedor seccionCentrada'>
                    <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                    <div className="justify-content-center">
                        <center>
                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>
                                    Programación de Culto del {translateDateToSpanish()}</span>
                            </button>
                        </center>

                        <div className="shadow-table">

                            <form>


                                <div className="mb-3">
                                    <label>Alabanza de apertura</label>
                                    <input type="text" onChange={handleAlabanzaApertura} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Oración inicial</label>
                                    <input type="text" disabled placeholder={worship.oracionInicial} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Alabanza</label>
                                    <input type="text" onChange={handleAlabanza} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Lectura Bíblica Previa a la Ley</label>
                                    <input type="text" disabled placeholder={worship.lecturaPreviaLey} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Lectura De La Ley</label>
                                    <input type="text" disabled placeholder={worship.lecturaLey} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Alabanza Para la Predicación</label>
                                    <input type="text" onChange={handleAlabanzaPredicacion} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Oración Para la Predicación</label>
                                    <input type="text" disabled placeholder={worship.oracionPredicacion} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Predicación</label>
                                    <input type="text" disabled placeholder={worship.predicacion} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Alabanza Alusiva al Tema (Recolección de Diezmos y Ofrendas)</label>
                                    <input type="text" onChange={handleAlabanzaAlusiva} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Bienvenidas a Visitantes</label>
                                    <input type="text" onChange={handleBienvenidas} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Avisos</label>
                                    <input type="text" disabled placeholder={worship.avisos} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Alabanza de Despedida</label>
                                    <input type="text" onChange={handleAlabanzaDespedida} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Oración Final</label>
                                    <input type="text" disabled placeholder={worship.oracionFinal} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label>Bendición de Dios</label>
                                    <input type="text" disabled placeholder={worship.bendicionDeDios} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <center>
                                    <button className="btn botonChidoBlue" onClick={handleFinishWorshipConfiguration} style={{ paddingTop: "10px" }}>Finalizar programación</button>
                                </center>
                            </form>

                        </div>
                    </div>
                </div>
            </Fragment>
        </Fade>

    )
}

export default WorshipMorning;