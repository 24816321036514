import React, { Component, Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap';



const Post = (data) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [dateToday, setDateToday] = useState('')

    //calculate range betweendates
    function daysBetween(one, another) {
        return (Math.round(Math.abs((+one) - (+another)) / 8.64e7));
    }

    const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;

        setDateToday(today)
        return today


    }





    useEffect(() => {
        getDate()
    }, [])


    return (
        <Fragment>

            <div

                style={{
                    justifyContent: 'center',
                    backgroundImage: `linear-gradient(
                    325deg,
                    rgba(0, 36, 51, 1) 0%,
                    rgba(98, 218, 255, 0.164) 100%
                    ),url(${(data.url)})`
                }} className="cardService">
                <div>


                    <span style={{ color: '#fff' }}>Faltan {daysBetween(new Date(dateToday), new Date(data.eventDate))} días</span>
                    <h1 className='serviceTitle'>{data.name}</h1>
                    <h6 className='serviceDescription'>{data.description}</h6>
                </div>
                <button onClick={handleShow} className='btn btn-primary botonChido'>Saber más</button>
            </div>


            <article>
                <Modal
                    show={show}
                    onHide={() => handleClose(false)}
                    size="lg"
                    centered
                    className='modal'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {data.name}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Descripción del evento</h4>
                        <p>
                            {data.description}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            </article>




        </Fragment>
    )
}

export default Post;