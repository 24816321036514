import React, { Fragment, useState } from 'react';

/**Transitions  */

import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';

import Swal from 'sweetalert2';

function ExternFilter() {



    const [fullName, setFullName] = useState('');
    const [denomination, setDenomination] = useState('');
    const [view, setView] = useState('/externalFilter');

    const handleNameInputChange = e => {
        const { value } = e.target;
        var letters = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;
        if (e.target.value.match(letters) && e.target.value != "") {
            document.querySelector("#nameInput").className = 'form-control correctNumber';
        } else {
            document.querySelector("#nameInput").className = 'form-control invalidNumber';
        }
        setFullName(value);
    }

    const handleDenominationInputChange = e => {
        const { value } = e.target;
        var letters = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;
        if (e.target.value.match(letters) && e.target.value != "") {
            document.querySelector("#denominationInput").className = 'form-control correctNumber';
        } else {
            document.querySelector("#denominationInput").className = 'form-control invalidNumber';
        }
        setDenomination(value);
    }


    const _validateInputs = async () => {

        const letters = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;





        if (fullName !== "" && denomination !== "") {

            if (fullName.match(letters) && fullName !== "") {
                window.location.href = '/helpFilter'
                saveData()
            } else {

                await Swal.fire({
                    position: 'center',
                    icon: 'info',
                    title: 'Datos incompletos',
                    text: 'Por favor ingresa la información faltante',
                    showConfirmButton: false,
                    timer: 3000
                })
                window.location.href = '/externalFilter'
            }
        } else {
            await Swal.fire({
                position: 'center',
                icon: 'info',
                title: 'Datos incompletos',
                text: 'Por favor ingresa la información faltante',
                showConfirmButton: false,
                timer: 3000
            })
            window.location.href = '/externalFilter'
        }


    }

    const saveData = async () => {

        let ticket = await localStorage.getItem('@ticket');
        ticket = JSON.parse(ticket);

        const ticketObj = {
            asunto: {
                titulo: '',
                categoria: '',
                contenido: ''
            },
            usuario: {
                nombreCompleto: fullName,
                localidad: '',
                denominacion: denomination,
                correoElectronico: '',
                pregunta: '',
                respuesta: '',
                mensaje: ''
            },
            externo: "Externo"
        }
        await localStorage.setItem('@ticket', JSON.stringify(ticketObj));
    }




    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                        <div className="justify-content-center">

                            <div className='toCenter'>
                                <h1 style={{color:'#fff',fontWeight:700}}>¡Comencemos!</h1>
                                <h6 className='m-4 blank-text'>Por favor registra la siguiente información</h6>


                                <div className='m-4'></div>

                                <section>
                                    <div className="input m-4 inputData">
                                        <h6 className='mb-2 blank-text'>¿Cuál es tu nombre?</h6>
                                        <input type="text" id='nameInput' onChange={handleNameInputChange} className="form-control " placeholder="Ejemplo: José Enríquez Beltrán" aria-label="Username" aria-describedby="basic-addon1"></input>
                                    </div>
                                </section>

                                <section>
                                    <div className="input m-4 inputData">
                                        <h6 className='mb-2 blank-text'>Denominación Religiosa a la que perteneces</h6>
                                        <input type="text" id='denominationInput' onChange={handleDenominationInputChange} className="form-control " placeholder="Escriba aqui" aria-label="denominacion"></input>
                                    </div>
                                </section>
                            </div>
                        </div>


                        <div className='gruopButons'>
                            <button className='btn botonLlamar' onClick={_validateInputs} >Continuar</button>
                        </div>

                    </div>








                </Fragment>
            </Paper>
        </Fade>
    );

}

export default ExternFilter;