import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const app = firebase.initializeApp({
    apiKey: "AIzaSyDdcqg8ldAdVoMoBEHFQemcYLPmUfE72wc",
    authDomain: "chat-tv-churubusco.firebaseapp.com",
    databaseURL: "https://chat-tv-churubusco.firebaseio.com",
    projectId: "chat-tv-churubusco",
    storageBucket: "chat-tv-churubusco.appspot.com",
    messagingSenderId: "972280545185",
    appId: "1:972280545185:web:21ebaacf2a58d73a4a95e0",
    measurementId: "G-WT15KP9YNT"
})

export default app;

