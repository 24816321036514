/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Fade } from '@material-ui/core';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

//import sendWhatsApp

import { Modal, Button } from 'react-bootstrap';

/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'
import CardService from './CardService';
import ButtonCardService from './ButtonCardService';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const AsignacionCultoCabanasRecibimiento = () => {


    const [worship, setWorship] = useState('');
    const [currentId, setCurrentId] = useState('');






    //Obtiene la data de un localstorage
    /*
     const getUserData = async () => {
         let ticket = await localStorage.getItem('@ticket')
         ticket = JSON.parse(ticket);
         setTicket(ticket)
     }
    */

     const getWorshipData = async () => {
        let worship = await localStorage.getItem('@preWorship')
        worship = JSON.parse(worship);

        let currentId = await localStorage.getItem('@currentId')
        currentId = JSON.parse(currentId);
        setCurrentId(currentId)


        setWorship(worship)
    }


    // Agrega un item a un objeto -- objAux['url'] = downloadURL

    const handleWorshipConfiguration = async (e) => {

        e.preventDefault()

        worship["superIntendente"] = superIntendente;
        worship["SuperintendenteRespaldo"] = SuperintendenteRespaldo;
        worship["SuperintendenteRespaldo"] = SuperintendenteRespaldo;
        worship["oracionInicial"] = oracionInicial;
        worship["lecturaPreviaLey"] = lecturaPreviaLey;
        worship["lecturaLey"] = lecturaLey;

        db.collection('cultos').add(worship)


        Swal.fire(
            'Culto creado',
            'El culto está disponible en: Mostrar próximos',
            'success'
        ).then(() => {
            localStorage.clear()
            window.location.href = "/admin/main";
        })

    }


    //Creando los states


    const [superIntendente, setSuperIntendente] = useState('');
    const [SuperintendenteRespaldo, setSuperIntendenteRespaldo] = useState('');
    const [oracionInicial, setOracionInicial] = useState('');
    const [lecturaPreviaLey, setLecturaPreviaLey] = useState('');
    const [lecturaLey, setLecturaLey] = useState('');


    //Creando los handlers

    const handleSuperIntendente = (e) => {
        setSuperIntendente(e.target.value)
    }

    const handleSuperIntendenteRespaldo = (e) => {
        setSuperIntendenteRespaldo(e.target.value)
    }

    const handleOracionInicial = (e) => {
        setOracionInicial(e.target.value)
    }

    const handleLecturaPreviaLey = (e) => {
        setLecturaPreviaLey(e.target.value)
    }

    const handleLecturaLey = (e) => {
        setLecturaLey(e.target.value)
    }




    useEffect(() => {
        getWorshipData()
    }, [])



    return (

        <Fade in="true">
            <Fragment>
                <div className='contenedor seccionCentrada'>
                    <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                    <div className="justify-content-center">
                        <center>
                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>Asignación de Culto Cabañas</span>
                            </button>
                        </center>

                        <div className="shadow-table">
                            <form>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Superintendente (Principal)</label>
                                    <input type="text" onChange={handleSuperIntendente} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Superintendente (Respaldo)</label>
                                    <input type="text" required onChange={handleSuperIntendenteRespaldo} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Oración Inicial</label>
                                    <input type="text" required onChange={handleOracionInicial} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Lectura Bíblica Previa a la Ley</label>
                                    <input type="text" required onChange={handleLecturaPreviaLey} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <span id="inputGroup-sizing-default">Lectura De La Ley</span>
                                    <input type="text" required onChange={handleLecturaLey} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Palabras Alusivas</label>
                                    <input type="text" required onChange={e => worship["palabrasAlusivas"] = e.target.value} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Oración por los holocaustos</label>
                                    <input type="text" required onChange={e => worship["oracionHolocaustos"] = e.target.value} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Oración para la Predicación</label>
                                    <input type="text" required onChange={e => worship["oracionPredicacion"] = e.target.value} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Predicación</label>
                                    <input type="text" required onChange={e => worship["predicacion"] = e.target.value} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>


                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Avisos</label>
                                    <input type="text" required onChange={e => worship["avisos"] = e.target.value} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                
                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Oración final</label>
                                    <input type="text" required onChange={e => worship["oracionFinal"] = e.target.value} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Bendición de Dios</label>
                                    <input type="text" required onChange={e => worship["bendicionDeDios"] = e.target.value} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>


                                <center>
                                    <button className="btn botonChidoBlue" onClick={handleWorshipConfiguration} >Crear culto</button>
                                </center>
                            </form>


                        </div>
                    </div>
                </div>
            </Fragment>
        </Fade>

    )
}

export default AsignacionCultoCabanasRecibimiento;