/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

//import emailjs

import emailjs from 'emailjs-com';



/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'
import Uploader from './Uploader';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const HolocaustForm = () => {


    const [usuarios, setUsuarios] = useState([])


    const songs = [
        {
            "label": "Himno No. 1",
            "value": "Cada Momento La Vida Me Da"
        },
        {
            "label": "Himno No. 2",
            "value": "Unión Fraternal"
        },
        {
            "label": "Himno No. 3",
            "value": "Nunca Desmayes"
        },
        {
            "label": "Himno No. 4",
            "value": "No Hay Tristeza En El Reino"
        },
        {
            "label": "Himno No. 5",
            "value": "Hay Un Jardín Memorable"
        },
        {
            "label": "Himno No. 6",
            "value": "Hay Un País"
        },
        {
            "label": "Himno No. 7",
            "value": "Nunca Dios Mío, Cesará Mi Labio"
        },
        {
            "label": "Himno No. 8",
            "value": "El Salvavidas"
        },
        {
            "label": "Himno No. 9",
            "value": "Obediente a Tu Mandato"
        },
        {
            "label": "Himno No. 10",
            "value": "Cristo, Rey De Gloria"
        },
        {
            "label": "Himno No. 11",
            "value": "A Jesucristo Ven Sin Tardar"
        },
        {
            "label": "Himno No. 12",
            "value": "¡Oh, Si Es Un Reino Aquí!"
        },
        {
            "label": "Himno No. 13",
            "value": "Del Santo Amor de Cristo"
        },
        {
            "label": "Himno No. 14",
            "value": "No Se Turbe Vuestro Corazón"
        },
        {
            "label": "Himno No. 15",
            "value": "La Gracia de Dios"
        },
        {
            "label": "Himno No. 16",
            "value": "¡Salvo Ya Soy!"
        },
        {
            "label": "Himno No. 17",
            "value": "El Nombre de Jesús"
        },
        {
            "label": "Himno No. 18",
            "value": "Cuando Andemos Con Dios"
        },
        {
            "label": "Himno No. 19",
            "value": "Sublime Don"
        },
        {
            "label": "Himno No. 20",
            "value": "Padre A Tus Pies Me Postro"
        },
        {
            "label": "Himno No. 21",
            "value": "Incansables Compañeros"
        },
        {
            "label": "Himno No. 22",
            "value": "Por La Mañana"
        },
        {
            "label": "Himno No. 23",
            "value": "Dulce Oración"
        },
        {
            "label": "Himno No. 24",
            "value": "En Duda Tenaz"
        },
        {
            "label": "Himno No. 25",
            "value": "Hay Una Iglesia Preciosa"
        },
        {
            "label": "Himno No. 26",
            "value": "Hermosa Tierra Sión"
        },
        {
            "label": "Himno No. 27",
            "value": "El Pimpollo"
        },
        {
            "label": "Himno No. 28",
            "value": "El Esposo Viene"
        },
        {
            "label": "Himno No. 29",
            "value": "Pronto La Noche Viene"
        },
        {
            "label": "Himno No. 30",
            "value": "Juventud Despertad"
        },
        {
            "label": "Himno No. 31",
            "value": "Cristo Es El Camino"
        },
        {
            "label": "Himno No. 32",
            "value": "Quiero Seguir a Mi Jesús"
        },
        {
            "label": "Himno No. 33",
            "value": "De La Muerte Al Imperio"
        },
        {
            "label": "Himno No. 34",
            "value": "Cantaré a Jesús de Mi Alma Rey"
        },
        {
            "label": "Himno No. 35",
            "value": "Un Bello Hogar"
        },
        {
            "label": "Himno No. 36",
            "value": "Alabadle"
        },
        {
            "label": "Himno No. 37",
            "value": "¡Oh, Abre Tu Mi Corazón!"
        },
        {
            "label": "Himno No. 38",
            "value": "La Luz De Dios"
        },
        {
            "label": "Himno No. 39",
            "value": "Israel Restaurado"
        },
        {
            "label": "Himno No. 40",
            "value": "Ven ¡Oh! Pródigo Ven"
        },
        {
            "label": "Himno No. 41",
            "value": "¡Dios Te Ve!"
        },
        {
            "label": "Himno No. 42",
            "value": "La Espada Espiritual"
        },
        {
            "label": "Himno No. 43",
            "value": "¡Sea La Paz, Sea La Paz!"
        },
        {
            "label": "Himno No. 44",
            "value": "Cuando lo Anhelo Más"
        },
        {
            "label": "Himno No. 45",
            "value": "Salid De Egipto Ya"
        },
        {
            "label": "Himno No. 46",
            "value": "Somos Un Cuerpo En Cristo"
        },
        {
            "label": "Himno No. 47",
            "value": "Himno Del Sábado"
        },
        {
            "label": "Himno No. 48",
            "value": "Loor a Ti, Señor"
        },
        {
            "label": "Himno No. 49",
            "value": "Ábrele"
        },
        {
            "label": "Himno No. 50",
            "value": "Oh Cristo Mío"
        },
        {
            "label": "Himno No. 51",
            "value": "¡Oh Jóvenes Venid!"
        },
        {
            "label": "Himno No. 52",
            "value": "Sal a Sembrar"
        },
        {
            "label": "Himno No. 53",
            "value": "La Voz de Jesús"
        },
        {
            "label": "Himno No. 54",
            "value": "Rostro Divino"
        },
        {
            "label": "Himno No. 55",
            "value": "Allá La Puerta Franca Está"
        },
        {
            "label": "Himno No. 56",
            "value": "¡Oh Cuán Dulce es Fiar en Cristo!"
        },
        {
            "label": "Himno No. 57",
            "value": "Sigue Orando"
        },
        {
            "label": "Himno No. 58",
            "value": "Piensa Bien lo Que Hoy Vas a Hacer"
        },
        {
            "label": "Himno No. 59",
            "value": "Toma Mi Vida"
        },
        {
            "label": "Himno No. 60",
            "value": "El Llamamiento de Cristo"
        },
        {
            "label": "Himno No. 61",
            "value": "Yo Quiero Seguir a Cristo"
        },
        {
            "label": "Himno No. 62",
            "value": "Escuela Sabática"
        },
        {
            "label": "Himno No. 63",
            "value": "Brille o No el Sol"
        },
        {
            "label": "Himno No. 64",
            "value": "¡Salid Valientes Batallones!"
        },
        {
            "label": "Himno No. 65",
            "value": "En Mi Alma Hay Dulce Paz"
        },
        {
            "label": "Himno No. 66",
            "value": "Del Culto Al Tiempo Llega"
        },
        {
            "label": "Himno No. 67",
            "value": "Señor, Reposamos"
        },
        {
            "label": "Himno No. 68",
            "value": "Roca de la Eternidad"
        },
        {
            "label": "Himno No. 69",
            "value": "Ya se Acerca el Fin"
        },
        {
            "label": "Himno No. 70",
            "value": "La Siembra"
        },
        {
            "label": "Himno No. 71",
            "value": "¡Oh, Qué Amigo!"
        },
        {
            "label": "Himno No. 72",
            "value": "Firmes y Adelante"
        },
        {
            "label": "Himno No. 73",
            "value": "Cerca de Ti Señor"
        },
        {
            "label": "Himno No. 74",
            "value": "Dulce Comunión"
        },
        {
            "label": "Himno No. 75",
            "value": "¡Paz! ¡Paz! ¡Cuán Dulce Paz!"
        },
        {
            "label": "Himno No. 76",
            "value": "Cristo, Es Mi Dulce Salvador"
        },
        {
            "label": "Himno No. 77",
            "value": "Grande Gozo Hay En Mi Alma"
        },
        {
            "label": "Himno No. 78",
            "value": "Tendrás Que Renacer"
        },
        {
            "label": "Himno No. 79",
            "value": "Entera Consaggración"
        },
        {
            "label": "Himno No. 80",
            "value": "Santa Biblia"
        },
        {
            "label": "Himno No. 81",
            "value": "Hay Un Precioso Manantial"
        },
        {
            "label": "Himno No. 82",
            "value": "A la Siega Acudid"
        },
        {
            "label": "Himno No. 83",
            "value": "Ya Todo Dejé"
        },
        {
            "label": "Himno No. 84",
            "value": "¡Oh, Jesús, Pastor Divino!"
        },
        {
            "label": "Himno No. 85",
            "value": "¡Oh, Yo Quiero Andar Con Cristo!"
        },
        {
            "label": "Himno No. 86",
            "value": "Grandes Cosas Cristo Ha Hecho Para Mi"
        },
        {
            "label": "Himno No. 87",
            "value": "Dilo a Cristo"
        },
        {
            "label": "Himno No. 88",
            "value": "Dios os Guarde"
        },
        {
            "label": "Himno No. 89",
            "value": "El Consolador Ha Venido"
        },
        {
            "label": "Himno No. 90",
            "value": "Puedo Oír tu Voz Llamando"
        },
        {
            "label": "Himno No. 91",
            "value": "Sólo a Ti Dios y Señor"
        },
        {
            "label": "Himno No. 92",
            "value": "Cerca, Más Cerca"
        },
        {
            "label": "Himno No. 93",
            "value": "¡Santo! ¡Santo! ¡Santo!"
        },
        {
            "label": "Himno No. 94",
            "value": "Cuando Él Vino a Mi Corazón"
        },
        {
            "label": "Himno No. 95",
            "value": "¡Guíame, Oh Salvador!"
        },
        {
            "label": "Himno No. 96",
            "value": "Hubo Quien Por Mis Culpas Muriera"
        },
        {
            "label": "Himno No. 97",
            "value": "Un Día Cristo Volverá"
        },
        {
            "label": "Himno No. 98",
            "value": "No Puede El Mundo Ser Mi Hogar"
        },
        {
            "label": "Himno No. 99",
            "value": "Santo Espíritu, Dirige"
        },
        {
            "label": "Himno No. 100",
            "value": "A Cristo Doy Mi Canto"
        },
        {
            "label": "Himno No. 101",
            "value": "Cristo Está Buscando Obreros"
        },
        {
            "label": "Himno No. 102",
            "value": "Todo a Cristo Yo Me Rindo"
        },
        {
            "label": "Himno No. 103",
            "value": "¡Viene Otra Vez!"
        },
        {
            "label": "Himno No. 104",
            "value": "Las Buenas Nuevas"
        },
        {
            "label": "Himno No. 105",
            "value": "En las Aguas de la Muerte"
        },
        {
            "label": "Himno No. 106",
            "value": "Feliz Cantando Alegre"
        },
        {
            "label": "Himno No. 107",
            "value": "Ama El Pastor Sus Ovejas"
        },
        {
            "label": "Himno No. 108",
            "value": "Pecador, Ven al Dulce Jesús"
        },
        {
            "label": "Himno No. 109",
            "value": "Yo Quiero Trabajar Por El Señor"
        },
        {
            "label": "Himno No. 110",
            "value": "La Palabra Hoy Sembrada"
        },
        {
            "label": "Himno No. 111",
            "value": "¡Oh, Sí Quiero Verle!"
        },
        {
            "label": "Himno No. 112",
            "value": "A Solas al Huerto"
        },
        {
            "label": "Himno No. 113",
            "value": "Santo Espíritu Desciende"
        },
        {
            "label": "Himno No. 114",
            "value": "¡Oh, Padre Eterno!"
        },
        {
            "label": "Himno No. 115",
            "value": "No Te Dé Temor"
        },
        {
            "label": "Himno No. 116",
            "value": "¡Trabajad, Trabajad!"
        },
        {
            "label": "Himno No. 117",
            "value": "Jesús Te Necesita"
        },
        {
            "label": "Himno No. 118",
            "value": "Lejos de Mi Padre Dios"
        },
        {
            "label": "Himno No. 119",
            "value": "¡Oh, Bondad Tan Infinita!"
        },
        {
            "label": "Himno No. 120",
            "value": "Yo Guiaré"
        },
        {
            "label": "Himno No. 121",
            "value": "Bellas Palabras de Vida"
        },
        {
            "label": "Himno No. 122",
            "value": "A Nuestro Padre Dios"
        },
        {
            "label": "Himno No. 123",
            "value": "Nítido Rayo Por Cristo"
        },
        {
            "label": "Himno No. 124",
            "value": "Cristo Me Ama"
        },
        {
            "label": "Himno No. 125",
            "value": "Al Amparo de la Roca"
        },
        {
            "label": "Himno No. 126",
            "value": "Dios Bendiga las Almas Unidas"
        },
        {
            "label": "Himno No. 127",
            "value": "Hoy el Sábado Glorioso"
        },
        {
            "label": "Himno No. 128",
            "value": "Cristianos Israelitas"
        },
        {
            "label": "Himno No. 129",
            "value": "Alguna Vez Ya No Estaré"
        },
        {
            "label": "Himno No. 130",
            "value": "Hoy es Día de Reposo"
        },
        {
            "label": "Himno No. 131",
            "value": "Feliz Momento"
        },
        {
            "label": "Himno No. 132",
            "value": "Divino Amor"
        },
        {
            "label": "Himno No. 133",
            "value": "Mi Rey y Mi Amigo"
        },
        {
            "label": "Himno No. 134",
            "value": "Cara a Cara Espero Verle"
        },
        {
            "label": "Himno No. 135",
            "value": "¡Piedad, Oh Santo Dios!"
        },
        {
            "label": "Himno No. 136",
            "value": "Loor a Ti Mi Dios en Esta Noche"
        },
        {
            "label": "Himno No. 137",
            "value": "Con Gran Fe"
        },
        {
            "label": "Himno No. 138",
            "value": "Traed los Diezmos al Granero"
        },
        {
            "label": "Himno No. 139",
            "value": "Cuando la Trompeta Suene"
        },
        {
            "label": "Himno No. 140",
            "value": "¡Oh, Gracia Excelsa!"
        },
        {
            "label": "Himno No. 141",
            "value": "Da lo Mejor al Maestro"
        },
        {
            "label": "Himno No. 142",
            "value": "La Mañana Vino Ya"
        },
        {
            "label": "Himno No. 143",
            "value": "¡Ven a Él, Ven a Él"
        },
        {
            "label": "Himno No. 144",
            "value": "El Sembrador"
        },
        {
            "label": "Himno No. 145",
            "value": "Dulce Consuelo"
        },
        {
            "label": "Himno No. 146",
            "value": "El Huerto de Oración"
        },
        {
            "label": "Himno No. 147",
            "value": "Es El Tiempo de la Siega"
        },
        {
            "label": "Himno No. 148",
            "value": "El Verbo"
        },
        {
            "label": "Himno No. 149",
            "value": "En los Días de Prueba"
        },
        {
            "label": "Himno No. 150",
            "value": "Fuente de la Vida Eterna"
        },
        {
            "label": "Himno No. 151",
            "value": "Adoradle"
        },
        {
            "label": "Himno No. 152",
            "value": "Siempre Orar"
        },
        {
            "label": "Himno No. 153",
            "value": "Hoy Venimos, Cual Hermanos"
        },
        {
            "label": "Himno No. 154",
            "value": "Despide Hoy Tu Grey"
        },
        {
            "label": "Himno No. 155",
            "value": "¡Hosanna!"
        },
        {
            "label": "Himno No. 156",
            "value": "El Diezmo"
        },
        {
            "label": "Himno No. 157",
            "value": "Jubilosas Nuestras Voces"
        },
        {
            "label": "Himno No. 158",
            "value": "Más Santidad Dame"
        },
        {
            "label": "Himno No. 159",
            "value": "Joyas, Joyas, Joyas"
        },
        {
            "label": "Himno No. 160",
            "value": "Tú Déjate Tu Trono y Corona"
        },
        {
            "label": "Himno No. 161",
            "value": "Cabañas, Cabañas"
        },
        {
            "label": "Himno No. 162",
            "value": "A La Luz"
        },
        {
            "label": "Himno No. 163",
            "value": "El Rey Que Viene"
        },
        {
            "label": "Himno No. 164",
            "value": "Bajo Sus Alas"
        },
        {
            "label": "Himno No. 165",
            "value": "Manos Limpias"
        },
        {
            "label": "Himno No. 166",
            "value": "Me Guía Él"
        },
        {
            "label": "Himno No. 167",
            "value": "Adelante Adelante"
        },
        {
            "label": "Himno No. 168",
            "value": "Oración Al Padre"
        },
        {
            "label": "Himno No. 169",
            "value": "La Segunda Venida de Cristo"
        },
        {
            "label": "Himno No. 170",
            "value": "Los Diez Mandamientos"
        },
        {
            "label": "Himno No. 171",
            "value": "Junto a Mi Dios"
        },
        {
            "label": "Himno No. 172",
            "value": "Ya El Fin Se Acerca"
        },
        {
            "label": "Himno No. 173",
            "value": "Te Loamos ¡Oh Dios!"
        },
        {
            "label": "Himno No. 174",
            "value": "Me Levantó"
        },
        {
            "label": "Himno No. 175",
            "value": "Bástame Su Gracia"
        },
        {
            "label": "Himno No. 176",
            "value": "Bendice a Tu Creador"
        },
        {
            "label": "Himno No. 177",
            "value": "Fuiste Clavado En La Cruz"
        },
        {
            "label": "Himno No. 178",
            "value": "Soldados Del Señor"
        },
        {
            "label": "Himno No. 179",
            "value": "Yo Daré Mis Diezmos"
        },
        {
            "label": "Himno No. 180",
            "value": "Un Ancla Tenemos"
        },
        {
            "label": "Himno No. 181",
            "value": "Tal Amor Ensalzaré"
        },
        {
            "label": "Himno No. 182",
            "value": "¿Puede Cristo Confiar En Mí?"
        },
        {
            "label": "Himno No. 183",
            "value": "Vale La Pena Servir a Jesús"
        },
        {
            "label": "Himno No. 184",
            "value": "Coronadle"
        },
        {
            "label": "Himno No. 185",
            "value": "Da Al Señor Tus Diezmos"
        },
        {
            "label": "Himno No. 186",
            "value": "El Reposo de Israel"
        },
        {
            "label": "Himno No. 187",
            "value": "Dulce Tu Ley"
        },
        {
            "label": "Himno No. 188",
            "value": "Mejor Que El Oro"
        },
        {
            "label": "Himno No. 189",
            "value": "Despliegue El Cristiano"
        },
        {
            "label": "Himno No. 190",
            "value": "Más Cual Mi Cristo"
        },
        {
            "label": "Himno No. 191",
            "value": "Habla Señor Mí Alma"
        },
        {
            "label": "Himno No. 192",
            "value": "Dejo El Mundo"
        },
        {
            "label": "Himno No. 193",
            "value": "Tuyo Soy, Jesús"
        },
        {
            "label": "Himno No. 194",
            "value": "En El Monte Calvario"
        },
        {
            "label": "Himno No. 195",
            "value": "Fiel Huésped"
        },
        {
            "label": "Himno No. 196",
            "value": "Ora Hasta Triunfar"
        },
        {
            "label": "Himno No. 197",
            "value": "Unidos En Espíritu"
        },
        {
            "label": "Himno No. 198",
            "value": "Cariñoso Salvador"
        },
        {
            "label": "Himno No. 199",
            "value": "Dios Es Amor"
        },
        {
            "label": "Himno No. 200",
            "value": "Jehová Es Mi Pastor"
        },
        {
            "label": "Himno No. 201",
            "value": "Últimos Tiempos"
        },
        {
            "label": "Himno No. 202",
            "value": "Amigo Tengo"
        },
        {
            "label": "Himno No. 203",
            "value": "Shema"
        },
        {
            "label": "Himno No. 204",
            "value": "Himno Nacional de Israel (Hebreo)"
        },
        {
            "label": "Himno No. 205",
            "value": "Hermosas Cabañas"
        },
        {
            "label": "Himno No. 206",
            "value": "Me Guiará Mi Salvador"
        },
        {
            "label": "Himno No. 207",
            "value": "Día De Gran Livertad"
        },
        {
            "label": "Himno No. 208",
            "value": "Mi Padre Es Un Rey"
        },
        {
            "label": "Himno No. 209",
            "value": "Te Loamos"
        },
        {
            "label": "Himno No. 210",
            "value": "¡Viene Otra Vez Nuestro Salvador!"
        },
        {
            "label": "Himno No. 211",
            "value": "Hay Quién Vele Mis Pisadas"
        },
        {
            "label": "Himno No. 212",
            "value": "Dulces Momentos"
        },
        {
            "label": "Himno No. 213",
            "value": "Roca De Los Siglos"
        },
        {
            "label": "Himno No. 214",
            "value": "Hazme Una Fuente"
        },
        {
            "label": "Himno No. 215",
            "value": "Pentecostés De Gloria"
        },
        {
            "label": "Himno No. 216",
            "value": "Quiero Seguir Las Pisadas de Cristo"
        },
        {
            "label": "Himno No. 217",
            "value": "Grato Es Contar La História"
        },
        {
            "label": "Himno No. 218",
            "value": "No Hay Cual Jesús"
        },
        {
            "label": "Himno No. 219",
            "value": "Pronto Veremos a Cristo Venir"
        },
        {
            "label": "Himno No. 220",
            "value": "Tu Corazón Pide"
        },
        {
            "label": "Himno No. 221",
            "value": "Ignoro Yo Por Qué Será"
        },
        {
            "label": "Himno No. 222",
            "value": "Habladme Más De Cristo"
        },
        {
            "label": "Himno No. 223",
            "value": "Me Redimió, Me Redimió"
        },
        {
            "label": "Himno No. 224",
            "value": "Marchen, Marchen"
        },
        {
            "label": "Himno No. 225",
            "value": "Cómo Habré de Presentarme"
        },
        {
            "label": "Himno No. 226",
            "value": "¿Cómo Hemos de Estar En Juicio?"
        },
        {
            "label": "Himno No. 227",
            "value": "A Dios Cantemos"
        },
        {
            "label": "Himno No. 228",
            "value": "Tengo Un Amigo"
        },
        {
            "label": "Himno No. 229",
            "value": "Haz Lo Que Quieras De Mí"
        },
        {
            "label": "Himno No. 230",
            "value": "Obedezco Por Fe"
        },
        {
            "label": "Himno No. 231",
            "value": "Pon Tu Carga En Jesús"
        },
        {
            "label": "Himno No. 232",
            "value": "Ejemplos de Escuela Sabática"
        },
        {
            "label": "Himno No. 233",
            "value": "Hora de la Escuela Sabática"
        },
        {
            "label": "Himno No. 234",
            "value": "Dad a Dios Inmortal Alabanza"
        },
        {
            "label": "Himno No. 235",
            "value": "La Tierna Voz"
        },
        {
            "label": "Himno No. 236",
            "value": "Mi Todo a Dios Consagro"
        },
        {
            "label": "Himno No. 237",
            "value": "En tu Día de Reposo"
        },
        {
            "label": "Himno No. 238",
            "value": "El Día Santo Del Señor"
        },
        {
            "label": "Himno No. 239",
            "value": "Dad Loores al Cordero"
        },
        {
            "label": "Himno No. 240",
            "value": "Hay Un Lugar Do Quiero Estar"
        },
        {
            "label": "Himno No. 241",
            "value": "Valor y Fe"
        },
        {
            "label": "Himno No. 242",
            "value": "Yo Soy Peregrino (Estribillo)"
        },
        {
            "label": "Himno No. 243",
            "value": "Junto a Ti"
        },
        {
            "label": "Himno No. 244",
            "value": "Otro Amigo Como Cristo No Lo Hay"
        },
        {
            "label": "Himno No. 245",
            "value": "Por Gracia Me Levantó"
        },
        {
            "label": "Himno No. 246",
            "value": "Dime La História De Cristo"
        },
        {
            "label": "Himno No. 247",
            "value": "¡Voy a la Tierra de Canaán!"
        },
        {
            "label": "Himno No. 248",
            "value": "Oh, Santa Quietud"
        },
        {
            "label": "Himno No. 249",
            "value": "Deja Que Él More En Ti"
        },
        {
            "label": "Himno No. 250",
            "value": "No Es Tiempo Aún"
        },
        {
            "label": "Himno No. 251",
            "value": "Vanidad, Todo Vanidad"
        },
        {
            "label": "Himno No. 252",
            "value": "Viviendo Por Fe"
        },
        {
            "label": "Himno No. 253",
            "value": "Yo Sé Que Jesucristo Vive"
        },
        {
            "label": "Himno No. 254",
            "value": "El Piloto de Galilea"
        },
        {
            "label": "Himno No. 255",
            "value": "El Camino de Fe"
        },
        {
            "label": "Himno No. 256",
            "value": "Todo En Todo es Jesucristo"
        },
        {
            "label": "Himno No. 257",
            "value": "A Sión"
        },
        {
            "label": "Himno No. 258",
            "value": "Tengo Un Hogar Más Allá Del Mar"
        },
        {
            "label": "Himno No. 259",
            "value": "Mi Dios: La Roca Fuerte"
        },
        {
            "label": "Himno No. 260",
            "value": "Nos Vemos En el Reino (Estribillo)"
        },
        {
            "label": "Himno No. 261",
            "value": "El Sábado"
        },
        {
            "label": "Himno No. 262",
            "value": "El Rey de Israel"
        },
        {
            "label": "Himno No. 263",
            "value": "Él Es"
        },
        {
            "label": "Himno No. 264",
            "value": "Jerusalem La Hermosa"
        },
        {
            "label": "Himno No. 265",
            "value": "Estaremos Juntos (Estribillo)"
        },
        {
            "label": "Himno No. 266",
            "value": "Adelante La Iglesia de Dios Israelita"
        },
        {
            "label": "Himno No. 267",
            "value": "Éxodo"
        },
        {
            "label": "Himno No. 268",
            "value": "Tierra Bendita"
        },
        {
            "label": "Himno No. 269",
            "value": "Somos La Iglesia de Dios"
        },
        {
            "label": "Himno No. 270",
            "value": "Una Vez Más"
        },
        {
            "label": "Himno No. 271",
            "value": "Somos de Israel"
        },
        {
            "label": "Himno No. 272",
            "value": "Obreros de la Mies de Cristo"
        },
        {
            "label": "Himno No. 273",
            "value": "Jerusalem De Oro"
        },
        {
            "label": "Himno No. 274",
            "value": "Qué Tal Hermano"
        },
        {
            "label": "Himno No. 275",
            "value": "Oye Israel"
        },
        {
            "label": "Himno No. 276",
            "value": "Salmo 119:129-136"
        },
        {
            "label": "Himno No. 277",
            "value": "Misionero"
        },
        {
            "label": "Himno No. 278",
            "value": "Salmo 91"
        }
    ]

    const psalms = [
        {
            "label": "Salmo No. 1",
            "value": "Salmo No. 1"
        },
        {
            "label": "Salmo No. 2",
            "value": "Salmo No. 2"
        },
        {
            "label": "Salmo No. 3",
            "value": "Salmo No. 3"
        },
        {
            "label": "Salmo No. 4",
            "value": "Salmo No. 4"
        },
        {
            "label": "Salmo No. 5",
            "value": "Salmo No. 5"
        },
        {
            "label": "Salmo No. 6",
            "value": "Salmo No. 6"
        },
        {
            "label": "Salmo No. 7",
            "value": "Salmo No. 7"
        },
        {
            "label": "Salmo No. 8",
            "value": "Salmo No. 8"
        },
        {
            "label": "Salmo No. 9",
            "value": "Salmo No. 9"
        },
        {
            "label": "Salmo No. 10",
            "value": "Salmo No. 10"
        },
        {
            "label": "Salmo No. 11",
            "value": "Salmo No. 11"
        },
        {
            "label": "Salmo No. 12",
            "value": "Salmo No. 12"
        },
        {
            "label": "Salmo No. 13",
            "value": "Salmo No. 13"
        },
        {
            "label": "Salmo No. 14",
            "value": "Salmo No. 14"
        },
        {
            "label": "Salmo No. 15",
            "value": "Salmo No. 15"
        },
        {
            "label": "Salmo No. 16",
            "value": "Salmo No. 16"
        },
        {
            "label": "Salmo No. 17",
            "value": "Salmo No. 17"
        },
        {
            "label": "Salmo No. 18",
            "value": "Salmo No. 18"
        },
        {
            "label": "Salmo No. 19",
            "value": "Salmo No. 19"
        },
        {
            "label": "Salmo No. 20",
            "value": "Salmo No. 20"
        },
        {
            "label": "Salmo No. 21",
            "value": "Salmo No. 21"
        },
        {
            "label": "Salmo No. 22",
            "value": "Salmo No. 22"
        },
        {
            "label": "Salmo No. 23",
            "value": "Salmo No. 23"
        },
        {
            "label": "Salmo No. 24",
            "value": "Salmo No. 24"
        },
        {
            "label": "Salmo No. 25",
            "value": "Salmo No. 25"
        },
        {
            "label": "Salmo No. 26",
            "value": "Salmo No. 26"
        },
        {
            "label": "Salmo No. 27",
            "value": "Salmo No. 27"
        },
        {
            "label": "Salmo No. 28",
            "value": "Salmo No. 28"
        },
        {
            "label": "Salmo No. 29",
            "value": "Salmo No. 29"
        },
        {
            "label": "Salmo No. 30",
            "value": "Salmo No. 30"
        },
        {
            "label": "Salmo No. 31",
            "value": "Salmo No. 31"
        },
        {
            "label": "Salmo No. 32",
            "value": "Salmo No. 32"
        },
        {
            "label": "Salmo No. 33",
            "value": "Salmo No. 33"
        },
        {
            "label": "Salmo No. 34",
            "value": "Salmo No. 34"
        },
        {
            "label": "Salmo No. 35",
            "value": "Salmo No. 35"
        },
        {
            "label": "Salmo No. 36",
            "value": "Salmo No. 36"
        },
        {
            "label": "Salmo No. 37",
            "value": "Salmo No. 37"
        },
        {
            "label": "Salmo No. 38",
            "value": "Salmo No. 38"
        },
        {
            "label": "Salmo No. 39",
            "value": "Salmo No. 39"
        },
        {
            "label": "Salmo No. 40",
            "value": "Salmo No. 40"
        },
        {
            "label": "Salmo No. 41",
            "value": "Salmo No. 41"
        },
        {
            "label": "Salmo No. 42",
            "value": "Salmo No. 42"
        },
        {
            "label": "Salmo No. 43",
            "value": "Salmo No. 43"
        },
        {
            "label": "Salmo No. 44",
            "value": "Salmo No. 44"
        },
        {
            "label": "Salmo No. 45",
            "value": "Salmo No. 45"
        },
        {
            "label": "Salmo No. 46",
            "value": "Salmo No. 46"
        },
        {
            "label": "Salmo No. 47",
            "value": "Salmo No. 47"
        },
        {
            "label": "Salmo No. 48",
            "value": "Salmo No. 48"
        },
        {
            "label": "Salmo No. 49",
            "value": "Salmo No. 49"
        },
        {
            "label": "Salmo No. 50",
            "value": "Salmo No. 50"
        },
        {
            "label": "Salmo No. 51",
            "value": "Salmo No. 51"
        },
        {
            "label": "Salmo No. 52",
            "value": "Salmo No. 52"
        },
        {
            "label": "Salmo No. 53",
            "value": "Salmo No. 53"
        },
        {
            "label": "Salmo No. 54",
            "value": "Salmo No. 54"
        },
        {
            "label": "Salmo No. 55",
            "value": "Salmo No. 55"
        },
        {
            "label": "Salmo No. 56",
            "value": "Salmo No. 56"
        },
        {
            "label": "Salmo No. 57",
            "value": "Salmo No. 57"
        },
        {
            "label": "Salmo No. 58",
            "value": "Salmo No. 58"
        },
        {
            "label": "Salmo No. 59",
            "value": "Salmo No. 59"
        },
        {
            "label": "Salmo No. 60",
            "value": "Salmo No. 60"
        },
        {
            "label": "Salmo No. 61",
            "value": "Salmo No. 61"
        },
        {
            "label": "Salmo No. 62",
            "value": "Salmo No. 62"
        },
        {
            "label": "Salmo No. 63",
            "value": "Salmo No. 63"
        },
        {
            "label": "Salmo No. 64",
            "value": "Salmo No. 64"
        },
        {
            "label": "Salmo No. 65",
            "value": "Salmo No. 65"
        },
        {
            "label": "Salmo No. 66",
            "value": "Salmo No. 66"
        },
        {
            "label": "Salmo No. 67",
            "value": "Salmo No. 67"
        },
        {
            "label": "Salmo No. 68",
            "value": "Salmo No. 68"
        },
        {
            "label": "Salmo No. 69",
            "value": "Salmo No. 69"
        },
        {
            "label": "Salmo No. 70",
            "value": "Salmo No. 70"
        },
        {
            "label": "Salmo No. 71",
            "value": "Salmo No. 71"
        },
        {
            "label": "Salmo No. 72",
            "value": "Salmo No. 72"
        },
        {
            "label": "Salmo No. 73",
            "value": "Salmo No. 73"
        },
        {
            "label": "Salmo No. 74",
            "value": "Salmo No. 74"
        },
        {
            "label": "Salmo No. 75",
            "value": "Salmo No. 75"
        },
        {
            "label": "Salmo No. 76",
            "value": "Salmo No. 76"
        },
        {
            "label": "Salmo No. 77",
            "value": "Salmo No. 77"
        },
        {
            "label": "Salmo No. 78",
            "value": "Salmo No. 78"
        },
        {
            "label": "Salmo No. 79",
            "value": "Salmo No. 79"
        },
        {
            "label": "Salmo No. 80",
            "value": "Salmo No. 80"
        },
        {
            "label": "Salmo No. 81",
            "value": "Salmo No. 81"
        },
        {
            "label": "Salmo No. 82",
            "value": "Salmo No. 82"
        },
        {
            "label": "Salmo No. 83",
            "value": "Salmo No. 83"
        },
        {
            "label": "Salmo No. 84",
            "value": "Salmo No. 84"
        },
        {
            "label": "Salmo No. 85",
            "value": "Salmo No. 85"
        },
        {
            "label": "Salmo No. 86",
            "value": "Salmo No. 86"
        },
        {
            "label": "Salmo No. 87",
            "value": "Salmo No. 87"
        },
        {
            "label": "Salmo No. 88",
            "value": "Salmo No. 88"
        },
        {
            "label": "Salmo No. 89",
            "value": "Salmo No. 89"
        },
        {
            "label": "Salmo No. 90",
            "value": "Salmo No. 90"
        },
        {
            "label": "Salmo No. 91",
            "value": "Salmo No. 91"
        },
        {
            "label": "Salmo No. 92",
            "value": "Salmo No. 92"
        },
        {
            "label": "Salmo No. 93",
            "value": "Salmo No. 93"
        },
        {
            "label": "Salmo No. 94",
            "value": "Salmo No. 94"
        },
        {
            "label": "Salmo No. 95",
            "value": "Salmo No. 95"
        },
        {
            "label": "Salmo No. 96",
            "value": "Salmo No. 96"
        },
        {
            "label": "Salmo No. 97",
            "value": "Salmo No. 97"
        },
        {
            "label": "Salmo No. 98",
            "value": "Salmo No. 98"
        },
        {
            "label": "Salmo No. 99",
            "value": "Salmo No. 99"
        },
        {
            "label": "Salmo No. 100",
            "value": "Salmo No. 100"
        },
        {
            "label": "Salmo No. 101",
            "value": "Salmo No. 101"
        },
        {
            "label": "Salmo No. 102",
            "value": "Salmo No. 102"
        },
        {
            "label": "Salmo No. 103",
            "value": "Salmo No. 103"
        },
        {
            "label": "Salmo No. 104",
            "value": "Salmo No. 104"
        },
        {
            "label": "Salmo No. 105",
            "value": "Salmo No. 105"
        },
        {
            "label": "Salmo No. 106",
            "value": "Salmo No. 106"
        },
        {
            "label": "Salmo No. 107",
            "value": "Salmo No. 107"
        },
        {
            "label": "Salmo No. 108",
            "value": "Salmo No. 108"
        },
        {
            "label": "Salmo No. 109",
            "value": "Salmo No. 109"
        },
        {
            "label": "Salmo No. 110",
            "value": "Salmo No. 110"
        },
        {
            "label": "Salmo No. 111",
            "value": "Salmo No. 111"
        },
        {
            "label": "Salmo No. 112",
            "value": "Salmo No. 112"
        },
        {
            "label": "Salmo No. 113",
            "value": "Salmo No. 113"
        },
        {
            "label": "Salmo No. 114",
            "value": "Salmo No. 114"
        },
        {
            "label": "Salmo No. 115",
            "value": "Salmo No. 115"
        },
        {
            "label": "Salmo No. 116",
            "value": "Salmo No. 116"
        },
        {
            "label": "Salmo No. 117",
            "value": "Salmo No. 117"
        },
        {
            "label": "Salmo No. 118",
            "value": "Salmo No. 118"
        },
        {
            "label": "Salmo No. 119",
            "value": "Salmo No. 119"
        },
        {
            "label": "Salmo No. 120",
            "value": "Salmo No. 120"
        },
        {
            "label": "Salmo No. 121",
            "value": "Salmo No. 121"
        },
        {
            "label": "Salmo No. 122",
            "value": "Salmo No. 122"
        },
        {
            "label": "Salmo No. 123",
            "value": "Salmo No. 123"
        },
        {
            "label": "Salmo No. 124",
            "value": "Salmo No. 124"
        },
        {
            "label": "Salmo No. 125",
            "value": "Salmo No. 125"
        },
        {
            "label": "Salmo No. 126",
            "value": "Salmo No. 126"
        },
        {
            "label": "Salmo No. 127",
            "value": "Salmo No. 127"
        },
        {
            "label": "Salmo No. 128",
            "value": "Salmo No. 128"
        },
        {
            "label": "Salmo No. 129",
            "value": "Salmo No. 129"
        },
        {
            "label": "Salmo No. 130",
            "value": "Salmo No. 130"
        },
        {
            "label": "Salmo No. 131",
            "value": "Salmo No. 131"
        },
        {
            "label": "Salmo No. 132",
            "value": "Salmo No. 132"
        },
        {
            "label": "Salmo No. 133",
            "value": "Salmo No. 133"
        },
        {
            "label": "Salmo No. 134",
            "value": "Salmo No. 134"
        },
        {
            "label": "Salmo No. 135",
            "value": "Salmo No. 135"
        },
        {
            "label": "Salmo No. 136",
            "value": "Salmo No. 136"
        },
        {
            "label": "Salmo No. 137",
            "value": "Salmo No. 137"
        },
        {
            "label": "Salmo No. 138",
            "value": "Salmo No. 138"
        },
        {
            "label": "Salmo No. 139",
            "value": "Salmo No. 139"
        },
        {
            "label": "Salmo No. 140",
            "value": "Salmo No. 140"
        },
        {
            "label": "Salmo No. 141",
            "value": "Salmo No. 141"
        },
        {
            "label": "Salmo No. 142",
            "value": "Salmo No. 142"
        },
        {
            "label": "Salmo No. 143",
            "value": "Salmo No. 143"
        },
        {
            "label": "Salmo No. 144",
            "value": "Salmo No. 144"
        },
        {
            "label": "Salmo No. 145",
            "value": "Salmo No. 145"
        },
        {
            "label": "Salmo No. 146",
            "value": "Salmo No. 146"
        },
        {
            "label": "Salmo No. 147",
            "value": "Salmo No. 147"
        },
        {
            "label": "Salmo No. 148",
            "value": "Salmo No. 148"
        },
        {
            "label": "Salmo No. 149",
            "value": "Salmo No. 149"
        },
        {
            "label": "Salmo No. 150",
            "value": "Salmo No. 150"
        }
    ]


    //PAISES Y ESTADOS

    const alemania = [
        {
            "value": "Baden-Wurtemberg",
            "label": "Baden-Wurtemberg"
        },
        {
            "value": "Baja Sajonia",
            "label": "Baja Sajonia"
        },
        {
            "value": "Baviera",
            "label": "Baviera"
        },
        {
            "value": "Berlín",
            "label": "Berlín"
        },
        {
            "value": "Brandeburgo",
            "label": "Brandeburgo"
        },
        {
            "value": "Bremen",
            "label": "Bremen"
        },
        {
            "value": "Hamburgo",
            "label": "Hamburgo"
        },
        {
            "value": "Hesse",
            "label": "Hesse"
        },
        {
            "value": "Mecklemburgo-Pomerania Occidental",
            "label": "Mecklemburgo-Pomerania Occidental"
        },
        {
            "value": "Renania del Norte-Westfalia",
            "label": "Renania del Norte-Westfalia"
        },
        {
            "value": "Renania-Palatinado",
            "label": "Renania-Palatinado"
        },
        {
            "value": "Sajonia",
            "label": "Sajonia"
        },
        {
            "value": "Sajonia-Anhalt",
            "label": "Sajonia-Anhalt"
        },
        {
            "value": "Sarre",
            "label": "Sarre"
        },
        {
            "value": "Schleswig-Holstein",
            "label": "Schleswig-Holstein"
        },
        {
            "value": "Turingia",
            "label": "Turingia"
        }
    ]

    const argentina = [
        {
            "value": "Provincia de Buenos Aires",
            "label": "Provincia de Buenos Aires"
        },
        {
            "value": "Provincia de Catamarca",
            "label": "Provincia de Catamarca"
        },
        {
            "value": "Provincia de Córdoba",
            "label": "Provincia de Córdoba"
        },
        {
            "value": "Provincia de Corrientes",
            "label": "Provincia de Corrientes"
        },
        {
            "value": "Provincia de Entre Ríos",
            "label": "Provincia de Entre Ríos"
        },
        {
            "value": "Provincia de Formosa",
            "label": "Provincia de Formosa"
        },
        {
            "value": "Provincia de Jujuy",
            "label": "Provincia de Jujuy"
        },
        {
            "value": "Provincia de La Pampa",
            "label": "Provincia de La Pampa"
        },
        {
            "value": "Provincia de La Rioja",
            "label": "Provincia de La Rioja"
        },
        {
            "value": "Provincia de Mendoza",
            "label": "Provincia de Mendoza"
        },
        {
            "value": "Provincia de Misiones",
            "label": "Provincia de Misiones"
        },
        {
            "value": "Provincia de Río Negro",
            "label": "Provincia de Río Negro"
        },
        {
            "value": "Provincia de Salta",
            "label": "Provincia de Salta"
        },
        {
            "value": "Provincia de San Juan",
            "label": "Provincia de San Juan"
        },
        {
            "value": "Provincia de San Luis",
            "label": "Provincia de San Luis"
        },
        {
            "value": "Provincia de Santa Cruz",
            "label": "Provincia de Santa Cruz"
        },
        {
            "value": "Provincia de Santa Fe",
            "label": "Provincia de Santa Fe"
        },
        {
            "value": "Provincia de Santiago del Estero",
            "label": "Provincia de Santiago del Estero"
        },
        {
            "value": "Provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur",
            "label": "Provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur"
        },
        {
            "value": "Provincia de Tucumán",
            "label": "Provincia de Tucumán"
        },
        {
            "value": "Provincia del Chaco",
            "label": "Provincia del Chaco"
        },
        {
            "value": "Provincia del Chubut",
            "label": "Provincia del Chubut"
        },
        {
            "value": "Provincia del Neuquén",
            "label": "Provincia del Neuquén"
        }
    ]

    const belice = [
        {
            "value": "Belice",
            "label": "Belice"
        },
        {
            "value": "Cayo",
            "label": "Cayo"
        },
        {
            "value": "Corozal",
            "label": "Corozal"
        },
        {
            "value": "Orange Walk",
            "label": "Orange Walk"
        },
        {
            "value": "Stann Creek",
            "label": "Stann Creek"
        },
        {
            "value": "Toledo",
            "label": "Toledo"
        }
    ]

    const brasil = [
        {
            "value": "Acre",
            "label": "Acre"
        },
        {
            "value": "Alagoas",
            "label": "Alagoas"
        },
        {
            "value": "Amapá",
            "label": "Amapá"
        },
        {
            "value": "Amazonas",
            "label": "Amazonas"
        },
        {
            "value": "Bahía",
            "label": "Bahía"
        },
        {
            "value": "Ceará",
            "label": "Ceará"
        },
        {
            "value": "Distrito Federal Brasília",
            "label": "Distrito Federal Brasília"
        },
        {
            "value": "Espírito Santo",
            "label": "Espírito Santo"
        },
        {
            "value": "Goiás",
            "label": "Goiás"
        },
        {
            "value": "Maranhão",
            "label": "Maranhão"
        },
        {
            "value": "Mato Grosso",
            "label": "Mato Grosso"
        },
        {
            "value": "Mato Grosso del Sur",
            "label": "Mato Grosso del Sur"
        },
        {
            "value": "Minas Gerais",
            "label": "Minas Gerais"
        },
        {
            "value": "Pará",
            "label": "Pará"
        },
        {
            "value": "Paraíba",
            "label": "Paraíba"
        },
        {
            "value": "Paraná",
            "label": "Paraná"
        },
        {
            "value": "Pernambuco",
            "label": "Pernambuco"
        },
        {
            "value": "Piauí",
            "label": "Piauí"
        },
        {
            "value": "Río de Janeiro",
            "label": "Río de Janeiro"
        },
        {
            "value": "Río Grande del Norte",
            "label": "Río Grande del Norte"
        },
        {
            "value": "Río Grande del Sur",
            "label": "Río Grande del Sur"
        },
        {
            "value": "Rondônia",
            "label": "Rondônia"
        },
        {
            "value": "Roraima",
            "label": "Roraima"
        },
        {
            "value": "Santa Catarina",
            "label": "Santa Catarina"
        },
        {
            "value": "Sao Paulo",
            "label": "Sao Paulo"
        },
        {
            "value": "Sergipe",
            "label": "Sergipe"
        },
        {
            "value": "Tocantins",
            "label": "Tocantins"
        }
    ]

    const bolivia = [
        {
            "value": "Beni",
            "label": "Beni"
        },
        {
            "value": "Chuquisaca",
            "label": "Chuquisaca"
        },
        {
            "value": "Cochabamba",
            "label": "Cochabamba"
        },
        {
            "value": "La Paz",
            "label": "La Paz"
        },
        {
            "value": "Oruro",
            "label": "Oruro"
        },
        {
            "value": "Pando",
            "label": "Pando"
        },
        {
            "value": "Potosí",
            "label": "Potosí"
        },
        {
            "value": "Santa Cruz",
            "label": "Santa Cruz"
        },
        {
            "value": "Tarija",
            "label": "Tarija"
        }
    ]

    const canada = [
        {
            "value": "Alberta",
            "label": "Alberta"
        },
        {
            "value": "Columbia Británica",
            "label": "Columbia Británica"
        },
        {
            "value": "Isla del Príncipe Eduardo",
            "label": "Isla del Príncipe Eduardo"
        },
        {
            "value": "Manitoba",
            "label": "Manitoba"
        },
        {
            "value": "Nueva Escocia",
            "label": "Nueva Escocia"
        },
        {
            "value": "Nuevo Brunswick",
            "label": "Nuevo Brunswick"
        },
        {
            "value": "Nunavut",
            "label": "Nunavut"
        },
        {
            "value": "Ontario",
            "label": "Ontario"
        },
        {
            "value": "Quebec",
            "label": "Quebec"
        },
        {
            "value": "Saskatchewan",
            "label": "Saskatchewan"
        },
        {
            "value": "Terranova y Labrador",
            "label": "Terranova y Labrador"
        },
        {
            "value": "Territorios del Noroeste",
            "label": "Territorios del Noroeste"
        },
        {
            "value": "Yukón",
            "label": "Yukón"
        }
    ]

    const chile = [
        {
            "value": "Región de Antofagasta",
            "label": "Región de Antofagasta"
        },
        {
            "value": "Región de Arica y Parinacota",
            "label": "Región de Arica y Parinacota"
        },
        {
            "value": "Región de Atacama",
            "label": "Región de Atacama"
        },
        {
            "value": "Región de Aysén del General Carlos Ibáñez del Campo",
            "label": "Región de Aysén del General Carlos Ibáñez del Campo"
        },
        {
            "value": "Región de Coquimbo",
            "label": "Región de Coquimbo"
        },
        {
            "value": "Región de La Araucanía",
            "label": "Región de La Araucanía"
        },
        {
            "value": "Región de Los Lagos",
            "label": "Región de Los Lagos"
        },
        {
            "value": "Región de Los Ríos",
            "label": "Región de Los Ríos"
        },
        {
            "value": "Región de Magallanes y de la Antártica Chilena",
            "label": "Región de Magallanes y de la Antártica Chilena"
        },
        {
            "value": "Región de Tarapacá",
            "label": "Región de Tarapacá"
        },
        {
            "value": "Región de Valparaíso",
            "label": "Región de Valparaíso"
        },
        {
            "value": "Región del Biobío",
            "label": "Región del Biobío"
        },
        {
            "value": "Región del Libertador General Bernardo O'Higgins",
            "label": "Región del Libertador General Bernardo O'Higgins"
        },
        {
            "value": "Región del Maule",
            "label": "Región del Maule"
        },
        {
            "value": "Región Metropolitana de Santiago",
            "label": "Región Metropolitana de Santiago"
        }
    ]

    const costa_rica = [
        {
            "value": "Alajuela",
            "label": "Alajuela"
        },
        {
            "value": "Cartago",
            "label": "Cartago"
        },
        {
            "value": "Guanacaste",
            "label": "Guanacaste"
        },
        {
            "value": "Heredia",
            "label": "Heredia"
        },
        {
            "value": "Limón",
            "label": "Limón"
        },
        {
            "value": "Puntarenas",
            "label": "Puntarenas"
        },
        {
            "value": "San José",
            "label": "San José"
        }
    ]
    const colombia = [
        {
            "value": "Amazonas",
            "label": "Amazonas"
        },
        {
            "value": "Antioquía",
            "label": "Antioquía"
        },
        {
            "value": "Arauca",
            "label": "Arauca"
        },
        {
            "value": "Atlántico",
            "label": "Atlántico"
        },
        {
            "value": "Bolívar",
            "label": "Bolívar"
        },
        {
            "value": "Boyacá",
            "label": "Boyacá"
        },
        {
            "value": "Caldas",
            "label": "Caldas"
        },
        {
            "value": "Caquetá",
            "label": "Caquetá"
        },
        {
            "value": "Casanare",
            "label": "Casanare"
        },
        {
            "value": "Cauca",
            "label": "Cauca"
        },
        {
            "value": "Cesar",
            "label": "Cesar"
        },
        {
            "value": "Chocó",
            "label": "Chocó"
        },
        {
            "value": "Córdoba",
            "label": "Córdoba"
        },
        {
            "value": "Cundinamarca",
            "label": "Cundinamarca"
        },
        {
            "value": "Guainía",
            "label": "Guainía"
        },
        {
            "value": "Guaviare",
            "label": "Guaviare"
        },
        {
            "value": "Huila",
            "label": "Huila"
        },
        {
            "value": "La Guajira:",
            "label": "La Guajira:"
        },
        {
            "value": "Magdalena",
            "label": "Magdalena"
        },
        {
            "value": "Meta",
            "label": "Meta"
        },
        {
            "value": "Nariño",
            "label": "Nariño"
        },
        {
            "value": "Norte de Santander",
            "label": "Norte de Santander"
        },
        {
            "value": "Putumayo",
            "label": "Putumayo"
        },
        {
            "value": "Quindío",
            "label": "Quindío"
        },
        {
            "value": "Risaralda",
            "label": "Risaralda"
        },
        {
            "value": "San Andrés y Providencia",
            "label": "San Andrés y Providencia"
        },
        {
            "value": "Santander",
            "label": "Santander"
        },
        {
            "value": "Sucre",
            "label": "Sucre"
        },
        {
            "value": "Tolima",
            "label": "Tolima"
        },
        {
            "value": "Valle del Cauca",
            "label": "Valle del Cauca"
        },
        {
            "value": "Vaupés",
            "label": "Vaupés"
        },
        {
            "value": "Vichada",
            "label": "Vichada"
        }
    ]
    const eeuu = [
        {
            "value": "Alabama",
            "label": "Alabama"
        },
        {
            "value": "Alaska",
            "label": "Alaska"
        },
        {
            "value": "Arizona",
            "label": "Arizona"
        },
        {
            "value": "Arkansas",
            "label": "Arkansas"
        },
        {
            "value": "California",
            "label": "California"
        },
        {
            "value": "Carolina del Norte",
            "label": "Carolina del Norte"
        },
        {
            "value": "Carolina del Sur",
            "label": "Carolina del Sur"
        },
        {
            "value": "Colorado",
            "label": "Colorado"
        },
        {
            "value": "Connecticut",
            "label": "Connecticut"
        },
        {
            "value": "Dakota del Norte",
            "label": "Dakota del Norte"
        },
        {
            "value": "Dakota del Sur",
            "label": "Dakota del Sur"
        },
        {
            "value": "Delaware",
            "label": "Delaware"
        },
        {
            "value": "Florida",
            "label": "Florida"
        },
        {
            "value": "Georgia",
            "label": "Georgia"
        },
        {
            "value": "Hawái",
            "label": "Hawái"
        },
        {
            "value": "Idaho",
            "label": "Idaho"
        },
        {
            "value": "Illinois",
            "label": "Illinois"
        },
        {
            "value": "Indiana",
            "label": "Indiana"
        },
        {
            "value": "Iowa",
            "label": "Iowa"
        },
        {
            "value": "Kansas",
            "label": "Kansas"
        },
        {
            "value": "Kentucky",
            "label": "Kentucky"
        },
        {
            "value": "Luisiana",
            "label": "Luisiana"
        },
        {
            "value": "Maine",
            "label": "Maine"
        },
        {
            "value": "Maryland",
            "label": "Maryland"
        },
        {
            "value": "Massachusetts",
            "label": "Massachusetts"
        },
        {
            "value": "Míchigan",
            "label": "Míchigan"
        },
        {
            "value": "Minnesota",
            "label": "Minnesota"
        },
        {
            "value": "Misisipi",
            "label": "Misisipi"
        },
        {
            "value": "Misuri",
            "label": "Misuri"
        },
        {
            "value": "Montana",
            "label": "Montana"
        },
        {
            "value": "Nebraska",
            "label": "Nebraska"
        },
        {
            "value": "Nevada",
            "label": "Nevada"
        },
        {
            "value": "Nueva Jersey",
            "label": "Nueva Jersey"
        },
        {
            "value": "Nueva York",
            "label": "Nueva York"
        },
        {
            "value": "Nuevo Hampshire",
            "label": "Nuevo Hampshire"
        },
        {
            "value": "Nuevo México",
            "label": "Nuevo México"
        },
        {
            "value": "Ohio",
            "label": "Ohio"
        },
        {
            "value": "Oklahoma",
            "label": "Oklahoma"
        },
        {
            "value": "Oregón",
            "label": "Oregón"
        },
        {
            "value": "Pensilvania",
            "label": "Pensilvania"
        },
        {
            "value": "Rhode Island",
            "label": "Rhode Island"
        },
        {
            "value": "Tennessee",
            "label": "Tennessee"
        },
        {
            "value": "Texas",
            "label": "Texas"
        },
        {
            "value": "Utah",
            "label": "Utah"
        },
        {
            "value": "Vermont",
            "label": "Vermont"
        },
        {
            "value": "Virginia",
            "label": "Virginia"
        },
        {
            "value": "Virginia Occidental",
            "label": "Virginia Occidental"
        },
        {
            "value": "Washington",
            "label": "Washington"
        },
        {
            "value": "Wisconsin",
            "label": "Wisconsin"
        },
        {
            "value": "Wyoming",
            "label": "Wyoming"
        }
    ]

    const ecuador = [
        {
            "value": "Azuay",
            "label": "Azuay"
        },
        {
            "value": "Bolívar",
            "label": "Bolívar"
        },
        {
            "value": "Cañar",
            "label": "Cañar"
        },
        {
            "value": "Carchi",
            "label": "Carchi"
        },
        {
            "value": "Chimborazo",
            "label": "Chimborazo"
        },
        {
            "value": "Cotopaxi",
            "label": "Cotopaxi"
        },
        {
            "value": "El Oro",
            "label": "El Oro"
        },
        {
            "value": "Esmeraldas",
            "label": "Esmeraldas"
        },
        {
            "value": "Galápagos",
            "label": "Galápagos"
        },
        {
            "value": "Guayas",
            "label": "Guayas"
        },
        {
            "value": "Imbabura",
            "label": "Imbabura"
        },
        {
            "value": "Loja",
            "label": "Loja"
        },
        {
            "value": "Los Ríos",
            "label": "Los Ríos"
        },
        {
            "value": "Manabí",
            "label": "Manabí"
        },
        {
            "value": "Morona Santiago",
            "label": "Morona Santiago"
        },
        {
            "value": "Napo",
            "label": "Napo"
        },
        {
            "value": "Orellana",
            "label": "Orellana"
        },
        {
            "value": "Pastaza",
            "label": "Pastaza"
        },
        {
            "value": "Pichincha",
            "label": "Pichincha"
        },
        {
            "value": "Santa Elena",
            "label": "Santa Elena"
        },
        {
            "value": "Santo Domingo de los T-Sáchilas",
            "label": "Santo Domingo de los T-Sáchilas"
        },
        {
            "value": "Sucumbíos",
            "label": "Sucumbíos"
        },
        {
            "value": "Tungurahua",
            "label": "Tungurahua"
        },
        {
            "value": "Zamora Chinchipe",
            "label": "Zamora Chinchipe"
        }
    ]

    const espana = [
        {
            "value": "A Coruña",
            "label": "A Coruña"
        },
        {
            "value": "Álava",
            "label": "Álava"
        },
        {
            "value": "Albacete",
            "label": "Albacete"
        },
        {
            "value": "Alicante",
            "label": "Alicante"
        },
        {
            "value": "Almería",
            "label": "Almería"
        },
        {
            "value": "Asturias",
            "label": "Asturias"
        },
        {
            "value": "Ávila",
            "label": "Ávila"
        },
        {
            "value": "Badajoz",
            "label": "Badajoz"
        },
        {
            "value": "Baleares",
            "label": "Baleares"
        },
        {
            "value": "Barcelona",
            "label": "Barcelona"
        },
        {
            "value": "Burgos",
            "label": "Burgos"
        },
        {
            "value": "Cáceres",
            "label": "Cáceres"
        },
        {
            "value": "Cádiz",
            "label": "Cádiz"
        },
        {
            "value": "Cantabria",
            "label": "Cantabria"
        },
        {
            "value": "Castellón",
            "label": "Castellón"
        },
        {
            "value": "Ceuta",
            "label": "Ceuta"
        },
        {
            "value": "Ciudad Real",
            "label": "Ciudad Real"
        },
        {
            "value": "Córdoba",
            "label": "Córdoba"
        },
        {
            "value": "Cuenca",
            "label": "Cuenca"
        },
        {
            "value": "Gipuzkoa",
            "label": "Gipuzkoa"
        },
        {
            "value": "Girona",
            "label": "Girona"
        },
        {
            "value": "Granada",
            "label": "Granada"
        },
        {
            "value": "Guadalajara",
            "label": "Guadalajara"
        },
        {
            "value": "Huelva",
            "label": "Huelva"
        },
        {
            "value": "Huesca",
            "label": "Huesca"
        },
        {
            "value": "Jaén",
            "label": "Jaén"
        },
        {
            "value": "La Rioja",
            "label": "La Rioja"
        },
        {
            "value": "Las Palmas",
            "label": "Las Palmas"
        },
        {
            "value": "León",
            "label": "León"
        },
        {
            "value": "Lleida",
            "label": "Lleida"
        },
        {
            "value": "Lugo",
            "label": "Lugo"
        },
        {
            "value": "Madrid",
            "label": "Madrid"
        },
        {
            "value": "Málaga",
            "label": "Málaga"
        },
        {
            "value": "Melilla",
            "label": "Melilla"
        },
        {
            "value": "Murcia",
            "label": "Murcia"
        },
        {
            "value": "Navarra",
            "label": "Navarra"
        },
        {
            "value": "Ourense",
            "label": "Ourense"
        },
        {
            "value": "Palencia",
            "label": "Palencia"
        },
        {
            "value": "Pontevedra",
            "label": "Pontevedra"
        },
        {
            "value": "Salamanca",
            "label": "Salamanca"
        },
        {
            "value": "Santa Cruz de Tenerife",
            "label": "Santa Cruz de Tenerife"
        },
        {
            "value": "Segovia",
            "label": "Segovia"
        },
        {
            "value": "Sevilla",
            "label": "Sevilla"
        },
        {
            "value": "Soria",
            "label": "Soria"
        },
        {
            "value": "Tarragona",
            "label": "Tarragona"
        },
        {
            "value": "Teruel",
            "label": "Teruel"
        },
        {
            "value": "Toledo",
            "label": "Toledo"
        },
        {
            "value": "Valencia",
            "label": "Valencia"
        },
        {
            "value": "Valladolid",
            "label": "Valladolid"
        },
        {
            "value": "Vizcaya",
            "label": "Vizcaya"
        },
        {
            "value": "Zamora",
            "label": "Zamora"
        },
        {
            "value": "Zaragoza",
            "label": "Zaragoza"
        }
    ]

    const el_salvador = [
        {
            "value": "Ahuachapán",
            "label": "Ahuachapán"
        },
        {
            "value": "Cabañas",
            "label": "Cabañas"
        },
        {
            "value": "Chalatenango",
            "label": "Chalatenango"
        },
        {
            "value": "Cuscatlán",
            "label": "Cuscatlán"
        },
        {
            "value": "La Libertad",
            "label": "La Libertad"
        },
        {
            "value": "La Paz",
            "label": "La Paz"
        },
        {
            "value": "La Unión",
            "label": "La Unión"
        },
        {
            "value": "Morazán",
            "label": "Morazán"
        },
        {
            "value": "San Miguel",
            "label": "San Miguel"
        },
        {
            "value": "San Salvador",
            "label": "San Salvador"
        },
        {
            "value": "San Vicente",
            "label": "San Vicente"
        },
        {
            "value": "Santa Ana",
            "label": "Santa Ana"
        },
        {
            "value": "Sonsonate",
            "label": "Sonsonate"
        },
        {
            "value": "Usulután",
            "label": "Usulután"
        }
    ]

    const francia = [
        {
            "value": "Altos de Francia",
            "label": "Altos de Francia"
        },
        {
            "value": "Auvernia-Ródano-Alpes",
            "label": "Auvernia-Ródano-Alpes"
        },
        {
            "value": "Borgoña-Franco Condado",
            "label": "Borgoña-Franco Condado"
        },
        {
            "value": "Bretaña",
            "label": "Bretaña"
        },
        {
            "value": "Centro-Val de Loira",
            "label": "Centro-Val de Loira"
        },
        {
            "value": "Córcega",
            "label": "Córcega"
        },
        {
            "value": "Gran Este",
            "label": "Gran Este"
        },
        {
            "value": "Isla de Francia",
            "label": "Isla de Francia"
        },
        {
            "value": "Normandía",
            "label": "Normandía"
        },
        {
            "value": "Nueva Aquitania",
            "label": "Nueva Aquitania"
        },
        {
            "value": "Occitania",
            "label": "Occitania"
        },
        {
            "value": "País del Loira",
            "label": "País del Loira"
        },
        {
            "value": "Provenza-Alpes-Costa Azul",
            "label": "Provenza-Alpes-Costa Azul"
        }
    ]

    const guatemala = [
        {
            "value": "Alta Verapaz",
            "label": "Alta Verapaz"
        },
        {
            "value": "Baja Verapaz",
            "label": "Baja Verapaz"
        },
        {
            "value": "Chimaltenango",
            "label": "Chimaltenango"
        },
        {
            "value": "Chiquimula",
            "label": "Chiquimula"
        },
        {
            "value": "El Progreso",
            "label": "El Progreso"
        },
        {
            "value": "Escuintla",
            "label": "Escuintla"
        },
        {
            "value": "Guatemala",
            "label": "Guatemala"
        },
        {
            "value": "Huehuetenango",
            "label": "Huehuetenango"
        },
        {
            "value": "Izabal",
            "label": "Izabal"
        },
        {
            "value": "Jalapa",
            "label": "Jalapa"
        },
        {
            "value": "Jutiapa",
            "label": "Jutiapa"
        },
        {
            "value": "Petén",
            "label": "Petén"
        },
        {
            "value": "Quetzaltenango",
            "label": "Quetzaltenango"
        },
        {
            "value": "Quiché",
            "label": "Quiché"
        },
        {
            "value": "Retalhuleu",
            "label": "Retalhuleu"
        },
        {
            "value": "Sacatepéquez",
            "label": "Sacatepéquez"
        },
        {
            "value": "San Marcos",
            "label": "San Marcos"
        },
        {
            "value": "Santa Rosa",
            "label": "Santa Rosa"
        },
        {
            "value": "Sololá",
            "label": "Sololá"
        },
        {
            "value": "Suchitepéquez",
            "label": "Suchitepéquez"
        },
        {
            "value": "Totonicapán",
            "label": "Totonicapán"
        },
        {
            "value": "Zacapa",
            "label": "Zacapa"
        }
    ]

    const gibraltar = [
        {
            "value": "East Side",
            "label": "East Side"
        },
        {
            "value": "North District",
            "label": "North District"
        },
        {
            "value": "Reclamation Areas",
            "label": "Reclamation Areas"
        },
        {
            "value": "Sandpits Area",
            "label": "Sandpits Area"
        },
        {
            "value": "South District",
            "label": "South District"
        },
        {
            "value": "Town Area",
            "label": "Town Area"
        },
        {
            "value": "Upper Town",
            "label": "Upper Town"
        }
    ]

    const honduras = [
        {
            "value": "Atlántida",
            "label": "Atlántida"
        },
        {
            "value": "Choluteca",
            "label": "Choluteca"
        },
        {
            "value": "Colón",
            "label": "Colón"
        },
        {
            "value": "Comayagua",
            "label": "Comayagua"
        },
        {
            "value": "Copán",
            "label": "Copán"
        },
        {
            "value": "Cortés",
            "label": "Cortés"
        },
        {
            "value": "El Paraíso",
            "label": "El Paraíso"
        },
        {
            "value": "Francisco Morazán",
            "label": "Francisco Morazán"
        },
        {
            "value": "Gracias a Dios",
            "label": "Gracias a Dios"
        },
        {
            "value": "Intibucá",
            "label": "Intibucá"
        },
        {
            "value": "Islas de la Bahía",
            "label": "Islas de la Bahía"
        },
        {
            "value": "La Paz",
            "label": "La Paz"
        },
        {
            "value": "Lempira",
            "label": "Lempira"
        },
        {
            "value": "Ocotepeque",
            "label": "Ocotepeque"
        },
        {
            "value": "Olancho",
            "label": "Olancho"
        },
        {
            "value": "Santa Bárbara",
            "label": "Santa Bárbara"
        },
        {
            "value": "Valle",
            "label": "Valle"
        },
        {
            "value": "Yoro",
            "label": "Yoro"
        }
    ]

    const italia = [
        {
            "value": "Abruzos",
            "label": "Abruzos"
        },
        {
            "value": "Apulia",
            "label": "Apulia"
        },
        {
            "value": "Basilicata",
            "label": "Basilicata"
        },
        {
            "value": "Calabria",
            "label": "Calabria"
        },
        {
            "value": "Campania",
            "label": "Campania"
        },
        {
            "value": "Cerdeña",
            "label": "Cerdeña"
        },
        {
            "value": "Emilia-Romaña",
            "label": "Emilia-Romaña"
        },
        {
            "value": "Friuli-Venecia Julia",
            "label": "Friuli-Venecia Julia"
        },
        {
            "value": "Lacio",
            "label": "Lacio"
        },
        {
            "value": "Liguria",
            "label": "Liguria"
        },
        {
            "value": "Lombardía",
            "label": "Lombardía"
        },
        {
            "value": "Marcas",
            "label": "Marcas"
        },
        {
            "value": "Molise",
            "label": "Molise"
        },
        {
            "value": "Piamonte",
            "label": "Piamonte"
        },
        {
            "value": "Sicilia",
            "label": "Sicilia"
        },
        {
            "value": "Toscana",
            "label": "Toscana"
        },
        {
            "value": "Trentino-Alto Adigio",
            "label": "Trentino-Alto Adigio"
        },
        {
            "value": "Umbría",
            "label": "Umbría"
        },
        {
            "value": "Valle de Aosta",
            "label": "Valle de Aosta"
        },
        {
            "value": "Véneto",
            "label": "Véneto"
        }
    ]

    const israel = [
        {
            "value": "Área de Judea y Samaria",
            "label": "Área de Judea y Samaria"
        },
        {
            "value": "Distrito Central",
            "label": "Distrito Central"
        },
        {
            "value": "Distrito de Haifa",
            "label": "Distrito de Haifa"
        },
        {
            "value": "Distrito de Jerusalén",
            "label": "Distrito de Jerusalén"
        },
        {
            "value": "Distrito de Tel Aviv",
            "label": "Distrito de Tel Aviv"
        },
        {
            "value": "Distrito Meridional",
            "label": "Distrito Meridional"
        },
        {
            "value": "Distrito Norte",
            "label": "Distrito Norte"
        }
    ]


    const peru = [
        {
            "value": "Amazonas",
            "label": "Amazonas"
        },
        {
            "value": "Áncash",
            "label": "Áncash"
        },
        {
            "value": "Apurímac",
            "label": "Apurímac"
        },
        {
            "value": "Arequipa",
            "label": "Arequipa"
        },
        {
            "value": "Ayacucho",
            "label": "Ayacucho"
        },
        {
            "value": "Cajamarca",
            "label": "Cajamarca"
        },
        {
            "value": "Callao",
            "label": "Callao"
        },
        {
            "value": "Cusco",
            "label": "Cusco"
        },
        {
            "value": "Huancavelica",
            "label": "Huancavelica"
        },
        {
            "value": "Huánuco",
            "label": "Huánuco"
        },
        {
            "value": "Ica",
            "label": "Ica"
        },
        {
            "value": "Junín",
            "label": "Junín"
        },
        {
            "value": "La Libertad",
            "label": "La Libertad"
        },
        {
            "value": "Lambayeque",
            "label": "Lambayeque"
        },
        {
            "value": "Lima (departamento)",
            "label": "Lima (departamento)"
        },
        {
            "value": "Lima Metropolitana",
            "label": "Lima Metropolitana"
        },
        {
            "value": "Loreto",
            "label": "Loreto"
        },
        {
            "value": "Madre de Dios",
            "label": "Madre de Dios"
        },
        {
            "value": "Moquegua",
            "label": "Moquegua"
        },
        {
            "value": "Pasco",
            "label": "Pasco"
        },
        {
            "value": "Piura",
            "label": "Piura"
        },
        {
            "value": "Puno",
            "label": "Puno"
        },
        {
            "value": "San Martín",
            "label": "San Martín"
        },
        {
            "value": "Tacna",
            "label": "Tacna"
        },
        {
            "value": "Tumbes",
            "label": "Tumbes"
        },
        {
            "value": "Ucayali",
            "label": "Ucayali"
        }
    ]

    const panama = [
        {
            "value": "Bocas del Toro",
            "label": "Bocas del Toro"
        },
        {
            "value": "Chiriquí",
            "label": "Chiriquí"
        },
        {
            "value": "Coclé",
            "label": "Coclé"
        },
        {
            "value": "Colón",
            "label": "Colón"
        },
        {
            "value": "Darién",
            "label": "Darién"
        },
        {
            "value": "Herrera",
            "label": "Herrera"
        },
        {
            "value": "Los Santos",
            "label": "Los Santos"
        },
        {
            "value": "Panamá",
            "label": "Panamá"
        },
        {
            "value": "Panamá Oeste",
            "label": "Panamá Oeste"
        },
        {
            "value": "Veraguas",
            "label": "Veraguas"
        }
    ]

    const paraguay = [
        {
            "value": "Alto Paraguay",
            "label": "Alto Paraguay"
        },
        {
            "value": "Alto Paraná",
            "label": "Alto Paraná"
        },
        {
            "value": "Amambay",
            "label": "Amambay"
        },
        {
            "value": "Boquerón",
            "label": "Boquerón"
        },
        {
            "value": "Caaguazú",
            "label": "Caaguazú"
        },
        {
            "value": "Caazapá",
            "label": "Caazapá"
        },
        {
            "value": "Canindeyú",
            "label": "Canindeyú"
        },
        {
            "value": "Central",
            "label": "Central"
        },
        {
            "value": "Concepción",
            "label": "Concepción"
        },
        {
            "value": "Cordillera",
            "label": "Cordillera"
        },
        {
            "value": "Guairá",
            "label": "Guairá"
        },
        {
            "value": "Itapúa",
            "label": "Itapúa"
        },
        {
            "value": "Misiones",
            "label": "Misiones"
        },
        {
            "value": "Ñeembucú",
            "label": "Ñeembucú"
        },
        {
            "value": "Paraguarí",
            "label": "Paraguarí"
        },
        {
            "value": "Paraguay",
            "label": "Paraguay"
        },
        {
            "value": "Presidente Hayes",
            "label": "Presidente Hayes"
        },
        {
            "value": "San Pedro",
            "label": "San Pedro"
        }
    ]

    const portugal = [
        {
            "value": "Aveiro",
            "label": "Aveiro"
        },
        {
            "value": "Beja",
            "label": "Beja"
        },
        {
            "value": "Braga",
            "label": "Braga"
        },
        {
            "value": "Braganza",
            "label": "Braganza"
        },
        {
            "value": "Castelo Branco",
            "label": "Castelo Branco"
        },
        {
            "value": "Coímbra",
            "label": "Coímbra"
        },
        {
            "value": "Évora",
            "label": "Évora"
        },
        {
            "value": "Faro",
            "label": "Faro"
        },
        {
            "value": "Guarda",
            "label": "Guarda"
        },
        {
            "value": "Leiría",
            "label": "Leiría"
        },
        {
            "value": "Lisboa",
            "label": "Lisboa"
        },
        {
            "value": "Oporto",
            "label": "Oporto"
        },
        {
            "value": "Portalegre",
            "label": "Portalegre"
        },
        {
            "value": "Santarém",
            "label": "Santarém"
        },
        {
            "value": "Setúbal",
            "label": "Setúbal"
        },
        {
            "value": "Viana do Castelo",
            "label": "Viana do Castelo"
        },
        {
            "value": "Vila Real",
            "label": "Vila Real"
        },
        {
            "value": "Viseu",
            "label": "Viseu"
        }
    ]

    const uruguay = [
        {
            "value": "Artigas",
            "label": "Artigas"
        },
        {
            "value": "Canelones",
            "label": "Canelones"
        },
        {
            "value": "Cerro Largo",
            "label": "Cerro Largo"
        },
        {
            "value": "Colonia",
            "label": "Colonia"
        },
        {
            "value": "Durazno",
            "label": "Durazno"
        },
        {
            "value": "Flores",
            "label": "Flores"
        },
        {
            "value": "Florida",
            "label": "Florida"
        },
        {
            "value": "Lavalleja",
            "label": "Lavalleja"
        },
        {
            "value": "Maldonado",
            "label": "Maldonado"
        },
        {
            "value": "Montevideo",
            "label": "Montevideo"
        },
        {
            "value": "Paysandú",
            "label": "Paysandú"
        },
        {
            "value": "Río Negro",
            "label": "Río Negro"
        },
        {
            "value": "Rivera",
            "label": "Rivera"
        },
        {
            "value": "Rocha",
            "label": "Rocha"
        },
        {
            "value": "Salto",
            "label": "Salto"
        },
        {
            "value": "San José",
            "label": "San José"
        },
        {
            "value": "Soriano",
            "label": "Soriano"
        },
        {
            "value": "Tacuarembó",
            "label": "Tacuarembó"
        },
        {
            "value": "Treinta y Tres",
            "label": "Treinta y Tres"
        }
    ]

    const venezuela = [
        {
            "value": "Amazonas",
            "label": "Amazonas"
        },
        {
            "value": "Anzoátegui",
            "label": "Anzoátegui"
        },
        {
            "value": "Apure",
            "label": "Apure"
        },
        {
            "value": "Aragua",
            "label": "Aragua"
        },
        {
            "value": "Barinas",
            "label": "Barinas"
        },
        {
            "value": "Bolívar",
            "label": "Bolívar"
        },
        {
            "value": "Carabobo",
            "label": "Carabobo"
        },
        {
            "value": "Caracas",
            "label": "Caracas"
        },
        {
            "value": "Cojedes",
            "label": "Cojedes"
        },
        {
            "value": "Delta Amacuro",
            "label": "Delta Amacuro"
        },
        {
            "value": "Falcón",
            "label": "Falcón"
        },
        {
            "value": "Guárico",
            "label": "Guárico"
        },
        {
            "value": "Lara",
            "label": "Lara"
        },
        {
            "value": "Mérida",
            "label": "Mérida"
        },
        {
            "value": "Miranda",
            "label": "Miranda"
        },
        {
            "value": "Monagas",
            "label": "Monagas"
        },
        {
            "value": "Nueva Esparta",
            "label": "Nueva Esparta"
        },
        {
            "value": "Portuguesa",
            "label": "Portuguesa"
        },
        {
            "value": "Sucre",
            "label": "Sucre"
        },
        {
            "value": "Táchira",
            "label": "Táchira"
        },
        {
            "value": "Trujillo",
            "label": "Trujillo"
        },
        {
            "value": "Vargas",
            "label": "Vargas"
        },
        {
            "value": "Yaracuy",
            "label": "Yaracuy"
        },
        {
            "value": "Zulia",
            "label": "Zulia"
        }
    ]
    const mexico = [
        {
            "label": "Aguascalientes",
            "value": "Aguascalientes"
        },
        {
            "label": "Baja California",
            "value": "Baja California"
        },
        {
            "label": "Baja California Sur",
            "value": "Baja California Sur"
        },
        {
            "label": "Campeche",
            "value": "Campeche"
        },
        {
            "label": "Chiapas",
            "value": "Chiapas"
        },
        {
            "label": "Chihuahua",
            "value": "Chihuahua"
        },
        {
            "label": "Coahuila",
            "value": "Coahuila"
        },
        {
            "label": "Colima",
            "value": "Colima"
        },
        {
            "label": "Ciudad de México / Distrito Federal",
            "value": "Ciudad de México / Distrito Federal"
        },
        {
            "label": "Durango",
            "value": "Durango"
        },
        {
            "label": "Estado de México",
            "value": "Estado de México"
        },
        {
            "label": "Guanajuato",
            "value": "Guanajuato"
        },
        {
            "label": "Guerrero",
            "value": "Guerrero"
        },
        {
            "label": "Hidalgo",
            "value": "Hidalgo"
        },
        {
            "label": "Jalisco",
            "value": "Jalisco"
        },
        {
            "label": "Michoacán",
            "value": "Michoacán"
        },
        {
            "label": "Morelos",
            "value": "Morelos"
        },
        {
            "label": "Nayarit",
            "value": "Nayarit"
        },
        {
            "label": "Nuevo León",
            "value": "Nuevo León"
        },
        {
            "label": "Oaxaca",
            "value": "Oaxaca"
        },
        {
            "label": "Puebla",
            "value": "Puebla"
        },
        {
            "label": "Querétaro",
            "value": "Querétaro"
        },
        {
            "label": "Quintana Roo",
            "value": "Quintana Roo"
        },
        {
            "label": "San Luis Potosí",
            "value": "San Luis Potosí"
        },
        {
            "label": "Sinaloa",
            "value": "Sinaloa"
        },
        {
            "label": "Sonora",
            "value": "Sonora"
        },
        {
            "label": "Tabasco",
            "value": "Tabasco"
        },
        {
            "label": "Tamaulipas",
            "value": "Tamaulipas"
        },
        {
            "label": "Tlaxcala",
            "value": "Tlaxcala"
        },
        {
            "label": "Veracruz",
            "value": "Veracruz"
        },
        {
            "label": "Yucatán",
            "value": "Yucatán"
        },
        {
            "label": "Zacatecas",
            "value": "Zacatecas"
        }
    ]



    let continueWitRegister = true;





    const getSongs = (songs) => {
        return (
            <Select onChange={e => setHolocaustTitle(e.value)} options={songs} />
        )
    }


    const getCountries = () => {
        return (
            <Select onChange={handleCountry} options={countries} />
        )
    }

    const getStates = (stateList) => {
        return (
            <Select onChange={handleState} options={stateList} />
        )
    }

    let changeInputFileLabel = (e) => {
        console.log(e.target.files)
        setFile(e.target.files)
        document.getElementById("inputFile").innerHTML = e.target.files[0].name
    }


    let titleSongSection = document.getElementById("titleSongSection");

    let grupalParticipationCombo = document.getElementById("grupalParticipationCombo");

    let checkIfGrupalParticipationIsAvailable = () => {
        console.log(participationTypes)
        if (participationTypes == "Grupal") {

            return (
                <div class="mb-3" id="grupalParticipationCombo">
                    <label>Tipo de Participación Grupal</label>
                    <select onChange={handleGrupalParticipation} required class="custom-select">
                        <option selected>Ninguna seleccionada</option>
                        <option value="Sociedad">Sociedad</option>
                        <option value="Grupo Musical">Grupo Musical</option>
                        <option value="Familia">Familia</option>
                    </select>
                    <div style={{ height: "15px" }}></div>
                    <div class="mb-3">
                        <label>Nombre Sociedad, Grupo Musical o Familia</label>
                        <input onChange={handleGroupName} type="text" class="form-control" required placeholder="Escriba aquí"></input>
                    </div>
                </div>
            )
        }
    }




    let searchSongSection = document.getElementById("searchSongSection");


    let durationHolocaust = document.getElementById("durationHolocaust");
    let rangeHolocaust = document.getElementById("rangeHolocaust")
    let getRangeInputHolocaustDuration = (e) => {
        console.log(e)
        setDurationSong(e.target.value)
        console.log(e.target.value)
    }


    const handlerSubmit = (e) => {
        e.preventDefault();
        handleUploadData()
    }






    let videoUrlAux = localStorage.getItem("@urlVideo");

    const handleUploadData = () => {
        let videoUrlAux = localStorage.getItem("@urlVideo");
        let holocaustObject = {
            registerName: registerName,
            registerFirstName: registerFirstName,
            registerLastName: registerLastName,
            registerEmail: registerEmail,
            countryCode: countryCode,
            state: state,
            local: local,
            lada: lada,
            phone: phone,
            participationTypes: participationTypes,
            grupalParticipations: grupalParticipations,
            groupName: groupName,
            titleHolocaust: holocaustTitle,
            holocaustTypes: holocaustTypes,
            durationHolocaust: durationSong,
            datePresent: datePresent,
            commentHolocaust: comments,
            urlFile: videoUrlAux,
            confirmado: false
        }

        if (videoUrlAux == null) {
            Swal.fire(
                'No se ha seleccionado un archivo',
                'Carga tu archivo para continuar',
                'error'
            )
        } else {
            let isGrupal = checkIsParticipationGrupal(participationTypes);
            if (isGrupal) {

                if (grupalParticipations != "" && groupName != "" && videoUrlAux != null && datePresent != "") {
                    db.collection('holocaustos').add(holocaustObject)


                    emailjs.send("churubusco_privatemail", "template_registroHolocau", {
                        nombreRegistrante: registerName + " " + registerFirstName + " " + registerLastName,
                        emailRegistrante: registerEmail
                    }, "user_BU2y1xMxhazWdSDHcARB6");



                    Swal.fire(
                        '¡Holocausto registrado!',
                        'Pronto te contactaremos, verifica tu correo electrónico',
                        'success'
                    ).then(() => {
                        localStorage.clear()
                        window.location.href = '/accepted';
                    })
                } else {
                    Swal.fire(
                        'Falta información',
                        'Verifica que toda la información esté completa',
                        'error'
                    )
                }

            } else {
                if (checkDataNotNull() == true) {
                    emailjs.send("churubusco_privatemail", "template_registroHolocau", {
                        nombreRegistrante: registerName + " " + registerFirstName + " " + registerLastName,
                        emailRegistrante: registerEmail
                    }, "user_BU2y1xMxhazWdSDHcARB6");

                    db.collection('holocaustos').doc().set(holocaustObject)


                    Swal.fire(
                        '¡Holocausto registrado!',
                        'Pronto te contactaremos, verifica tu correo electrónico',
                        'success'
                    ).then(() => {
                        localStorage.clear()
                        window.location.href = '/accepted';
                    })
                }
            }
        }



    }

    /*
        const validateHolocaustType = ()=>{
            if(holocaustTypes == "Salmo" ||  holocaustTypes == "Himno del Himnario General"){
                
            }
        }
    */

    const checkDataNotNull = () => {


        if (registerName == "") {
            Swal.fire(
                'Campo Nombre no puede ir vacío',
                'Ingresa tu nombre',
                'error'
            )

            continueWitRegister = false;
        } else if (registerFirstName == "") {
            Swal.fire(
                'Campo Apellido Paterno no puede ir vacío',
                'Ingresar apellido',
                'error'
            )

            continueWitRegister = false;
        } else if (registerLastName == "") {
            Swal.fire(
                'Campo Apellido Materno  no puede ir vacío',
                'Ingresar apellido',
                'error'
            )

            continueWitRegister = false;

        } else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(registerEmail) == false)) {

            Swal.fire(
                'E-mail no válido',
                'Ingresa un e-mail correcto',
                'error'
            )
            continueWitRegister = false;
        } else if (countryCode == "") {
            Swal.fire(
                'Campo Código de país no puede ir vacío',
                'Ingresa tu Código',
                'error'
            )
            continueWitRegister = false;

        } else if (country == "") {
            Swal.fire(
                'Campo País no puede ir vacío',
                'Ingresa tu país',
                'error'
            )

            continueWitRegister = false;
        } else if (state == "") {
            Swal.fire(
                'Campo Estado no puede ir vacío',
                'Ingresa tu estado',
                'error'
            )

            continueWitRegister = false;

        } else if (local == "") {
            Swal.fire(
                'Campo Localidad no puede ir vacío',
                'Ingresa tu localidad',
                'error'
            )
            continueWitRegister = false;

        } else if (lada == "") {
            Swal.fire(
                'Campo Lada no puede ir vacío',
                'Ingresa tu Lada',
                'error'
            )

            continueWitRegister = false;

        } else if (phone == "") {
            Swal.fire(
                'Campo Número no puede ir vacío',
                'Ingresa tu número',
                'error'
            )

            continueWitRegister = false;

        } else if (participationTypes == "") {
            Swal.fire(
                'Campo Típo de Participación no puede ir vacío',
                'Selecciona un tipo',
                'error'
            )
            continueWitRegister = false;
        } else if (holocaustTypes == "") {
            Swal.fire(
                'Campo Típo de Holocausto no puede ir vacío',
                'Selecciona un tipo',
                'error'
            )
            continueWitRegister = false;
        } else if (holocaustTitle == "") {
            Swal.fire(
                'El título del holocausto no puede ir vacío',
                'Ingresa cuánto dura tu holocausto',
                'error'
            )
            continueWitRegister = false;
        } else if (durationSong == "") {
            Swal.fire(
                'Campo duración no puede ir vacío',
                'Ingresa cuánto dura tu holocausto',
                'error'
            )
            continueWitRegister = false;
        } else if (datePresent == "") {
            Swal.fire(
                'Campo fecha de presentación no puede ir vacío',
                'Ingresa una fecha de presentación',
                'error'
            )
            continueWitRegister = false;
        }

        return continueWitRegister;

    }

    const checkIsParticipationGrupal = (participation) => {
        if (participationTypes == "Grupal") {
            return true
        } else if (participationTypes == "Individual") {
            return false
        }
    }


    const [registerName, setRegisterName] = useState('');
    const [registerFirstName, setRegisterFirstName] = useState('');
    const [registerLastName, setRegisterLastName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');

    const [countryCode, setCountryCode] = useState('');
    const [lada, setLada] = useState('');
    const [phone, setPhone] = useState('');

    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [local, setLocal] = useState('');

    const [participationTypes, setParticipationTypes] = useState('');
    const [grupalParticipations, setGrupalParticipations] = useState('');

    const [groupName, setGroupName] = useState('');
    const [holocaustTypes, setHholocaustTypes] = useState('');

    const [durationSong, setDurationSong] = useState('');

    const [datePresent, setDatePresent] = useState('');

    const [file, setFile] = useState('');

    const [comments, setComments] = useState('');

    const [downloadUrl, setDownLoadUrl] = useState('');


    const [holocaustTitle, setHolocaustTitle] = useState('')



    let countries = [
        {
            "value": "Alemania",
            "label": "Alemania"
        },
        {
            "value": "Argentina",
            "label": "Argentina"
        },
        {
            "value": "Belice",
            "label": "Belice"
        },
        {
            "value": "Bolivia",
            "label": "Bolivia"
        },
        {
            "value": "Brasil",
            "label": "Brasil"
        },
        {
            "value": "Canadá",
            "label": "Canadá"
        },
        {
            "value": "Chile",
            "label": "Chile"
        },
        {
            "value": "Colombia",
            "label": "Colombia"
        },
        {
            "value": "Costa Rica",
            "label": "Costa Rica"
        },
        {
            "value": "Ecuador",
            "label": "Ecuador"
        },
        {
            "value": "El Salvador",
            "label": "El Salvador"
        },
        {
            "value": "España",
            "label": "España"
        },
        {
            "value": "Estados Unidos",
            "label": "Estados Unidos"
        },
        {
            "value": "Francia",
            "label": "Francia"
        },
        {
            "value": "Gibraltar",
            "label": "Gibraltar"
        },
        {
            "value": "Guatemala",
            "label": "Guatemala"
        },
        {
            "value": "Honduras",
            "label": "Honduras"
        },
        {
            "value": "Israel",
            "label": "Israel"
        },
        {
            "value": "Italia",
            "label": "Italia"
        },
        {
            "value": "México",
            "label": "México"
        },
        {
            "value": "Panamá",
            "label": "Panamá"
        },
        {
            "value": "Paraguay",
            "label": "Paraguay"
        },
        {
            "value": "Perú",
            "label": "Perú"
        },
        {
            "value": "Portugal",
            "label": "Portugal"
        },
        {
            "value": "Uruguay",
            "label": "Uruguay"
        },
        {
            "value": "Venezuela",
            "label": "Venezuela"
        }
    ]

    /**Functions for setting data */

    let handleRegisterName = (e) => {
        setRegisterName(e.target.value)
    }

    let handleRegisterFirstName = (e) => {
        setRegisterFirstName(e.target.value)
    }

    let handleRegisterLastName = (e) => {
        setRegisterLastName(e.target.value)
        localStorage.setItem("@videoInfo", registerName + "_" + registerFirstName + "_" + registerLastName)
        console.log(localStorage.getItem("@videoInfo"))
    }

    let handleRegisterEmail = (e) => {
        setRegisterEmail(e.target.value)
    }

    let handleCountryCode = (e) => {
        setCountryCode(e.target.value)
    }

    let handleLada = (e) => {
        setLada(e.target.value)
    }


    let handleParticipationType = (e) => {
        setParticipationTypes(e.target.value)
    }

    let handleGrupalParticipation = (e) => {
        setGrupalParticipations(e.target.value)
    }

    let handleGroupName = (e) => {
        setGroupName(e.target.value)
    }

    let handleHolocaustType = (e) => {

        setHholocaustTypes(e.target.value)
    }

    let handleHolocaustTitle = (e) => {
        setHolocaustTitle(e.value)
    }

    let handleDurationSong = (e) => {
        console.log(e)
        setDurationSong(e.target.value)
    }

    let handleDatePresent = (e) => {
        setDatePresent(e.target.value)
    }

    let handleComments = (e) => {
        setComments(e.target.value)
    }

    let handlePhone = (e) => {
        setPhone(e.target.value)
    }

    let handleCountry = (e) => {

        setCountry(e.value)
    }

    let handleState = (e) => {
        setState(e.value)
    }

    let handleLocal = (e) => {
        setLocal(e.target.value)
    }

    let checkSalm = () => {
        if (holocaustTypes == "Salmo") {
            return (
                <div class="mb-3">
                    <label>Número de Salmo</label>
                    {
                        getSongs(psalms)
                    }
                </div>

            )
        }
    }

    const checkIsAnotherHolocaustType = () => {
        if (holocaustTypes != "Salmo" && holocaustTypes != "Himno del Himnario General") {
            return (

                <div class="mb-3" id="titleSongSection">
                    <label>Título de Holocausto</label>
                    <input onChange={e => setHolocaustTitle(e.target.value)} type="text" class="form-control" required placeholder="Escriba aquí"></input>
                </div>
            )
        }
    }

    let checkSong = () => {
        if (holocaustTypes == "Himno del Himnario General") {
            return (
                <div class="mb-3">
                    <label>Número o nombre del Himno</label>
                    {
                        getSongs(songs)
                    }
                </div>

            )
        }
    }


    let loadStatesFromCountry = () => {

        switch (country) {
            case "Alemania":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={alemania} />
                    </div>

                )
                break;

            case "Argentina":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={argentina} />
                    </div>
                )
                break;

            case "Belice":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={belice} />
                    </div>
                )
                break;

            case "Bolivia":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={bolivia} />
                    </div>
                )
                break;

            case "Brasil":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={brasil} />
                    </div>
                )
                break;

            case "Canadá":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={canada} />
                    </div>
                )
                break;

            case "Chile":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={chile} />
                    </div>
                )
                break;

            case "Colombia":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={colombia} />
                    </div>
                )
                break;

            case "Costa Rica":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={costa_rica} />
                    </div>
                )
                break;

            case "Ecuador":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={ecuador} />
                    </div>
                )
                break;

            case "El Salvador":
                return (

                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={el_salvador} />
                    </div>
                )
                break;

            case "España":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={espana} />
                    </div>
                )
                break;

            case "Estados Unidos":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={eeuu} />
                    </div>
                )
                break;

            case "Francia":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={francia} />
                    </div>
                )
                break;


            case "Gibraltar":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={gibraltar} />
                    </div>
                )
                break;

            case "Guatemala":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={guatemala} />
                    </div>
                )
                break;

            case "Honduras":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={honduras} />
                    </div>
                )
                break;

            case "Israel":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={israel} />
                    </div>
                )
                break;

            case "Italia":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={italia} />
                    </div>
                )
                break;

            case "México":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={mexico} />
                    </div>
                )
                break;

            case "Panamá":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={panama} />
                    </div>
                )
                break;

            case "Paraguay":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={paraguay} />
                    </div>
                )
                break;

            case "Perú":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={peru} />
                    </div>
                )
                break;

            case "Portugal":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={portugal} />
                    </div>
                )
                break;

            case "Uruguay":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={uruguay} />
                    </div>
                )
                break;

            case "Venezuela":
                return (
                    <div class="mb-3">
                        <label>Estado</label>
                        <Select onChange={handleState} options={venezuela} />
                    </div>
                )
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        checkSalm();
        checkSong();
        loadStatesFromCountry();
        checkIsAnotherHolocaustType();
        checkIfGrupalParticipationIsAvailable()

    }, [])



    return (
        <Fragment>
            <div className='contenedor seccionCentrada'>
                <img src={LOGOIDI} className="logoIDI" alt="Logo ID(I)" />
                <div className="justify-content-center">
                    <div style={{ height: '30px' }}></div>
                    <div className='toCenter'>
                        <h1 className="titleTable">Registro de Holocaustos</h1>
                    </div>
                    <div style={{ height: '30px' }}></div>

                    <div className="shadow-table">
                        <form>
                            <div class="mb-3">
                                <label>Nombre de quien registra el Holocausto</label>
                                <input onChange={handleRegisterName} type="text" class="form-control" required></input>
                            </div>

                            <div class="mb-3">
                                <label>Apellido paterno</label>
                                <input onChange={handleRegisterFirstName} type="text" class="form-control" required></input>
                            </div>

                            <div class="mb-3">
                                <label>Apellido materno</label>
                                <input onChange={handleRegisterLastName} type="text" class="form-control" required></input>
                            </div>

                            <div class="mb-3">
                                <label>Correo electrónico de contacto</label>
                                <input type="email" onChange={handleRegisterEmail} class="form-control" placeholder="Escriba aquí" required></input>
                            </div>

                            <div class="mb-3">
                                <label>Número de celular</label>

                                <div className="input-group">
                                    <input onChange={handleCountryCode} type="number" class="form-control" placeholder="Código de país" required></input>
                                    <input onChange={handleLada} type="number" class="form-control" placeholder="Lada" required></input>
                                    <input onChange={handlePhone} type="number" class="form-control" placeholder="Celular" required></input>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label>¿De qué país eres?</label>
                                {
                                    getCountries()
                                }

                            </div>


                            {
                                loadStatesFromCountry()
                            }


                            <div class="mb-3">
                                <label>Localidad</label>
                                <input onChange={handleLocal} type="text" class="form-control" placeholder="Ej. Churubusco" required></input>
                            </div>

                            <div class="mb-3">
                                <label>Tipo de participación</label>
                                <select onChange={e => setParticipationTypes(e.target.value)} required class="custom-select">
                                    <option selected>Ninguna seleccionada</option>
                                    <option value="Individual">Individual</option>
                                    <option value="Grupal">Grupal</option>
                                </select>
                            </div>

                            {
                                checkIfGrupalParticipationIsAvailable()
                            }





                            <div class="mb-3">
                                <label>Tipo de Holocausto</label>
                                <select class="custom-select" onChange={handleHolocaustType} required>
                                    <option selected>Ninguno seleccionado</option>
                                    <option value="Himno del Himnario General">Himno del Himnario General</option>
                                    <option value="Himno del Himnario Local">Himno del Himnario Local</option>
                                    <option value="Salmo">Salmo</option>
                                    <option value="Canto">Canto</option>
                                    <option value="Texto de Memoria">Texto de Memoria</option>
                                    <option value="Poesía / Declamación">Poesía / Declamación</option>
                                    <option value="Diálogo">Diálogo</option>
                                    <option value="Número Especial">Número Especial</option>
                                </select>
                            </div>

                            {
                                checkSalm()
                            }


                            {
                                checkSong()
                            }

                            {
                                checkIsAnotherHolocaustType()
                            }

                            <div class="mb-3">
                                <div>
                                    <label>Duración</label>
                                </div>
                                <label style={{ fontSize: "15px", color: "#006279" }}>Arrastre el siguiente puntero para definir la duración de su holocausto</label>
                                <div>
                                    <input required defaultValue="1" onChange={getRangeInputHolocaustDuration} type="range" class="form-range" min="1" max="10" step="0.5" id="rangeHolocaust"></input>
                                </div>
                                <div>
                                    <label>Minutos: </label>
                                    <input disabled id="durationHolocaust" placeholder={durationSong}></input>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label>Seleccione el día en que quiere que se presente su holocausto</label>
                                <div>
                                    <label style={{ fontSize: "15px", color: "#006279" }}>Sujeto a la disponibilidad*</label>
                                </div>
                                <input onChange={handleDatePresent} type="date" class="form-control" placeholder="Escriba aquí" required></input>
                            </div>


                            <div class="custom-file mb-3" id="customFile" lang="es">
                                <Uploader nameVideo={registerName + "_" + registerFirstName + "_" + registerLastName + "_" + holocaustTitle}></Uploader>
                            </div>

                            <div class="input-group mb-3 centerArea">
                                <label>Comentarios o indicaciones especiales referente al holocausto a presentar</label>
                                <div>
                                    <textarea onChange={handleComments} id="areaComment" class="form-control col-xs-12" rows="7" cols="50" required aria-label="With textarea"></textarea>
                                </div>
                            </div>

                            <center>
                                <button type="submit" class="btn botonChidoBlue" onClick={handlerSubmit}>Registrar</button>
                            </center>


                        </form>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default HolocaustForm;