import React, { Component, Fragment, useEffect, useState } from "react";
import "../App.css";
import "../Navigation.css";
import { Link } from "react-router-dom";
import LOGOIDI from "../assets/icons/escudoblanco.png";


import LOGOYOUTUBE from '../assets/icons/youtubeconsombra.png';
import LOGOFACEBOOK from '../assets/icons/facebookconsombra.png';

import iconPhone from "../assets/icons/mPhone.png";
import iconEmail from "../assets/icons/email.png";
import PostList from "./PostList";
import chatingSVG from "../assets/images/undraw_mail_2_tqip.png";


/** Config. Firebase */

import app from "../firebase";
const db = app.firestore();



const Landpage = () => {
  const [videolink, setVideoLink] = useState([]);
  const getVideo = async () => {
    await db
      .collection("video")
      .orderBy("date", "desc")
      .limit(1)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setVideoLink(docs);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getVideo();
  }, []);

  return (
    <Fragment>
      {/**Hero */}
      <div className="heroPage">
        <img src={LOGOIDI} className="logoIDI" alt="Logo ID(I)" />
      </div>

      {/**Video online*/}
      <div className="contentMain" style={{ textAlign: "center" }}>
        <div id="servicesSection" className="justify-content-center">
          <div style={{ textAlign: "center" }}>
            <div className="mb-2 p-4">
              <button className="custom-btn btn-5">
                <span>Transmisión en vivo</span>
              </button>
            </div>
            {videolink.map((video) => (
              <iframe
                className="onlineVideo"
                width="560"
                height="315"
                src={video.link}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ))}
          </div>

          <div className="mb-2 p-4 centerContet">


            <Fragment>
              <div

                style={{
                  justifyContent: 'center',
                  backgroundSize: 'cover',
                  width: "300px",
                  height: "200px !important",
                  backgroundPosition: 'center',
                  backgroundImage: `linear-gradient(
                    325deg,
                    rgba(0, 36, 51, 1) 0%,
                    rgba(98, 218, 255, 0.164) 100%
                    ),url(https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.es%2Ffotos-premium%2Fvino-bodas-fondo-rojo-pascua-pan-fiesta-judio-matzoh-sobre-tabla-madera_4036240.htm&psig=AOvVaw1zisbqfzASdRf9K8ljrOlb&ust=1649821529103000&source=images&cd=vfe&ved=0CAoQjRxqFwoTCKDnu6bOjfcCFQAAAAAdAAAAABAN)`
                }} className="cardNote">

                <div style={{ height: '20px' }}></div>
                <p style={{ fontSize: '30px', fontWeight: '700', color: '#fff' }}>Pascua 2022</p>
                <div style={{ height: '40px' }}></div>
                <a style={{ paddingTop: '10px', color: "#fff", fontWeight: "bold" }} className='btn btn-warning' href="/holocausto">Registra aquí tu Holocausto</a>
                <div style={{ height: '40px' }}></div>
              </div>
            </Fragment>

            <div className="mb-2 p-4">
              <button className="custom-btn btn-5">
                <span>Chat TV Churubusco</span>
              </button>
            </div>
            <div className="contactCard">
              <img src={chatingSVG} className="iconoChat" alt="Icono de chat" />
              <h1 className="contactUs">Contáctanos</h1>
              <Link to="/filter" className="botonLlamar">
                Click aqui para interactuar
              </Link>
            </div>
          </div>
        </div>


        <article className="containerPosts">

          <center>
            <h3 style={{ color: '#fff', fontWeight: '700' }}>Calendario de eventos</h3>
          </center>

          <PostList></PostList>
        </article>

        <article style={{ height: '50px' }}>
          <h3 style={{ color: '#fff', fontWeight: '700' }}>Visítanos en</h3>
        </article>
        <section style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <div>
            <div>
              <a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/user/TVChurubuscoIDI"><img className='logoIDI' src={LOGOYOUTUBE} /></a>
              <span style={{ color: '#fff', fontWeight: '700' }}>
                <a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/user/TVChurubuscoIDI">Youtube</a>
              </span>
            </div>
          </div>
          <div>
            <div>

              <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/TVChurubuscoIDI"> <img className='logoIDI' src={LOGOFACEBOOK} /></a>
              <span style={{ color: '#fff', fontWeight: '700' }}>
                <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/TVChurubuscoIDI">Facebook</a>
              </span>
            </div>
          </div>
        </section>


        <h1 className="display-4 m-4" style={{ fontSize: 30, color: '#fff', fontWeight: '700' }}>Ubicación y contacto</h1>
        <div className="locationSection">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d941.1029285526936!2d-99.1478337708434!3d19.351317664521833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ffcd65d6d171%3A0x47fded1030166730!2sIDI%20CHURUBUSCO!5e0!3m2!1ses-419!2smx!4v1603465733083!5m2!1ses-419!2smx"
            className="ubicacionMapa"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>


          <div className="dataContact">
            <p className="mt-4">
              <strong>
                <h2>Ubicación:</h2>
              </strong>
              <p className="m-2" style={{ fontSize: "20px" }}>
                9 Revilla y Pedregon, Ciudad de México
              </p>
            </p>
            <p className="mt-4">
              <strong>
                <h2>Contacto:</h2>
              </strong>
              <div>
                <img src={iconPhone} style={{ height: "28px" }} alt="phone" />{" "}
                &nbsp;
                <span style={{ fontSize: "20px" }}>
                  5563958430
                </span>
              </div>

              <a style={{ display: 'flex' }} href="mailto:contacto@idisraelitadigital.com">
                <img src={iconEmail} style={{ height: "28px" }} alt="phone" />{" "}
                &nbsp;&nbsp;
                <span style={{ fontSize: "18px" }}>
                  contacto@idisraelitadigital.com
                </span>
              </a>
            </p>
          </div>
        </div>
        <footer className="justify-content-center">
          <div className="m-4">
            <img src={LOGOIDI} className="logoIDI" alt="Logo ID(I)" />
            <strong>
              <h1 style={{ color: "#fff" }}>¡Paz a vosotros!</h1>
            </strong>
          </div>
          <div className="row justify-content-center">
            <br></br>
            <h1 style={{ color: "#AAAAAA" }} className="legalsLink">
              <a
                style={{ color: "#AAAAAA" }}
                href="https://www.desarrollalab.com"
                className="firma"
              >
                &nbsp;Diseñado, Desarrollado y Soportado por Desarrollalab
              </a>
            </h1>
          </div>
        </footer>
        <br></br>
        <br></br>
      </div>
    </Fragment>
  );
};

export default Landpage;
