/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

//import sendWhatsApp

import { Modal, Button } from 'react-bootstrap';

/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'
import CardService from './CardService';
import ButtonCardService from './ButtonCardService';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const Main = () => {

    return (
        <Fragment>
            <div className='contenedor seccionCentrada'>
                <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                <div className="justify-content-center">
                    <center>
                        <button className="mb-4 custom-btn btn-5">
                            <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>Programación de culto</span>
                        </button>
                    </center>



                    <div className="services">
                        <ButtonCardService url='https://videohive.img.customer.envatousercontent.com/files/248489623/flight_in_time_Image_00000.jpg?auto=compress%2Cformat&fit=crop&crop=top&max-h=8000&max-w=590&s=737366013023ee5ffbe6d4def0d23144' titleCard={'Mostrar anteriores'} view={'/admin/cultos/anteriores'} />
                        <ButtonCardService url='http://pastorwillgraham.com/wp-content/uploads/2020/01/pw-1024x513.png' titleCard={'Crear nuevo culto'} view={'/admin/new-worship'} />
                        <ButtonCardService url='https://bergen.edu/wp-content/uploads/Academic-Calendar-header.jpg' titleCard={'Mostrar próximos'} view={'/admin/cultos/proximos'} />
                        <ButtonCardService url='https://bergen.edu/wp-content/uploads/Academic-Calendar-header.jpghttps://www.reactivoz.com/wp-content/uploads/2021/05/hillsong-worship2-.jpg' titleCard={'Holocaustos'} view={'/admin/holocaust/all'} />
                    </div>




                </div>
            </div>
        </Fragment>
    )
}

export default Main;