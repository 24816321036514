/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Fade } from '@material-ui/core';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

//import sendWhatsApp

import { Modal, Button } from 'react-bootstrap';

/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'
import CardService from './CardService';
import ButtonCardService from './ButtonCardService';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const AsignacionCultoRecibimiento = () => {



    //Obtiene la data de un localstorage
    /*
     const getUserData = async () => {
         let ticket = await localStorage.getItem('@ticket')
         ticket = JSON.parse(ticket);
         setTicket(ticket)
     }
    */


    // Agrega un item a un objeto -- objAux['url'] = downloadURL

    const handleWorshipConfiguration = async (e) => {

        e.preventDefault()

        let worshipAux = await localStorage.getItem('@preWorship')
        worshipAux = JSON.parse(worshipAux);

        worshipAux["superIntendente"] = superIntendente;
        worshipAux["SuperintendenteRespaldo"] = SuperintendenteRespaldo;
        worshipAux["SuperintendenteRespaldo"] = SuperintendenteRespaldo;
        worshipAux["oracionInicial"] = oracionInicial;
        worshipAux["lecturaBiblica"] = lecturaBiblica;
        worshipAux["lecturaPreviaLey"] = lecturaPreviaLey;
        worshipAux["lecturaLey"] = lecturaLey;
        worshipAux["oracionPredicacion"] = oracionPredicacion;
        worshipAux["predicacion"] = predicacion;
        worshipAux["avisos"] = avisos;
        worshipAux["oracionFinal"] = oracionFinal;
        worshipAux["bendicionDeDios"] = bendicionDeDios;

        // Agrega un item a un objeto -- objAux['url'] = downloadURL

        db.collection('cultos').add(worshipAux)
        Swal.fire(
            'Culto creado',
            'El culto está disponible en: Mostrar próximos',
            'success'
        ).then(() => {
            window.location.href = "/admin/main";
        })

    }


    //Creando los states

    const [worshipAux, setWorshipAux] = useState('');

    const [superIntendente, setSuperIntendente] = useState('');
    const [SuperintendenteRespaldo, setSuperIntendenteRespaldo] = useState('');
    const [oracionInicial, setOracionInicial] = useState('');
    const [lecturaBiblica, setLecturaBiblica] = useState('');
    const [lecturaPreviaLey, setLecturaPreviaLey] = useState('');
    const [lecturaLey, setLecturaLey] = useState('');
    const [oracionPredicacion, setOracionPredicacion] = useState('');
    const [predicacion, setPredicacion] = useState('');
    const [avisos, setAvisos] = useState('');
    const [oracionFinal, setOracionFinal] = useState('');
    const [bendicionDeDios, setBendicionDeDios] = useState('');

    //Creando los handlers

    const handleSuperIntendente = (e) => {
        setSuperIntendente(e.target.value)
    }

    const handleSuperIntendenteRespaldo = (e) => {
        setSuperIntendenteRespaldo(e.target.value)
    }

    const handleOracionInicial = (e) => {
        setOracionInicial(e.target.value)
    }

    const handleLecturaPreviaLey = (e) => {
        setLecturaPreviaLey(e.target.value)
    }

    const handleLecturaLey = (e) => {
        setLecturaLey(e.target.value)
    }

    
    const handleOracionPredicacion = (e) => {
        setOracionPredicacion(e.target.value)
    }

    const handlePredicacion = (e) => {
        setPredicacion(e.target.value)
    }


    const handleAvisos = (e) => {
        setAvisos(e.target.value)
    }

    const handleOracionFinal = (e) => {
        setOracionFinal(e.target.value)
    }

    const handleBendicionDeDios = (e) => {
        setBendicionDeDios(e.target.value)
    }




    return (

        <Fade in="true">
            <Fragment>
                <div className='contenedor seccionCentrada'>
                    <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                    <div className="justify-content-center">
                        <center>
                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>Asignación de Culto de Recibimiento</span>
                            </button>
                        </center>

                        <div className="shadow-table">
                            <form>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Superintendente (Principal)</label>
                                    <input type="text" onChange={handleSuperIntendente} required className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Oración Inicial</label>
                                    <input type="text" required onChange={handleOracionInicial} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Lectura Bíblica Previa a la Ley</label>
                                    <input type="text" required onChange={handleLecturaPreviaLey} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <span id="inputGroup-sizing-default">Lectura De La Ley</span>
                                    <input type="text" required onChange={handleLecturaLey} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>



                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Oración Para la Predicación</label>
                                    <input type="text" required onChange={handleOracionPredicacion} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Predicación</label>
                                    <input type="text" required onChange={handlePredicacion} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Avisos</label>
                                    <input type="text" required onChange={handleAvisos} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Oración Final</label>
                                    <input type="text" required onChange={handleOracionFinal} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <div className="mb-3">
                                    <label id="inputGroup-sizing-default">Bendición de Dios</label>
                                    <input type="text" required onChange={handleBendicionDeDios} className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default"></input>
                                </div>

                                <center>
                                    <button className="btn botonChidoBlue" onClick={handleWorshipConfiguration} >Crear culto</button>
                                </center>
                            </form>


                        </div>
                    </div>
                </div>
            </Fragment>
        </Fade>

    )
}

export default AsignacionCultoRecibimiento;