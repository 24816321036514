import React, { useState, Fragment } from 'react';

/**Transitions  */

import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';
import LOGOIDI from "../../assets/icons/escudoblanco.png";
import { Link } from 'react-router-dom';

import moment from 'moment'
import { useEffect } from 'react';

function PrayRequest() {



    const [reason, setReason] = useState([
        "Agradecimientos",
        "Petición General",
        "Petición de Salud",
        "Petición de Salud por COVID-19"
    ]);

 



    const [reasonPray, setReasonPray] = useState('');


    const handleSelctChange = (e) => {
        if (reason[e.target.value] === undefined) {
            setView('/prayRequest')
            setReasonPray('')
        } else {
            setReasonPray(reason[e.target.value])
            setView('/formContact')
        }

    }

    const [view, setView] = useState('/prayRequest')



    const validateSelect = async () => {
        if (reasonPray == '') {

            let alerta = document.createElement("div");
            alerta.className = "AlertError";
            let texto = document.createTextNode("Selecciona una opción");

            alerta.appendChild(texto);

            document.querySelector("#areaAlerta").appendChild(alerta);

            setTimeout(() => {
                alerta.remove();
            }, 2000);
        } else {
            setView('/formContact')
            await nextStep()
        }
    }

    let nextStep = async () => {
        let ticket = await localStorage.getItem('@ticket');
        ticket = JSON.parse(ticket)

        const ticketObj = {
            asunto: {
                titulo: ticket.asunto.titulo,
                categoria: reasonPray,
                contenido: ''
            },
            usuario: {
                nombreCompleto: ticket.usuario.nombreCompleto,
                localidad: '',
                denominacion: ticket.usuario.denominacion,
                correoElectronico: '',
                pregunta: '',
                respuesta: '',
                mensaje: ''
            },
            externo: ticket.externo
        }

        setView('/formContact')
        await localStorage.setItem('@ticket', JSON.stringify(ticketObj));
    }

    const reasons = reason.map(reasons => reasons);

    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                    <img src={LOGOIDI} className="logoIDI" alt="Logo ID(I)" />
                        <div className="justify-content-center">

                            <div className='toCenter'>
                                <h4 className='blank-text'>¿Cuál es el motivo de la oración?</h4>
                                <h6 className='m-2 blank-text'>Seleccione una opción</h6>
                                <div className="input-group">
                                    <select onChange={handleSelctChange} className="m-2 custom-select" id="inputGroupSelect04">
                                        <option value="">Ninguna seleccionada</option>
                                        {
                                            reason.map((reason, key) => <option key={key} value={key}>{reason}</option>)
                                        }
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div id="areaAlerta"></div>
                        <div className='gruopButons'>
                            <Link className='botonLlamar' onClick={validateSelect} to={view}>Continuar</Link>
                        </div>

                    </div>








                </Fragment>
            </Paper>
        </Fade>
    );

}

export default PrayRequest;