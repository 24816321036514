import React, { useState, Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'


/**Transitions  */


import Loader from 'react-loader-spinner'


import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';
import Swal from 'sweetalert2';


/** Config. Firebase */

import app from '../../firebase';
const db = app.firestore();



const GreetingsList = () => {

    const [isLoading, setIsLoading] = useState(true);





    const [greetings, setGreetings] = useState([]);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const getGreetings = async () => {



        db.collection('greetings')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setGreetings(docs)
            });
        setIsLoading(false);


    }


    const resolveItem = async (id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success m-2',
                cancelButton: 'btn btn-danger m-2'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Deseas resolver este registro?',
            text: "Este registro sera eliminado",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Resolver',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {

                db.collection('greetings').doc(id).delete();

                Swal.fire(
                    'Registro resuelto',
                    'Se ha resuelto este registro',
                    'success'
                )
            }
        })





    }



    const createTable = () => {
        if (isLoading) {
            return (
                <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={100}
                    width={100}

                />
            )
        } else {
            return (

                <div className="table shadow-table table-responsive table-bordered" style={{ width: '80%' }}>



                    <table className="table" id="ordersTable">
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <th scope="col">De parte de</th>
                                <th scope="col">Tipo de usuario</th>
                                <th scope="col">Denominación</th>
                                <th scope="col">Localidad</th>
                                <th scope="col">Mensaje</th>
                                <th scope="col">Q&A</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                greetings.map((greeting) => (
                                    <tr>
                                        <td style={{ textAlign: "center" }}>{greeting.usuario.nombreCompleto}</td>
                                        <td style={{ textAlign: "center" }}>{greeting.externo}</td>
                                        <td style={{ textAlign: "center" }}>{greeting.usuario.denominacion}</td>
                                        <td style={{ textAlign: "center" }}>{greeting.usuario.localidad}</td>
                                        <td style={{ textAlign: "center" }}>{greeting.asunto.contenido}</td>
                                        <td style={{ textAlign: "center" }}> <strong>Pregunta:</strong> {greeting.usuario.pregunta} <strong>Respuesta:</strong> {greeting.usuario.respuesta}</td>
                                        <td style={{ textAlign: "center" }}>
                                            <button className='btn btn-warning' onClick={() => resolveItem(greeting.id)}>Resolver</button>
                                        </td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>

            )
        }
    }


    useEffect(() => {
        getGreetings()
        createTable()
    }, [])


    /** Verifica si hay usuario*/
    const { currentUser } = useContext(AuthContext);
    if (!currentUser) {
        return <Redirect to='/login' />
    }



    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                        <div className="justify-content-center">
                            <div style={{ height: '30px' }}></div>

                            <div className='toCenter'>
                                <h3 style={{ fontWeight: '700', color: '#fff' }}>Listado de  Saludos</h3>
                            </div>
                        </div>

                        <div style={{ height: '35px' }}></div>

                        {
                            createTable()
                        }


                    </div>
                </Fragment>
            </Paper>
        </Fade>
    );

}

export default GreetingsList;