/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Fade } from '@material-ui/core';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'
import Loader from 'react-loader-spinner'

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


import moment from 'moment'

//import icons

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import WorshipConfiguration from '../worshipTemplates/WorshipConfiguration'

/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const HistorialCultosNuevos = () => {



    //Obtiene la data de un localstorage
    /*
     const getUserData = async () => {
         let ticket = await localStorage.getItem('@ticket')
         ticket = JSON.parse(ticket);
         setTicket(ticket)
     }
    */


    // Agrega un item a un objeto -- objAux['url'] = downloadURL



    //Creando los states

    const [worshipList, setWorshipList] = useState('');
    const [alabanzas, setAlabanzas] = useState('');


    const getWorships = () => {

        db.collection('cultos').orderBy('worshipDate', 'asc')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setWorshipList(docs)
            });
    }


    const [alabanzaApertura, setAlabanzaApertura] = useState('');
    const [alabanza, setAlabanza] = useState('');
    const [alabanzaPredicacion, setAlabanzaPredicacion] = useState('');
    const [alabanzaAlusiva, setAlabanzaAlusiva] = useState('');
    const [bienvenidas, setBienvenidas] = useState('');
    const [alabanzaDespedida, setAlabanzaDespedida] = useState('');


    // configurando handlers

    const handleAlabanzaApertura = (e) => {
        setAlabanzaApertura(e.target.value);
    }

    const handleAlabanza = (e) => {
        setAlabanza(e.target.value);
    }

    const handleAlabanzaPredicacion = (e) => {
        setAlabanzaPredicacion(e.target.value);
    }

    const handleAlabanzaAlusiva = (e) => {
        setAlabanzaAlusiva(e.target.value);
    }

    const handleBienvenidas = (e) => {
        setBienvenidas(e.target.value);
    }

    const handleAlabanzaDespedida = (e) => {
        setAlabanzaDespedida(e.target.value);
    }

    //Obtiene la data de un localstorage






    const resolveItem = async (id) => {

        await db.collection('cultos').doc(id).get().then((currentWorship) => {
            localStorage.setItem('@preWorship', JSON.stringify(currentWorship.data()));
            localStorage.setItem('@currentId', JSON.stringify(currentWorship.id));
        }).then(() => {
            window.location.href = "/admin/culto/visualizar"
        })

    }

    const finalizeWorship = (worship) => {

        if (window.confirm("¿Eliminar culto?")) {
            db.collection("cultos").doc(worship.id).delete().then(() => {
                Swal.fire({
                    title: "<i className='m-4'>Culto eliminado</i>",
                    html: "<span className='m-4'>Este culto no pertenecerá al histórico</span>",
                    confirmButtonColor: "#006279",
                    confirmButtonText: "Aceptar",
                    icon: "success"
                })
            })
        }

    }



    const createTable = () => {
        if (worshipList.length == 0) {
            return (
                <>
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}

                    />
                    <h6 className="m-4" style={{ color: "#fff" }}>Buscando registros</h6>
                </>

            )
        } else {
            return (

                <div className="table shadow-table table-responsive table-bordered">
                    <table className="table " id="ordersTable">
                        <thead>
                            <tr style={{ textAlign: "center" }}>
                                <th scope="col">Ver</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Super intendente</th>
                                <th scope="col">Lectura previa a la ley</th>
                                <th scope="col">Expositor de Escuela Sabática</th>
                                <th scope="col">Lección Escuela Sabática</th>
                                <th scope="col">Lección Infantíl</th>
                                <th scope="col">Predicador</th>
                                <th scope="col">Tema Predicación</th>
                                <th scope="col">Alabanzas</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>


                            {

                                worshipList.map((worship) => (
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            <button className='btn' onClick={() => resolveItem(worship.id)}>
                                                <FontAwesomeIcon color="#00BFFF" icon={faEye} />
                                            </button>
                                        </td>
                                        <td style={{ textAlign: "center" }}>{worship.worshipDate}</td>
                                        <td style={{ textAlign: "center" }}>{worship.worshipType}</td>
                                        <td style={{ textAlign: "center" }}>{worship.superIntendente}</td>
                                        <td style={{ textAlign: "center" }}>{worship.lecturaPreviaLey == undefined ? "NA" : worship.lecturaPreviaLey}</td>
                                        <td style={{ textAlign: "center" }}>{worship.escuelaSabatica == undefined ? "NA" : worship.escuelaSabatica}</td>
                                        <td style={{ textAlign: "center" }}>{worship.tituloLeccionSabatica == undefined ? "NA" : worship.tituloLeccionSabatica}</td>
                                        <td style={{ textAlign: "center" }}>{worship.leccionInfantil == undefined ? "NA" : worship.leccionInfantil}</td>
                                        <td style={{ textAlign: "center" }}>{worship.predicacion}</td>
                                        <td style={{ textAlign: "center" }}>{worship.tituloPredicacion == undefined ? "NA" : worship.tituloPredicacion}</td>
                                        <td style={{ textAlign: "center" }}>{worship.alabanzaApertura + "," + worship.alabanza + ", " + worship.alabanzaPosteriorLey + ", " + worship.alabanzaAlusiva + ", "
                                            + worship.alabanzaPredicacion + ", " + worship.alabanzaDespedida}
                                            <br></br>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <button type="button" class="btn btn-outline-danger" onClick={() => finalizeWorship(worship)}>Eliminar</button>
                                        </td>


                                    </tr>
                                ))
                            }





                        </tbody>
                    </table>

                </div>

            )
        }

    }

    useEffect(() => {
        createTable()
        getWorships()
    }, [])


    return (

        <Fade in="true">
            <Fragment>
                <div className='contenedor seccionCentrada'>
                    <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                    <div className="justify-content-center">
                        <center>
                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>Consulta Histórica de Cultos</span>
                            </button>

                            {
                                createTable()
                            }
                        </center>
                    </div>
                </div>
            </Fragment>
        </Fade>

    )
}

export default HistorialCultosNuevos;