import React, { useState, useEffect } from 'react'

import Swal from 'sweetalert2';

import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';


/** Config. Firebase */

import app from '../../firebase';
const db = app.firestore();



const EventForm = (props) => {



    const intialStateValues = {
        url: '',
        name: '',
        description: '',
        dateEvent: ''
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const [values, setValues] = useState(intialStateValues);

    const handleInputChange = e => {
        const { name, value } = e.target;
        console.log(name, value);
        setValues({ ...values, [name]: value });
    }




    const validateURL = str => {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(str);
    }

    const handlerSubmit = e => {
        e.preventDefault();
        if (!validateURL(values.url)) {

            Toast.fire({
                icon: 'error',
                type: 'warning',
                background: "#fff",
                title: "Enlace inválido"
            })
        } else {
            props.addOrEditLink(values);
            setValues({ ...intialStateValues })
        }


    }


    const getLinkById = async (id) => {
        const doc = await db.collection('posts').doc(id).get();
        setValues({ ...doc.data() })
    }

    useEffect(() => {
        if (props.currentId === '') {
            setValues({ ...intialStateValues });
        } else {
            getLinkById(props.currentId);
        }
    }, [props.currentId])

    return (

        <Fade in={true}>
            <Paper elevation={0}>
            


                <div classNam='transmition'>
                        <div className="justify-content-center">
                            <div style={{height:'30px'}}></div>
                            <div className='toCenter'>
                                <p style={{color:'#6D6D6D'}}>Rellene el formulario para crear un evento</p>
                            </div>
                        </div>

                        <div style={{ height: '35px' }}></div>

                      
                        <form className='card card-body' onSubmit={handlerSubmit}>
                        <div className='form-group input-group mb-2'>
                            <div className='input-group-text bg-light'>
                                <i className="material-icons">insert_link</i>
                            </div>
                            <input placeholder='Enlace de imagen' onChange={handleInputChange} value={values.url} name='url' type="text" className='form-control' />
                        </div>


                        <div className="form-group input-group mb-2">
                            <div className="input-group-text bg-light">
                                <i className='material-icons'>create</i>
                            </div>
                            <input placeholder='Nombre del evento' onChange={handleInputChange} value={values.name} type="text" className='form-control' name="name" id="" />
                        </div>

                        <div className="form-group mb-2">
                            <textarea onChange={handleInputChange} value={values.description} name="description"
                                cols="30" placeholder='Descripción del evento' rows="3"
                                className='form-control mb-2'></textarea>
                        </div>

                        <div className="form-group mb-2">
                            <span>Fecha del evento</span>
                            <input onChange={handleInputChange} value={values.dateEvent} className="form-control mb-2" type="date" name="dateEvent" />
                        </div>

                        <button className='btn btn-primary btn-block'>
                            {props.currentId === '' ? 'Publicar' : 'Actualizar información'}
                        </button>
                    </form>

                    </div>

            </Paper>
        </Fade>
    )
}

export default EventForm;