/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Fade } from '@material-ui/core';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

import moment from 'moment'

//import emailjs

import emailjs from 'emailjs-com';


import Loader from 'react-loader-spinner'


/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faWindowClose, faCheckSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'




const HolocaustList = () => {


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500
    });

    const db = app.firestore();




    const [holocaustList, setHolocaustList] = useState([])

    const getAllHolocaust = () => {

        //     let cantosAux = worship["cantos"];

        db.collection('holocaustos').orderBy('datePresent', 'desc')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                    console.log(doc.id)
                });
                setHolocaustList(docs)

            });

    }

    const getNameGroupOrFamily = (currentHolocaust) => {
        switch (currentHolocaust.participationTypes) {
            case "Individual":
                return currentHolocaust.registerName + "" + currentHolocaust.registerFirstName + " " + currentHolocaust.registerLastName
                break;

            case "Grupal":
                return currentHolocaust.groupName + " / " + currentHolocaust.holocaustTypes
                break;

            case "Familia":
                return currentHolocaust.groupName + " / " + currentHolocaust.holocaustTypes
                break;
            default:
                break;
        }
    }


    const confirmHolocaust = (holocaust) => {


        if (window.confirm("¿Confirmar?")) {

            let datePresentConfirmed = prompt("El holocausto será presentado el: " + holocaust.datePresent + " confirme fecha");


            let hourConfirmed = prompt("Ingrese hora de presentación");



            db.collection("holocaustos").doc(holocaust.id).update({
                confirmado: true,
                datePresent: datePresentConfirmed,
                hourConfirmed: hourConfirmed
            }).then(() => {


                emailjs.send("churubusco_privatemail", "template_aceptacion", {
                    nombreRegistrante: holocaust.registerName + " " + holocaust.registerFirstName + " " + holocaust.registerLastName,
                    emailRegistrante: holocaust.registerEmail,
                    worshipDatePresent: datePresentConfirmed,
                    worshipStartTime: hourConfirmed
                }, "user_BU2y1xMxhazWdSDHcARB6").then(() => {

                    Swal.fire({
                        title: "<i className='m-4'>Holocausto confirmado</i>",
                        html: "<span className='m-4'>Se ha informado al registrante que se ha aceptado el holocausto</span>",
                        confirmButtonColor: "#006279",
                        confirmButtonText: "Aceptar",
                        icon: "success"
                    })
                })

            })
        } else {
            alert("El holocausto no fue confirmado")
        }
    }

    const rejectHolocaust = (holocaust) => {

        let razonRechazo = prompt("Ingrese motivo de eliminación de holocausto");

        let solucionRechazo = prompt("Ingrese solución");



        if (window.confirm("¿Rechazar?")) {


            db.collection("holocaustos").doc(holocaust.id).delete().then(() => {


                emailjs.send("churubusco_privatemail", "template_rechazo", {
                    nombreRegistrante: holocaust.registerName + " " + holocaust.registerFirstName + " " + holocaust.registerLastName,
                    emailRegistrante: holocaust.registerEmail,
                    motivoRechazo: razonRechazo,
                    solucionRechazo: solucionRechazo
                }, "user_BU2y1xMxhazWdSDHcARB6").then(() => {

                    Swal.fire({
                        title: "<i className='m-4'>Holocausto rechazado</i>",
                        html: "<span className='m-4'>Se ha informado al registrante que se ha rechazado el holocausto por: " + razonRechazo + "</span>",
                        confirmButtonColor: "#006279",
                        confirmButtonText: "Aceptar",
                        icon: "info"
                    })
                })

            })
        } else {
            alert("Holocausto no rechazado")
        }

    }


    const getHolocaust = () => {

        if (holocaustList.length == 0) {
            return (
                <center>
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}

                    />
                    <h6 className="m-4" style={{ color: "#fff" }}>Buscando registros</h6>
                </center>

            )
        } else {

            return (

                holocaustList.map((holocaust) => (
                    <tr>
                        <td>
                            {
                                holocaust.confirmado == false ? <>
                                    <span class="badge badge-dark ml-2">No confirmado</span>
                                </>
                                    :
                                    <>
                                        <span class="badge badge-primary ml-2">Confirmado</span>
                                    </>

                            }

                        </td>

                        <td className="d-flex">
                            <FontAwesomeIcon className="ml-4" onClick={() => confirmHolocaust(holocaust)} color="#5BF363" icon={faCheckSquare} />
                            <FontAwesomeIcon className="ml-4" onClick={() => rejectHolocaust(holocaust)} color="#D50000" icon={faTrash} />

                        </td>

                        <td>
                            <span>{holocaust.datePresent}</span>
                        </td>

                        <td>
                            <span>{holocaust.registerName + " " + holocaust.registerFirstName + " " + holocaust.registerLastName}</span>
                        </td>

                        <td>
                            <span>{holocaust.participationTypes}</span>
                        </td>

                        <td>
                            <span>{getNameGroupOrFamily(holocaust)}</span>
                        </td>
                        <td>
                            <span>{holocaust.local}</span>
                        </td>

                        <td>
                            <span>{holocaust.state}</span>
                        </td>

                        <td>
                            <span>{holocaust.titleHolocaust}</span>
                        </td>

                        <td>
                            <span>{holocaust.holocaustTypes}</span>
                        </td>

                        <td>
                            <span>{holocaust.commentHolocaust}</span>
                        </td>

                        <td>
                            <a href={holocaust.urlFile} target="_blank" className='btn'>
                                <FontAwesomeIcon color="#00608e" icon={faFileDownload} />
                            </a>
                        </td>
                        <td>
                            <a href={'https://api.whatsapp.com/send?phone=' + holocaust.countryCode + holocaust.lada + holocaust.phone + '&text="¡Paz a vos!'} className='btn'>
                                <FontAwesomeIcon color="#00608e" icon={faWhatsapp} />
                            </a>
                        </td>
                    </tr>
                ))


            )
        }
    }



    useEffect(() => {
        getHolocaust()
        getAllHolocaust()
    }, [])


    return (
        <Fade in="true">
            <Fragment>
                <div className='contenedor seccionCentrada'>
                    <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                    <div className="justify-content-center">
                        <center>
                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>Listado de Holocaustos</span>
                            </button>


                            <div className="shadow-table">
                                <table className="table table-bordered table-responsive">
                                    <thead>
                                        <tr>
                                            <th>Estado</th>
                                            <th>Acciones</th>
                                            <th>Fecha que escogió el usuario</th>
                                            <th>Registrante</th>
                                            <th>Tipo de participación</th>
                                            <th>Por</th>
                                            <th>Localidad</th>
                                            <th>Título</th>
                                            <th>Duración</th>
                                            <th>Tipo de holocausto</th>
                                            <th>Comentarios adicionales</th>
                                            <th>Archivo</th>
                                            <th>Whatsapp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getHolocaust()
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </center>
                    </div>
                </div>
            </Fragment>
        </Fade>

    )
}


export default HolocaustList;