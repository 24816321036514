/**React imports */
import React, { useCallback, useState, useContext } from 'react';

import { withRouter, Redirect } from 'react-router'
import logoColor from '../../assets/icons/LOGO1.png'


import Swal from 'sweetalert2'

/**Autenticacion */


import app from '../../firebase'

import { AuthContext } from '../Auth'




const Login = ({ history }) => {



    const [usuario, setUsuario] = useState('')
    const [password, setPassword] = useState('')




    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { user, password } = event.target.elements;
            try {
                await
                    app
                        .auth()
                        .signInWithEmailAndPassword(user.value, password.value)
            } catch (error) {
                Swal.fire(
                    'Autenticación fallida',
                    'Es posible que el usuario o la contraseña sean incorrectos',
                    'error'
                )
            }
        }, [history]
    );


    

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to='/admin/dashboard' />
    }


    return (

        <section style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', background: '#fff', alignItems: 'center', minHeight: '100vh' }}>
            <div className='loginCard'>
                <img src={logoColor} width='auto' height='100' alt="Logo Dra Edith Leal" srcset="" />

                <form onSubmit={handleLogin}>
                    <div style={{ textAlign: 'center' }}>

                        <span style={{ fontSize: '24px', textAlign: 'center' }} className="mb-4 EspecialistaEn">Inicio de sesión</span>
                        <div style={{ height: '10px' }}></div>

                        <div style={{ color: '#3896A7', background: '#EDF7F9', border: '1px solid #D3E8EC', width: '250px', fontSize: '14px' }} className="alert alert-success" role="alert">Inicia sesión para continuar</div>
                    </div>



                    <div className="pb-2">

                        <div style={{ height: '10px' }}></div>
                        <div>
                            <label style={{ color: '#727175' }} htmlFor="nameInput">Usuario</label>
                            <input name='user' id="nameInput" required onChange={(ev) => setUsuario(ev.target.value)} type="email" className="inputLogin form-control mb-2"></input>
                        </div>

                    </div>
                    <div className="pb-2">

                        <div>
                            <label style={{ color: '#727175' }} htmlFor="password">Contraseña</label>
                            <input name='password' type="password" required onChange={(ev) => setPassword(ev.target.value)} id="inputPassword" className="inputLogin form-control mb-2" ></input>
                        </div>

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100px' }}>
                        <button type='submit' id="btnContinuar" style={{ width: '250px' }} className="btn botonLlamar">Entrar</button>
                    </div>
                </form>
            </div>
        </section>


    );



}


export default Login;