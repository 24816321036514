import React, { Fragment, useState, useEffect } from 'react';
import Loader from 'react-loader-spinner'
import uuid from 'react-uuid'
import emailjs from 'emailjs-com';

import Swal from 'sweetalert2';

/**Transitions  */

import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';
import LOGOIDI from "../assets/icons/escudoblanco.png";

import { Link } from 'react-router-dom';

import app from "../firebase";
const db = app.firestore();

function FormContact() {



    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [ticket, setTicket] = useState('');

    const [sending, setSending] = useState(false);

    const handleMessageInputChange = e => {
        const { value } = e.target;

        if (e.target.value != "") {
            document.querySelector("#messageInput").className = 'form-control correctNumber';
        } else {
            document.querySelector("#messageInput").className = 'form-control invalidNumber';
        }
        setMessage(value);
    }

    const handleNameInputChange = e => {
        const { value } = e.target;

        var letters = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;
        if (e.target.value.match(letters) && e.target.value != "") {
            document.querySelector("#nameInput").className = 'form-control correctNumber';
        } else {
            document.querySelector("#nameInput").className = 'form-control invalidNumber';
        }
        setName(value);
    }

    const handleEmailInputChange = e => {
        const { value } = e.target;

        var mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value.match(mail) && e.target.value != "") {
            document.querySelector("#mailInput").className = 'form-control correctNumber';
            document.querySelector("#messageInput").disabled = false;

        } else {
            document.querySelector("#messageInput").disabled = true;

            document.querySelector("#mailInput").className = 'form-control invalidNumber';
        }

        setEmail(value)
    }

    const handleLocationInputChange = e => {
        const { value } = e.target;

        if (e.target.value != "") {
            document.querySelector("#locationInput").className = 'form-control correctNumber';
        } else {
            document.querySelector("#locationInput").className = 'form-control invalidNumber';
        }
        setLocation(value);
    }
    const handleAnswerInputChange = (e) => {

        const { value } = e.target;

        if (e.target.value != "") {
            document.querySelector("#answerInput").className = 'form-control correctNumber';
        } else {
            document.querySelector("#answerInput").className = 'form-control invalidNumber';
        }
        setAnswer(value)

    }


    const getAleatoryQuestion = () => {


        let questions = [
            '¿Según el Calendario Hebreo, en que fecha celebramos la fiesta de Pascua?',
            '¿Cuáles son las Solemnidades de la Iglesia?',
            '¿Cuánto tiempo Reinará Jesús?',
            '¿Cuáles son los 3 emblemas de la Pascua que practicamos en la Iglesia?',
            'Nombra 3 animales impuros para la alimentación',
            '¿Cuál es la condición de los muertos?'

        ];

        var aleatoyQuestion = questions[Math.floor(Math.random() * questions.length)];


        setQuestion(aleatoyQuestion);
    }



    const _validateInputs = () => {

        const letters = /^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g;

        switch (ticket.externo) {
            case 'Interno':



                if (name != "" && message != "" && location != '') {

                    if (name.match(letters) && name != "") {
                        sendRequest()
                    } else {
                        setView('/formContact');
                        Swal.fire({
                            position: 'center',
                            icon: 'info',
                            title: 'Datos incompletos',
                            html: `Considere lo siguiente
                                   <ul>
                                    <li>Los campos sólo pueden contener letras (a-z) y acentos (´)</li>
                                    <li>Evite ingresar números (0-9) y/o puntos (.)</li>
                                    <li>
                                    Los campos no pueden contener signos "&", signos igual (=), guiones bajos (_), apóstrofos ('), guiones (-), signos más (+), comas (,), corchetes angulares (<,>) o más de un punto (.) consecutivo.
                                    </li>
                                   </ul>
                            `,
                            text: 'Por favor ingresa la información faltante ',
                            showConfirmButton: false,
                            timer: 7000
                        })
                    }
                } else {
                    setView('/formContact');
                    Swal.fire({
                        position: 'center',
                        icon: 'info',
                        title: 'Datos incompletos',
                        html: `Considere lo siguiente
                        <ul>
                         <li>Los campos sólo pueden contener letras (a-z) y acentos (´)</li>
                         <li>Evite ingresar números (0-9) y/o puntos (.)</li>
                         <li>
                         Los campos no pueden contener signos "&", signos igual (=), guiones bajos (_), apóstrofos ('), guiones (-), signos más (+), comas (,), corchetes angulares (<,>) o más de un punto (.) consecutivo.
                         </li>
                        </ul>
                 `,
                        text: 'Por favor ingresa la información faltante',
                        showConfirmButton: false,
                        timer: 7000
                    })
                }
                break;
            case 'Externo': {
                if (message !== "") {
                    sendRequest()
                    setView('/formContact');
                } else {
                    setView('/formContact');
                    Swal.fire({
                        position: 'center',
                        icon: 'info',
                        title: 'Datos incompletos',
                        text: 'Por favor ingresa la información faltante',
                        showConfirmButton: false,
                        timer: 7000
                    })
                }
                break;
            }
            default:
                break;
        }
    }







    const getCategoryToInsert = (cat) => {

        switch (cat) {
            case 'Agradecimientos':

                return 'greetings';
            case 'Petición de Salud':

                return 'healt';
            case 'Petición General':

                return 'general';
            case 'Petición de Salud por COVID-19':

                return 'covidhealt';
            default:
                break;
        }
    }

    const getUserData = async () => {
        let ticket = await localStorage.getItem('@ticket')
        ticket = JSON.parse(ticket);
        setTicket(ticket)
    }

    useEffect(() => {
        getUserData()
        getAleatoryQuestion()
        verifyQuestion()
        changeButtonState();
    }, [])


    const convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }




    const getCollectiondDayNameToInsert = () => {

        var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
        var d = new Date();
        var dayName = days[d.getDay()]




        //out put ej. lunes

        // Obtener hora

        var date = new Date();
        var time12 = date.toLocaleTimeString();


        const hour24 = convertTime12to24(time12)

        const hourArray = hour24.split(':');

        let horaNumero = parseInt(hourArray[0] + hourArray[1]);

        switch (dayName) {
            case 'viernes': {
                if (horaNumero >= 2030) {
                    return 'sabadomatutino';
                } else {
                    return 'viernes';
                }

            }

            case 'sabado': {
                var date = new Date();
                var time12 = date.toLocaleTimeString();


                const hour24 = convertTime12to24(time12)

                const hourArray = hour24.split(':');

                if (parseInt(hourArray[0]) < 13) {
                    return 'sabadomatutino';
                } else if (parseInt(hourArray[0]) >= 13) {

                    const hora = parseInt(hourArray[0] + hourArray[1])
                    if (hora >= 1830) {
                        return 'viernes';
                        // console.log('insertar prox viernes')
                        //console.log(parseInt(hourArray[0] + hourArray[1]))
                    } else {
                        return 'sabadovespertino';
                        //console.log(' insertar en sabdo en la tarde');
                    }
                }
                break;
            }

            default:
                return 'viernes';
        }


    }

    const [view, setView] = useState('/formContact')
    const sendRequest = async () => {

        let ticket = await localStorage.getItem('@ticket');
        ticket = JSON.parse(ticket);
        setSending(true)
        var today = new Date();

        const ticketObj = {
            definer: uuid(),
            asunto: {
                titulo: ticket.asunto.titulo,
                categoria: ticket.asunto.categoria,
                contenido: message
            },
            date: today,
            usuario: {
                nombreCompleto: ticket.externo == "Interno" ? name : ticket.usuario.nombreCompleto,
                localidad: ticket.externo == "Interno" ? location : 'NA',
                denominacion: ticket.externo == "Interno" ? 'ID(I)' : ticket.usuario.denominacion,
                correoElectronico: email,
                pregunta: ticket.externo == "Externo" ? 'NA' : question,
                respuesta: ticket.externo == "Externo" ? 'NA' : answer,
                mensaje: message
            },
            externo: ticket.externo
        }


        try {


            switch (ticket.asunto.titulo) {
                case 'Petición de Oración': {

                    document.querySelector('#message').className = 'disabled'



                    await db.collection('prayrequest').doc("dias").collection(getCollectiondDayNameToInsert())
                        .doc('categorias').collection(getCategoryToInsert(ticket.asunto.categoria))
                        .add(ticketObj)
                    setSending(false)
                    window.location.href = '/accepted';
                    break;
                }
                case 'Hacer un comentario de La Escuela Sabática o Predicación': {
                    document.querySelector('#message').className = 'disabled'
                    await db.collection('comments').doc().set(ticketObj)
                    setSending(false)
                    window.location.href = '/accepted';
                    break;
                }
                case 'Hacer una pregunta referente a la Escuela Sabática o Predicación': {
                    document.querySelector('#message').className = 'disabled'
                    await db.collection('questions').doc().set(ticketObj)
                    window.location.href = '/accepted';
                    break;
                }
                case 'Enviar Saludos': {
                    document.querySelector('#message').className = 'disabled'
                    await db.collection('greetings').doc().set(ticketObj)
                    window.location.href = '/accepted';
                    break;
                }
                case 'Informar de una Bienvenida': {
                    document.querySelector('#message').className = 'disabled'
                    await db.collection('welcome').doc().set(ticketObj)
                    window.location.href = '/accepted';
                    break;
                }
                case 'Solicitar un Canto, Himno o Salmo': {
                    document.querySelector('#message').className = 'disabled'
                    await db.collection('soundrequest').doc().set(ticketObj)
                    window.location.href = '/accepted';
                    break;
                }
                case 'Solictar Informes': {
                    document.querySelector('#message').className = 'disabled';
                    await db.collection('generalInfoRequest').doc().set(ticketObj)

                    await emailjs.send("churubusco_privatemail", "template_22kj96j", {
                        nombreCompleto: ticketObj.usuario.nombreCompleto,
                        titulo: ticketObj.asunto.titulo,
                        categoria: ticketObj.asunto.categoria,
                        contenido: ticketObj.asunto.contenido,
                        localidad: ticketObj.usuario.localidad,
                        denominacion: ticketObj.usuario.denominacion,
                        correoElectronico: ticketObj.usuario.correoElectronico,
                        pregunta: ticketObj.usuario.pregunta,
                        respuesta: ticketObj.usuario.respuesta,
                    }, "user_BU2y1xMxhazWdSDHcARB6");

                    window.location.href = '/accepted';
                    break;
                }
                default: break;
            }


        } catch (error) {
            console.log(error)
        }


    }


    const changeButtonState = () => {
        if (sending) {
            return (
                <Fade in={true}>
                    <Paper elevation={0}>
                        <Fragment>
                            <div className='containerCenter'>
                                <div className="justify-content-center">

                                    <div className='toCenter'>

                                        <h4 className='titleApp'>Enviando</h4>
                                        <Loader type="Oval" color="#00BFFF" height={200} width={200} />

                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </Paper>
                </Fade>
            )
        }
    }

    const loadSections = () => {
        if (ticket.externo == "Externo" && ticket.asunto.titulo == 'Solictar Informes') {

            return (
                <section>
                    <h4 className='titleApp' style={{ color: '#fff', fontWeight: '600' }}>Datos de contacto</h4>

                    <div className="input m-2 inputData">
                        <h6 className='mb-2 blank-text'>Correo electrónico</h6>
                        <input id='mailInput' oninput="this.value=this.value.toLowerCase()" type="mail" onChange={handleEmailInputChange} className="form-control " placeholder="Ejemplo: josequeb@gmail.com" aria-label="Username" aria-describedby="basic-addon1"></input>
                    </div>
                </section>
            )
        } else if (ticket.externo == "Interno" && ticket.asunto.titulo == 'Solictar Informes') {

            return (
                <>
                    <section>

                        <h4 className='titleApp' style={{ color: '#fff', fontWeight: '600' }}>Datos de contacto</h4>

                        <div className="input m-2 inputData">
                            <h6 className='mb-2'>Correo electrónico</h6>
                            <input oninput="this.value=this.value.toLowerCase()" id='mailInput' type="mail" onChange={handleEmailInputChange} className="form-control " placeholder="Ejemplo: josequeb@gmail.com" aria-label="Username" aria-describedby="basic-addon1"></input>
                        </div>
                    </section>
                </>
            )

        }
    }



    const verifyQuestion = () => {
        if (ticket.externo == "Interno") {

            return (


                <section>
                    <h6 className='titleApp' style={{ color: '#fff', fontWeight: '600' }} >Información personal</h6>

                    <section>
                        <div className="input m-2 inputData">
                            <h6 className='mb-2 blank-text'>Nombre completo</h6>
                            <input id='nameInput' type="text" className="form-control" onChange={handleNameInputChange} placeholder="Ejemplo: José Enríquez Beltrán" aria-label="Username" aria-describedby="basic-addon1"></input>
                        </div>
                    </section>
                    <section>
                        <div className="input m-2 inputData">
                            <h6 className='mb-2 blank-text'>Localidad</h6>
                            <input id='locationInput' type="text" className="form-control" onChange={handleLocationInputChange} placeholder="Ejemplo: Churubusco" aria-label="Username" aria-describedby="basic-addon1"></input>
                        </div>
                    </section>
                    <div className="input m-2 inputData">
                        <h6 className='mb-2 blank-text'>Pregunta Aleatoria</h6>
                        <div className="alert alert-warning" style={{ maxWidth: '340px' }} role="alert">
                            {question}
                        </div>
                    </div>


                    <div className=" m-2 input-group" style={{ width: "340px" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text">Respuesta</span>
                        </div>
                        <textarea id='answerInput' className="form-control" onChange={handleAnswerInputChange} aria-label="With textarea"></textarea>
                    </div>
                </section>
            )
        }
    }




    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                        <img src={LOGOIDI} className="logoIDI" alt="Logo ID(I)" />
                        <div className="justify-content-center">
                            <div className='toCenter'>


                                <div className='m-4'></div>
                                {
                                    loadSections()
                                }

                                {
                                    verifyQuestion()
                                }

                                <section>
                                    <h6 className='titleApp' style={{ fontSize: '20px !important' }}>Mensaje</h6>

                                    <h6 className='mb-2 blank-text'>Por favor escriba aqui su mensaje</h6>
                                    <div className=" m-2 input-group" style={{ width: "340px" }}>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Mensaje</span>
                                        </div>
                                        <textarea id='messageInput' maxLength={200} onChange={handleMessageInputChange} className="form-control" aria-label="With textarea"></textarea>
                                    </div>
                                </section>
                            </div>
                        </div>


                        <div className='gruopButons'>
                            <Link id='message' className='botonLlamar' to={view} onClick={_validateInputs}>Enviar</Link>
                        </div>
                        <article style={{ height: '30px' }}></article>

                    </div>








                </Fragment>
            </Paper>
        </Fade>
    );

}

export default FormContact;