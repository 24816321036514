/**React imports */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Fade } from '@material-ui/core';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'

//import sendWhatsApp

import { Modal, Button } from 'react-bootstrap';

/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

/** LogoIDI */
import LOGOIDI from '../../assets/icons/escudoblanco.png'


import Swal from 'sweetalert2'
import CardService from './CardService';
import ButtonCardService from './ButtonCardService';


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();



const NewWorship = () => {


    let worshipList = [
        {
            "value": "Culto de Recibimiento",
            "label": "Culto de Recibimiento"
        },
        {
            "value": "Culto Matutino",
            "label": "Culto Matutino"
        },
        {
            "value": "Culto Vespertino",
            "label": "Culto Vespertino"
        },
        {
            "value": "Culto de Oración",
            "label": "Culto de Oración"
        },
        {
            "value": "Culto Pascua (Recibimiento)",
            "label": "Culto Pascua (Recibimiento)"
        },
        {
            "value": "Culto Pascua (Noche De Guardar)",
            "label": "Culto Pascua (Noche De Guardar)"
        },
        {
            "value": "Culto Pascua (Matutino)",
            "label": "Culto Pascua (Matutino)"
        },
        {
            "value": "Culto Pascua (Vespertino)",
            "label": "Culto Pascua (Vespertino)"
        },
        {
            "value": "Culto Pentecostés (Recibimiento)",
            "label": "Culto Pentecostés (Recibimiento)"
        },
        {
            "value": "Culto Pentecostés (Matutino)",
            "label": "Culto Pentecostés (Matutino)"
        },
        {
            "value": "Culto Pentecostés (Vespertino)",
            "label": "Culto Pentecostés (Vespertino)"
        },
        {
            "value": "Culto Cabañas Día 1 (Recibimiento)",
            "label": "Culto Cabañas Día 1 (Recibimiento)"
        },
        {
            "value": "Culto Cabañas Día 1 (Matutino)",
            "label": "Culto Cabañas Día 1 (Matutino)"
        },
        {
            "value": "Culto Cabañas (Fin Día 1 + Recibimiento Día 2)",
            "label": "Culto Cabañas (Fin Día 1 + Recibimiento Día 2)"
        },
        {
            "value": "Culto Cabañas (Fin Día 2 + Recibimiento Día 3)",
            "label": "Culto Cabañas (Fin Día 2 + Recibimiento Día 3)"
        },
        {
            "value": "Culto Cabañas (Fin Día 3 + Recibimiento Día 4)",
            "label": "Culto Cabañas (Fin Día 3 + Recibimiento Día 4)"
        },
        {
            "value": "Culto Cabañas (Fin Día 4 + Recibimiento Día 5)",
            "label": "Culto Cabañas (Fin Día 4 + Recibimiento Día 5)"
        },
        {
            "value": "Culto Cabañas (Fin Día 5 + Recibimiento Día 6)",
            "label": "Culto Cabañas (Fin Día 5 + Recibimiento Día 6)"
        },
        {
            "value": "Culto Cabañas (Fin Día 6 + Recibimiento Día 7)",
            "label": "Culto Cabañas (Fin Día 6 + Recibimiento Día 7)"
        },
        {
            "value": "Culto Cabañas (Fin Día 7 + Recibimiento Día 8)",
            "label": "Culto Cabañas (Fin Día 7 + Recibimiento Día 8)"
        },
        {
            "value": "Culto Cabañas Día 8 (Matutino)",
            "label": "Culto Cabañas Día 8 (Matutino)"
        },
        {
            "value": "Culto Cabañas Día 8 (Vespertino)",
            "label": "Culto Cabañas Día 8 (Vespertino)"
        },
        {
            "value": "Culto Ministerial",
            "label": "Culto Ministerial"
        },
        {
            "value": "Culto de Exaltaciones",
            "label": "Culto de Exaltaciones"
        },
        {
            "value": "Culto Luctuoso",
            "label": "Culto Luctuoso"
        },
        {
            "value": "Boda",
            "label": "Boda"
        }
    ]

    const [dateWorship, setDateWorship] = useState('');

    const [worship, setWorship] = useState('');

    const [startTime, setStartTime] = useState('');
    const [nombrePlantillaAsignacion, setNombrePlantillaAsignacion] = useState('');

    const getWorships = () => {
        return (
            <Select onChange={handleWorship} options={worshipList} />
        )
    }

    let handleWorship = (e) => {


        setWorship(e.value)

        switch (e.value) {
            case "Culto de Recibimiento":
                setNombrePlantillaAsignacion('culto/recibimiento');
                break;
            case "Culto Matutino":
                setNombrePlantillaAsignacion('culto/matutino');
                break;
            case "Culto Vespertino":
                setNombrePlantillaAsignacion('culto/vespertino');
                break;

            case "Culto Cabañas Día 1 (Recibimiento)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas Día 1 (Matutino)":
                
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas (Fin Día 1 + Recibimiento Día 2)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas (Fin Día 2 + Recibimiento Día 3)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas (Fin Día 3 + Recibimiento Día 4)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas (Fin Día 4 + Recibimiento Día 5)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas (Fin Día 5 + Recibimiento Día 6)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas (Fin Día 6 + Recibimiento Día 7)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas (Fin Día 7 + Recibimiento Día 8)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas Día 8 (Matutino)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Cabañas Día 8 (Vespertino)":
                setNombrePlantillaAsignacion('culto/cabanas/configurar');
                break;

            case "Culto Ministerial":
                alert("Espéralo muy pronto")
                break;

            case "Culto de Exaltaciones":
                alert("Espéralo muy pronto")
                break;

            case "Culto Luctuoso":
                alert("Espéralo muy pronto")
                break;

            case "Boda":
                alert("Espéralo muy pronto")
                break;

            default:
                alert("Error tipo de culto no disponible");
                break;
        }
    }



    let handleDateWorship = (e) => {
        setDateWorship(e.target.value)
    }

    let handleStartTime = (e) => {
        setStartTime(e.target.value)
    }

    let handleStorageWorship = async () => {

        let worshipObject = {
            worshipType: worship,
            worshipDate: dateWorship,
            startTime: startTime

        }

        // Agrega un item a un objeto -- objAux['url'] = downloadURL
        await localStorage.setItem('@preWorship', JSON.stringify(worshipObject));

    }


    //Obtiene la data de un localstorage
    /*
     const getUserData = async () => {
         let ticket = await localStorage.getItem('@ticket')
         ticket = JSON.parse(ticket);
         setTicket(ticket)
     }
    */
    return (

        <Fade in="true">
            <Fragment>
                <div className='contenedor seccionCentrada'>
                    <img src={LOGOIDI} width="200px" alt="Logo ID(I)" />
                    <div className="justify-content-center">
                        <center>
                            <button className="mb-4 custom-btn btn-5">
                                <span style={{ color: '#fff', textAlign: "center", fontSize: "30px !important" }}>Programación de nuevo culto</span>
                            </button>
                        </center>

                        <div className="shadow-table">
                            <form>
                                <div class="mb-3">
                                    <label>Tipo de culto</label>
                                    {
                                        getWorships()
                                    }
                                </div>


                                <div class="mb-3">
                                    <label>Fecha</label>
                                    <input onChange={handleDateWorship} type="date" class="form-control" placeholder="Escriba aquí" required></input>
                                </div>

                                <div class="mb-3">
                                    <label>Hora de inicio</label>
                                    <input type="time" id="appt" onChange={handleStartTime} className="form-control" required name="appt"
                                        min="00:00" max="23:00" required></input>
                                </div>

                                <center>
                                    <Link class="btn botonChidoBlue" onClick={handleStorageWorship} to={"/admin/asignacion/" + nombrePlantillaAsignacion}>Crear culto</Link>
                                </center>
                            </form>


                        </div>
                    </div>
                </div>
            </Fragment>
        </Fade>

    )
}

export default NewWorship;