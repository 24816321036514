/**React imports */
import React, { Component } from 'react';
//import { AuthContext } from '../Auth'
import { withRouter, Redirect } from 'react-router'
/**searcher with select */
import Select from 'react-select'


import { Modal, Button } from 'react-bootstrap';

/** Config. Firebase */

import app from '../../firebase';
import { Link } from 'react-router-dom';

import firebase from 'firebase'

import Swal from 'sweetalert2'
import FileUploader from "react-firebase-file-uploader";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500
});

const db = app.firestore();

class Uploader extends Component {
    state = {
      username: "",
      file: "",
      isUploading: false,
      progress: 0,
      fileUrl: "",
      videoName: this.props.nameVideo

    };
   

    handleChangeUsername = event =>
      this.setState({ username: event.target.value });

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

    handleProgress = progress => this.setState({ progress });

    handleUploadError = error => {
      this.setState({ isUploading: false });
      console.error(error);
    };
    
    handleUploadSuccess = filename => {
      this.setState({ file: filename, progress: 100, isUploading: false });
      firebase
        .storage()
        .ref("archivos")
        .child(filename)
        .getDownloadURL()
        .then((urlVideo)=>{
            
            localStorage.setItem("@urlVideo",urlVideo)
            console.log("Url generado: "+urlVideo)
            this.setState({ fileUrl: urlVideo })
        });
    };
   
    render() {
      return (
        <div>
          <form>
              {this.state.isUploading && <p class="alert alert-info mb-3"><strong>Cargando archivo: {this.state.progress} %.</strong> Espere a que esta ventana se cierre automáticamente</p>}
            <FileUploader
              name="archivo"
              filename={this.props.nameVideo}
              storageRef={firebase.storage().ref("archivos")}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
            />
          </form>
        </div>
      );
    }
  }
   
  export default Uploader;