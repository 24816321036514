import React, { Fragment } from 'react';


/**Transitions  */

import Paper from '@material-ui/core/Paper';
import { Fade } from '@material-ui/core';
import LOGOIDI from "../assets/icons/escudoblanco.png";
import { Link } from 'react-router-dom';



function FilterOne() {




    const startToInternal = async () => {


        const ticketObj = {
            asunto: {
                titulo: '',
                categoria: '',
                contenido: ''
            },
            usuario: {
                nombreCompleto: '',
                localidad: '',
                denominacion: '',
                correoElectronico: '',
                pregunta: '',
                respuesta: '',
                mensaje: ''
            },
            externo: "Interno"
        }

        console.log('saving data..');
        await localStorage.setItem('@ticket', JSON.stringify(ticketObj));
        console.log('Data saved..');
    }


    const startToExternal = async () => {

        let ticket = await localStorage.getItem('@ticket');
        ticket = JSON.parse(ticket);

        const ticketObj = {
            asunto: {
                titulo: '',
                categoria: '',
                contenido: ''
            },
            usuario: {
                nombreCompleto: '',
                localidad: '',
                denominacion: '',
                correoElectronico: '',
                pregunta: '',
                respuesta: '',
                mensaje: ''
            },
            externo: "Externo"
        }

        console.log('saving data..');
        await localStorage.setItem('@ticket', JSON.stringify(ticketObj));
        console.log('Data saved..');


    }



    return (
        <Fade in={true}>
            <Paper elevation={0}>
                <Fragment>
                    <div className='contentMain containerCenter'>
                        <img src={LOGOIDI} height='300px' alt="Logo Iglesia de Dios (Israelita)" />
                        <div className="justify-content-center">

                            <div className='toCenter'>
                                <h6 className='blank-text'>Por favor, responda unas breves preguntas</h6>
                                <h4 className='blank-text'>¿Perteneces a la iglesia de Dios (Israelita)?</h4>
                            </div>
                        </div>


                        <div className='gruopButons'>

                            <Link className='botonDecision' onClick={startToInternal} to='/helpFilter'>Sí</Link>
                            <Link className='botonDecision' onClick={startToExternal} to='/externalFilter'>No</Link>
                        </div>

                    </div>








                </Fragment>
            </Paper>
        </Fade>
    );

}

export default FilterOne;